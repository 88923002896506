import React, { useState } from 'react';

import { Icon } from 'components/atoms';
import { TableCell, HeaderCellContainer, CellContents } from './table-style';

const HeaderCell = props => {
  const { data, isActive, onClick, sortable, sortBy, width, ...others } = props;

  const [direction, setDirection] = useState(true);

  const handleClick = () => {
    if (sortable === true) {
      onClick(data.field, isActive, sortBy);
      if (isActive === true) {
        setDirection(!direction);
      }
    }
  };

  return (
    <TableCell width={width} overflow={others.overflow} header>
      <HeaderCellContainer
        sortable={sortable}
        arrowDirection={direction}
        onClick={() => handleClick(sortable)}
        {...others}
      >
        <CellContents>
          {data.label}
          {isActive && (
            <span>
              <Icon
                name={direction === true ? 'caretDown' : 'caretUp'}
                size='1rem'
              />
            </span>
          )}
        </CellContents>
      </HeaderCellContainer>
    </TableCell>
  );
};

export default HeaderCell;
