import React from 'react';

import { BasicButton, LabelContainer } from './button-style';
import Icon from '../icon/icon';

const Button = props => {
  const { children, icon, iconSize, ...rest } = props;

  return (
    <BasicButton type='button' {...rest}>
      {icon ? <Icon name={icon} size={iconSize || '0.75rem'} /> : null}
      {children ? <LabelContainer>{children}</LabelContainer> : null}
    </BasicButton>
  );
};

export default Button;
