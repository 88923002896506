import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import Icon from '../icon/icon';
import { Container, Button } from './row-buttons-style';

export const RowButtons = observer(props => {
  const { onConfirm, onCancel, disabled, noCancel, ...rest } = props;

  return (
    <Container {...rest}>
      <Button onClick={onConfirm} disabled={disabled}>
        <Icon name='check' color='transparent' fill='#fff' size='2rem' />
      </Button>
      {noCancel ? null : (
        <Button onClick={onCancel}>
          <Icon name='cross1' color='#fff' fill='#fff' size='1rem' />
        </Button>
      )}
    </Container>
  );
});

RowButtons.defaultProps = {
  disabled: false,
  className: '',
  onConfirm: () => {},
  onCancel: () => {}
};

RowButtons.propTypes = {
  disabled: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default RowButtons;
