import React from 'react';
import { observer } from 'mobx-react';
import { Container } from './derpling-popups-container-style';
import DerplingSentiment from '../derpling/derpling-sentiment/derpling-sentiment';

const DerplingPopupsContainer = () => {
  return (
    <Container>
      <DerplingSentiment />
    </Container>
  );
};

export default observer(DerplingPopupsContainer);
