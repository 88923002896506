import styled from 'styled-components/macro';

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  span {
    margin-left: 0.8em;
    font-weight: 400;
  }
`;

export const ToggleBox = styled.div`
  height: 1.25em;
  width: 2.5em;
  position: relative;
  background-color: ${({ condition }) =>
    condition ? 'var(--color-purple)' : 'var(--color-grey-lighter)'};
  border-radius: var(--border-radius-sm);
  display: flex;
  flex-direction: row;
  &:hover {
    box-shadow: ${({ size }) => {
      const inset = size;
      return `inset ${inset / 30}em ${inset / 30}em ${inset / 10}em ${inset /
        40}em rgba(0,0,0,0.1)`;
    }};
    div {
      box-shadow: ${({ size }) => {
        const inset = size;
        return `inset ${inset / -30}em ${inset / -30}em ${inset /
          10}em ${inset / 40}em rgba(0,0,0,0.1)`;
      }};
    }
  }
  transition: box-shadow 0.3s linear, background-color 0.2s ease-in-out;
`;

export const ToggleSwitch = styled.div`
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  transform: ${({ condition }) =>
    condition ? 'translateX(calc(1.5em - 4px))' : 'none'};
  width: 1em;

  background-color: var(--color-white);
  border-radius: var(--border-radius-xs);

  transition: transform 0.4s cubic-bezier(0, 0.4, 0, 1), box-shadow 0.2s linear;
`;
