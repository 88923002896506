import React, { useContext, useState, useEffect } from 'react';

import { withRouter } from 'react-router-dom';
import { AuthContext } from 'contexts/auth/auth-context';

import { Icon } from 'components/atoms';
import { DarkModeSwitch } from 'components/molecules';

import Logo from './logo.svg';
import G from './g-icon.svg';
import M from './m-icon.svg';

import {
  LoginPage,
  LoginTopRight,
  LoginBottomLeft,
  LoginContent,
  Header,
  DerplingContainer,
  Derpling,
  DerplingTitle,
  LoginButton,
  LoginDisclaimer,
  DerplingMessage,
  LoginDivider
} from './login-style';

const randomized = [
  'Hello there',
  'Beep boop  🤖',
  'Hey hey  😎',
  'Have a nice day!'
].sort(() => 0.5 - Math.random()); // Super lazy suffle..

const ending = [
  'Welcome  👋\n\nPlease select the appropriate login method below!'
];
const messages = ['Welcome!'].concat(randomized, ending);

const Login = withRouter(({ location }) => {
  const { isAuth, inProgress, login } = useContext(AuthContext);
  const processing = isAuth || inProgress;
  const [message, setMessage] = useState(null);
  const [fading, setFading] = useState(false);

  const { from } = location.state || {
    from: location.pathname === '/login' ? '/' : location.pathname
  };

  const handleLogin = connection => () => {
    login(from, connection);
  };

  useEffect(() => {
    if (processing) {
      setMessage('Logging in!');
    }
  }, [processing]);

  const showMessage = msg => {
    setMessage(msg || messages.shift());
    if (messages.length) {
      setTimeout(() => setFading(true), 4000);
      setTimeout(() => {
        setMessage(null);
        setFading(false);
      }, 5000);
    }
  };

  return (
    <LoginPage>
      <LoginTopRight />
      <Logo />
      <LoginContent>
        <Header>
          {message ? (
            <DerplingMessage className={fading && 'animate-out'}>
              {message}
            </DerplingMessage>
          ) : null}
          <DerplingContainer
            onClick={() => showMessage()}
            disabled={message || processing}
          >
            <Derpling />
          </DerplingContainer>
          <DerplingTitle>Derp</DerplingTitle>
        </Header>
        {processing ? null : (
          <>
            <LoginButton visual='google' onClick={handleLogin('google-oauth2')}>
              <G />
              Log in with your Google Account
            </LoginButton>
            <LoginButton visual='msoft' onClick={handleLogin('Loihde')}>
              <M />
              Log in with your Microsoft Account
            </LoginButton>
            <LoginDivider />
            <LoginButton
              visual='derp'
              onClick={handleLogin('Username-Password-Authentication')}
            >
              <Icon name='lockClosed' size='2rem' />
              <Icon name='lockOpen' size='2rem' />
              Login with Email &amp; Password
            </LoginButton>
          </>
        )}
      </LoginContent>
      <LoginDisclaimer>
        © {new Date().getFullYear()} Loihde Factor Oy
      </LoginDisclaimer>
      <LoginBottomLeft />
      <DarkModeSwitch />
    </LoginPage>
  );
});

export default Login;
