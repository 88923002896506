import React from 'react';
import PropTypes from 'prop-types';
import {
  TabContainer,
  TabLink,
  Tab,
  activeClassName
} from './tab-navigation-style';

const linkProps = tab => {
  const { to, onClick } = tab;
  const as = to ? undefined : 'div';
  const className = tab.active ? activeClassName : undefined;

  return { to, onClick, as, className };
};

const TabNavigation = props => {
  const { tabs } = props;
  return (
    <TabContainer>
      {tabs.map(item => (
        <TabLink exact key={item.label} {...linkProps(item)}>
          <Tab>{item.label}</Tab>
        </TabLink>
      ))}
    </TabContainer>
  );
};

export default TabNavigation;

TabNavigation.propTypes = {
  tabs: PropTypes.array.isRequired
};
