import styled, { keyframes } from 'styled-components/macro';

const animationIn = keyframes`
 0%{
   transform: translateY(0.6rem) scaleY(0.96);
   opacity:0;
 }
 100%{
   transform: translateY(0) scaleY(1);
   opacity:1
 }
`;

export const Window = styled.div`
  position: relative;
  max-width: 21rem;
  padding: 0.75rem;
  border: 1px solid var(--color-text-lightest);
  box-shadow: var(--shadow-md);
  border-radius: var(--border-radius-md);
  background-color: var(--color-modal);
  transform-origin: 95% 100%;
  opacity: 0;
  pointer-events: none;
  display: none;
  &.is-open {
    display: block;
    animation: ${animationIn} 1s forwards var(--ease-out-expo);
    pointer-events: all;
  }

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: calc(-0.5rem - 2px);
    right: calc(1.5rem - 2px);
    border: calc(0.5rem + 2px) solid transparent;
    border-bottom: none;
    border-top-color: var(--color-text-lightest);
    filter: drop-shadow(0px 4px 4px rgba(0 0 0, 0.13));
  }
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: -0.5rem;
    right: 1.5rem;
    border: 0.5rem solid transparent;
    border-bottom: none;
    border-top-color: var(--color-modal);
  }

  .is-hidden & {
    pointer-events: none;
  }
`;

export const ButtonContainer = styled.div`
  z-index: 20;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  cursor: pointer;
`;
