import styled from 'styled-components/macro';
import { breakpointsRefresh as breakpoints } from 'common/styles';

export const FiltersMenu = styled.div`
  padding: 0 1rem 1rem;
  color: var(--color-text-dark);
`;

export const FilterTitle = styled.div`
  color: var(--color-text-light);
  margin-top: 1rem;
  margin-bottom: 0.25rem;
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionContainer = styled.div`
  display: flex;
  margin-left: 0.25rem;
  justify-content: space-between;

  & > div:first-child {
    display: flex;
    flex-flow: wrap;
  }
`;

export const AddFiltersButton = styled.div`
  padding: 0.25rem 0.75rem;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  color: var(--color-text-is-active);
  background-color: var(--color-background-is-active);
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: var(--font-size-sm);
  margin-right: 1rem;
  position: relative;
  ${breakpoints.md} {
    margin-right: 1.5rem;
    margin-left: 0;
  }
  &:after {
    display: block;
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: var(--color-text-lightest);
    right: -1.5rem;
  }
  &:only-child :after {
    display: none;
  }
`;

export const ActiveRowCount = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap; // weird
  align-self: stretch;
  align-items: center;
  margin: 0 1rem;
  color: ${({ count }) =>
    count === 0 ? 'var(--color-text-light)' : 'var(--color-primary-dark)'};

  ${breakpoints.md} {
    margin: 0 1.5rem;
  }

  // Reuse with AddFiltersButton...
  &:after {
    display: block;
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: var(--color-text-lightest);
    right: -1.5rem;
  }
  &:last-child :after {
    display: none;
  }
`;

export const FilterButtonText = styled.span`
  margin-left: 0.5rem;
`;

export const ActiveFilter = styled.div`
  padding: 0.25rem 0.5rem 0.25rem 0.75rem;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  color: var(--color-text-is-active);
  background-color: var(--color-background-is-active);
  display: flex;
  align-items: center;
  font-size: var(--font-size-sm);
  white-space: nowrap;
  & + & {
    margin-left: 1rem;
  }

  ${ActiveRowCount} + &,
  ${AddFiltersButton} + & {
    margin-left: 1rem;

    ${breakpoints.md} {
      margin-left: 1.5rem;
    }
  }
`;

export const ActiveFilterRemove = styled.div`
  padding: 0 0.25rem;
  color: var(--color-text);
  display: grid;
  place-content: center;
  font-size: var(--font-size-sm);
  &:hover {
    color: var(--color-text-is-active);
  }

  padding-left: ${({ noPadding }) => noPadding && 0};
`;

export const FilterInputs = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.25rem;
`;

export const FilterInputContainer = styled.div`
  position: relative;
  input {
    width: 3.25rem;
    -moz-appearance: textfield;
    border: none;
    color: var(--color-text-dark);
    text-align: center;
    font-size: var(--font-size-sm);
    border-radius: 2px;
    padding-right: 0.5rem;
    background-color: var(--color-text-lightest);
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &:after {
    position: absolute;
    content: '%';
    color: var(--color-text-light);
    right: 0.1rem;
    display: grid;
    place-content: center;
    top: 0;
    height: 100%;
  }
`;

export const FilterHint = styled.div`
  margin-left: 1.25rem;
  margin-top: -0.3rem;
  width: 100%;
  font-style: italic;
  font-size: var(--font-size-xxs);
  color: var(--color-text-light);
`;

export const FilterCount = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-text);
`;
