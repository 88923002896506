/* eslint no-console: 0 */
import { autorun, observable, action, computed } from 'mobx';
import { createViewModel } from 'mobx-utils';

import moment from 'moment';
import axios from '../common/utils/axios';

export class Holiday {
  store = null;

  @observable id = -1;
  @observable day = '';
  @observable description = '';

  @observable deleted = false;

  constructor(store, json = {}) {
    this.store = store;
    this.updateFromJson(json);
  }

  @action save() {
    if (this.id > 0) {
      axios
        .put(
          `/api/v1/settings/holidays/${this.id}`,
          JSON.stringify(this.toJson())
        )
        .then(() => {});
    } else {
      axios
        .post('/api/v1/settings/holidays', JSON.stringify(this.toJson()))
        .then(
          action(response => {
            this.updateFromJson(response.data);
          })
        );
    }
  }

  updateFromJson(json) {
    this.id = json.id || -1;
    this.day = moment(json.day || new Date()).format('DD.MM.YYYY');
    this.description = json.description || '';
  }

  toJson() {
    return {
      day: this.day,
      description: this.description
    };
  }
}

export class HolidayStore {
  @observable __holidays = observable.array();
  @observable dirty = false;

  @computed get holidays() {
    return this.__holidays.filter(h => !h.deleted);
  }

  @action update = () => {
    axios.get('/api/v1/settings/holidays').then(
      action(response => {
        this.__holidays.clear();
        response.data.forEach(h => {
          this.createNewHoliday(h);
        });
      }),
      action(err => {
        console.log('could not update holidays', err);
      })
    );
  };

  isHoliday = day => {
    // TODO: bad to rely on specific format
    const d = moment(day).format('DD.MM.YYYY');
    return this.holidays.find(h => h.day === d);
  };

  @action createNewHoliday(json) {
    let h = new Holiday(this, json);
    h = createViewModel(h);
    autorun(() => {
      this.dirty = json === undefined; // Set dirty when creating empty holiday
    });
    this.__holidays.push(h);
  }

  @action revert() {
    this.__holidays.forEach(h => {
      h.reset();
      if (h.id === -1) {
        this.deleteHoliday(h);
      } else {
        h.deleted = false; // eslint-disable-line
        h.submit();
      }
    });
    this.dirty = false;
  }

  @action save() {
    this.__holidays.forEach(h => {
      if (h.isDirty) {
        if (h.deleted) {
          this.deleteHoliday(h);
        } else {
          h.submit();
          h.model.save();
        }
      }
    });
    autorun(() => {
      this.dirty = this.__holidays.some(h => h.isDirty);
    });
  }

  @action deleteHoliday(h) {
    const idx = this.__holidays.findIndex(hol => hol.id === h.id);
    h.submit();
    if (h.id === -1) {
      this.__holidays.splice(idx);
    } else if (h.deleted) {
      h.submit();
      axios.delete(`/api/v1/settings/holidays/${h.id}`).then(
        action(() => {
          this.__holidays.splice(idx, 1);
        }),
        action(err => {
          console.log('could not delete holiday', h, err);
        })
      );
    }
  }
}

export default new HolidayStore();
