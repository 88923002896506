import { useContext, useRef, useEffect, useState } from 'react';

import { AuthContext } from '../../contexts/auth/auth-context';
import { canAccess } from '../../common/rbac';

const usePermission = (
  permission,
  granted,
  denied,
  data = {},
  unmount = () => {}
) => {
  const authContext = useContext(AuthContext);
  const [hasPermission, setHasPermission] = useState(false);
  const didMount = useRef(false);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!didMount.current) {
      if (canAccess(authContext.user.role, permission, data)) {
        setHasPermission(true);
        if (granted) {
          granted();
        }
      } else {
        setHasPermission(true);
        if (denied) {
          denied();
        }
      }
      didMount.current = true;
    }

    return unmount;
  }, []);
  return hasPermission;
};

export default usePermission;
