import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { AuthContext, DerpContext, DateContext } from '../index';
import { useEmployee } from '../../common/hooks';
import { usePermission, rbac, canAccess } from '../../common/rbac';
import {
  filterEmployees,
  sortByFullName
} from '../../models/employee-list-model';

export const EmployeeListContext = React.createContext();

export const EmployeeListContextProvider = observer(props => {
  const derp = useContext(DerpContext);
  const date = useContext(DateContext);
  const authCtx = useContext(AuthContext);
  const employee = useEmployee();

  const { month, updateMonth } = date;
  const { employees } = derp;
  const { user } = authCtx;

  const activeEmployees =
    user.role === 'admin'
      ? employees.payrollActive(month)
      : employees.active(month);

  const [tags, setTags] = useState([]);
  const [sortedEmployees, setSortedEmployees] = useState([]);
  const employeeNamesOfTags = tags.map(t => t.name);

  const onDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  usePermission(rbac.EMPLOYEES_READ, () => {
    employees.load().then(() => {
      if (canAccess(user.role, rbac.EMPLOYEES_SUMMARY_READ)) {
        employees.loadReport(month);
      }
    });
  });

  useEffect(() => {
    const filteredActiveEmployees =
      tags.length === 0
        ? activeEmployees
        : filterEmployees(employeeNamesOfTags, activeEmployees);

    if (filteredActiveEmployees.length !== sortedEmployees.length) {
      setSortedEmployees(sortByFullName(filteredActiveEmployees)); // Sort by name by default
    }
  }, [activeEmployees]);

  const onDateChange = d => {
    updateMonth(d);
    if (canAccess(user.role, rbac.EMPLOYEES_SUMMARY_READ)) {
      employees.loadReport(d);
    }
  };

  const nameSuggestions = activeEmployees.map(e => {
    return { name: e.fullName(), type: 'employee' };
  });

  const filteredAndSortedEmployees =
    tags.length === 0
      ? sortedEmployees
      : filterEmployees(employeeNamesOfTags, sortedEmployees);

  const handleSort = sortFunc => {
    sortFunc(filteredAndSortedEmployees, month);
    setSortedEmployees(Array.from(filteredAndSortedEmployees));
  };

  const ids = filteredAndSortedEmployees.map(e => e.id);
  const currentEmployeeIndex = ids.findIndex(e => e === employee.id);
  const nextId = ids[currentEmployeeIndex + 1];
  const prevId = ids[currentEmployeeIndex - 1];

  const history = useHistory();

  const navigateToEmployee = newId => {
    history.push(
      `/ng/employees/${newId}/hours?month=${month.format('YYYY-MM-DD')}`
    );
  };

  const navigateToPreviousEmployee = () => navigateToEmployee(prevId);

  const navigateToNextEmployee = () => navigateToEmployee(nextId);

  return (
    <EmployeeListContext.Provider
      value={{
        onDelete,
        onDateChange,
        nameSuggestions,
        sortedEmployees,
        handleSort,
        filteredAndSortedEmployees,
        month,
        tags,
        setTags,
        employees,
        nextId,
        prevId,
        navigateToPreviousEmployee,
        navigateToNextEmployee
      }}
    >
      {props.children}
    </EmployeeListContext.Provider>
  );
});
