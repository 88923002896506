import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import * as Survey from 'survey-react';
import { useLocation } from 'react-router-dom';
import 'survey-react/survey.css';
import { DerpContext } from '../index';
import { usePermission, rbac } from '../../common/rbac';
import { useEmployee } from '../../common/hooks';
import notificationStore from '../../stores/notificationStore';
import '../../components/organisms/derpling/survey-view/survey-view.scss';
import { colors } from '../../common/styles';

const defaultThemeColors = Survey.StylesManager.ThemeColors.default;

defaultThemeColors['$main-color'] = colors.bifaGreen;
defaultThemeColors['$main-hover-color'] = colors.bifaGreen;
defaultThemeColors['$body-container-background-color'] = 'transparent';
defaultThemeColors['$header-color'] = colors.headingGray;

Survey.StylesManager.applyTheme();

export const DerplingContext = React.createContext();

export const DerplingContextProvider = props => {
  const derp = useContext(DerpContext);
  const employee = useEmployee();
  const location = useLocation();

  // Sentiment = the "How are you feeling" survey, data stored in our server
  // Survey = other survey, data stored in surveyJS server

  const [sentimentOpen, setSentimentOpen] = useState(false);
  const [sentimentPending, setSentimentPending] = useState(false);

  const [surveyOpen, setSurveyOpen] = useState(false);
  const [survey, setSurvey] = useState(null);

  const [model, setModel] = useState(null);

  const getSurveyModel = () => {
    if (model) {
      return model;
    }
    const { survey_id } = survey;
    const m = new Survey.Model({
      surveyId: survey_id,
      clientId: employee.id
    });
    setModel(m);
    return m;
  };

  const openSentiment = () => {
    setSentimentOpen(true);
  };

  const closeSentiment = () => {
    setSentimentOpen(false);
  };

  const openSurvey = () => {
    setSurveyOpen(true);
  };

  const closeSurvey = () => {
    setSurveyOpen(false);
  };

  const clearSurvey = () => {
    setSurvey(false);
  };

  const setActiveSurvey = s => {
    s.checkCompleteStatus(employee.id).then(() => {
      if (!s.completed) {
        setSurvey(s);
      }
    });
  };

  const loadSurveys = () => {
    const { surveys } = derp;
    if (!surveys.isReady()) {
      surveys.load().then(() => {
        if (surveys.all().length > 0) {
          const s = surveys.newest();
          setActiveSurvey(s);
        }
      });
    }
  };

  const isSentimentPending = feedbacks => {
    const now = moment();
    if (feedbacks.length === 0) {
      return true;
    }
    const latest = feedbacks[0].created_at; // Backend gives the newest first.
    const diff = now.diff(latest, 'days');
    return diff > 13;
  };

  const openAllAvailable = () => {
    setSentimentOpen(true); // Feeling survey is always available
    if (!survey) {
      loadSurveys(); // Check if there is a surveyJS survey available
    } else {
      setSurveyOpen(true);
    }
  };

  const autoOpen = () => {
    const pathItems = location.pathname.split('/');
    const onAdminSide = pathItems[1] === 'ng';
    // Only one is auto opened. surveyJS survey has higher priority
    if (survey && !onAdminSide) {
      // Don't auto open the survey on the admin side.
      openSurvey();
      closeSentiment();
    } else if (!survey && sentimentPending) {
      openSentiment();
    }
  };

  const showConfirmMessage = () => {
    notificationStore.queue('Thank you for your feedback!');
  };

  const addSentimentFeedback = (grade, comment) => {
    const success = employee.addFeedback({
      value: grade,
      comment
    });
    if (success) {
      showConfirmMessage();
      setSentimentPending(false);
    } else {
      notificationStore.queue('Sending feedback failed.');
    }
    closeSentiment();
  };

  usePermission(rbac.FEEDBACK_READ, () => {
    employee.loadFeedback().then(feedbacks => {
      const autoOpenSentiment = isSentimentPending(feedbacks);
      if (autoOpenSentiment) {
        setSentimentPending(true);
      }
      loadSurveys();
    });
  });

  const pendingSurveys = survey || sentimentPending;
  const popupsOpen = surveyOpen || sentimentOpen;
  const showBadge = !popupsOpen && pendingSurveys;

  useEffect(() => {
    autoOpen();
  }, [pendingSurveys]);

  return (
    <DerplingContext.Provider
      value={{
        survey,
        surveyOpen,
        sentimentPending,
        sentimentOpen,
        closeSentiment,
        closeSurvey,
        openAllAvailable,
        autoOpen,
        addSentimentFeedback,
        showConfirmMessage,
        clearSurvey,
        model,
        getSurveyModel,
        pendingSurveys,
        popupsOpen,
        showBadge
      }}
    >
      {props.children}
    </DerplingContext.Provider>
  );
};
