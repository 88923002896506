import styled from 'styled-components/macro';
import { breakpointsRefresh as breakpoints } from 'common/styles';

export const DatePickerContainer = styled.div`
  width: ${({ format }) => (format.length + 2) * 1.15}rem;
  margin-left: -0.5rem;
  .DayPickerInput > div {
    z-index: 55000;
  }
  .DayPickerInput > input {
    width: 100%;
    margin: 0;
    padding: 0.25rem 0.5rem;
    color: var(--color-input-text);
    border: 1px solid
      ${({ hasError }) =>
        hasError ? 'var(--color-error-light)' : 'transparent'};
    border-radius: var(--border-radius-xs);
    background-color: ${({ hasError }) =>
      hasError ? 'var(--color-error-lightest)' : 'transparent'};
    &:hover {
      background-color: ${({ colorHover }) =>
        colorHover || 'var(--color-background-hint)'};
    }
    &:focus {
      outline: none;
      border-color: var(--color-input-focus);
    }
  }
  .DayPickerInput-OverlayWrapper {
  }
`;

export const DateRangeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 2rem;
  width: 100%;
  padding: 0 1rem;
  margin-left: auto;

  border: 1px solid var(--color-text-lightest);
  border-radius: var(--border-radius-md);
  cursor: pointer;
  font-size: var(--font-size-sm);
  color: var(--color-text-dark);
  white-space: nowrap;

  &:hover {
    color: var(--color-text-darkest);
    background-color: var(--color-background-is-active);
  }
`;

export const CalendarContainer = styled.div`
  --datePickerCellSize: 2.25rem;
  ${breakpoints.md} {
    --datePickerCellSize: 1.75rem;
  }

  .DayPicker {
    font-size: var(--font-size-sm);
    color: var(--color-text-darker);
    &-Weekday {
      width: var(--datePickerCellSize);
      display: inline-grid;
      place-content: center;
    }
    &-WeekNumber {
      color: var(--color-text);
      font-size: var(--font-size-xs);
      border-right-color: var(--color-text-lightest);
      padding: 0;
      width: var(--datePickerCellSize);
      height: var(--datePickerCellSize);
      display: inline-grid;
      place-content: center;
    }
    &-NavButton {
      background-size: 1rem;
      width: 1.25rem;
      height: 1.25rem;
      color: red;
      &:hover {
        color: blue;
      }
    }

    &-Weekdays,
    &-Body,
    &-Week {
      display: block;
      min-width: calc(8 * var(--datePickerCellSize));
    }

    .DayPicker-Day {
      padding: 0;
      width: var(--datePickerCellSize);
      height: var(--datePickerCellSize);
      display: inline-grid;
      place-content: center;

      &:hover:not(.DayPicker-Day--outside) {
        background-color: var(--color-text-lightest);
      }
      &--outside {
        color: var(--color-background);
      }
      &--selected {
        border-radius: 0;

        &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
          background-color: var(--color-primary-light);
        }
        &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
          background-color: var(--color-primary);
        }
      }

      &--from {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }

      &--to {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }
  }
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: var(--color-text-lightest);
  }
`;

export const CalendarNavbar = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  height: 3.25rem;
  align-items: center;
  margin-right: 1rem;
  > div {
    width: 1.5rem;
    height: 100%;
    display: grid;
    place-content: center;
    cursor: pointer;
    color: var(--color-text-dark);
    &:hover {
      color: var(--color-text-darker);
    }
    ${breakpoints.md} {
      width: 1.75rem;
    }
  }
`;
