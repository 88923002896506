import { types } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { MomentType } from '../types';

const Certificate = types
  .model('Certificate', {
    id: types.maybeNull(types.string),
    name: types.optional(types.string, ''),
    date: types.optional(MomentType, moment())
  })
  .preProcessSnapshot(snapshot => {
    return {
      id: uuidv4(),
      ...snapshot
    };
  })
  .postProcessSnapshot(snapshot => {
    delete snapshot.id; // eslint-disable-line no-param-reassign
    return snapshot;
  })
  .actions(self => ({
    setName(name) {
      self.name = name; // eslint-disable-line no-param-reassign
    },
    setDate(date) {
      self.date = date; // eslint-disable-line no-param-reassign
    }
  }));

export default Certificate;
