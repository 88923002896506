import React from 'react';
import { ToggleContainer, ToggleBox, ToggleSwitch } from './toggle-style';

const Toggle = props => {
  const { onToggle, condition, title, disabled } = props;

  return (
    <ToggleContainer
      disabled={disabled}
      onClick={() => {
        if (!disabled) onToggle(!condition);
      }}
    >
      <ToggleBox condition={condition}>
        <ToggleSwitch condition={condition} />
      </ToggleBox>
      <span>{title}</span>
    </ToggleContainer>
  );
};

export default Toggle;
