import React from 'react';
import { Icon } from '..';

const Toggle = props => {
  const { onClick, condition, iconTrue, iconFalse } = props;

  return (
    <div onClick={onClick}>
      <Icon name={condition ? iconTrue : iconFalse} />
    </div>
  );
};

export default Toggle;
