import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';

import useClickOutside from 'common/hooks/useClickOutside';

import { SimpleInput, Button } from 'components/atoms';

import {
  Container,
  SearchContainer,
  EmployeeList,
  EmployeeWrapper,
  InputWrapper
} from './array-input-style';

const search = (value, options) => {
  return options
    .split(', ')
    .filter(Boolean)
    .filter(item =>
      value ? item.toLowerCase().includes(value.toLowerCase()) : true
    );
};

const values = {
  ArrowUp: -1,
  ArrowDown: 1,
  Tab: 0
};

const preventDefaultIfNecessary = (event, isLast) => {
  const isArrowKey = ['ArrowUp', 'ArrowDown'].includes(event.key);
  if (isArrowKey || (event.key === 'Tab' && isLast && !event.shiftKey)) {
    event.preventDefault();
  }
};

const ArrayInput = observer(
  ({ onCommit, initial = '', searchable = '', limit = 0, ...rest }) => {
    const [array, setArray] = useState(initial.split(', ').filter(Boolean));
    const [query, setQuery] = useState('');
    const [data, setData] = useState(search(query, searchable));
    const [hide, setHide] = useState(true);

    useEffect(() => {
      if (query) {
        setData(search(query, searchable));
      }
    }, [query]);

    const ref = useRef(null);
    useClickOutside(ref, () => setHide(true));

    useEffect(() => {
      setArray(initial.split(', ').filter(Boolean));
    }, [initial]);

    useEffect(() => {
      onCommit(array.join(', '));
    }, [array.length]);

    const list = useRef(null);

    const onSelect = item => {
      setArray([...array, item]);
      setQuery('');
    };

    const handleQuickSelect = event => {
      if (['Enter', ','].includes(event.key) && event.target.value.trim()) {
        onSelect(event.target.value.trim());
      }
    };

    const handleFocus = e => {
      if (['ArrowUp', 'ArrowDown', 'Tab'].includes(e.key)) {
        const elements = [...list.current.children];
        const lastIndex = elements.length - 1;
        const current = elements.findIndex(el => document.activeElement === el);

        const index = Math.min(lastIndex, Math.max(0, current + values[e.key]));
        elements[index].focus();

        preventDefaultIfNecessary(e, index === lastIndex);
      }
    };

    const removeItem = item => {
      setArray(array.filter(val => val !== item));
    };

    return (
      <Container>
        <SearchContainer
          onFocus={() => setHide(false)}
          onKeyDown={handleFocus}
          ref={ref}
        >
          <InputWrapper>
            {array.map(contact => (
              <span key={contact}>
                {contact}
                <Button
                  small
                  transparent
                  icon='cross2'
                  onClick={() => removeItem(contact)}
                />
              </span>
            ))}
            {limit && array.length >= limit ? null : (
              <SimpleInput
                value={query}
                onChange={setQuery}
                onKeyDown={e => handleQuickSelect(e)}
                placeholder='Add contact'
                {...rest}
              />
            )}
          </InputWrapper>
          {!hide && data.length ? (
            <EmployeeList ref={list}>
              {data.map(item => (
                <EmployeeWrapper
                  key={item}
                  tabIndex='0'
                  onClick={() => onSelect(item)}
                  onKeyDown={e => handleQuickSelect(e, item)}
                >
                  <div>{item}</div>
                </EmployeeWrapper>
              ))}
            </EmployeeList>
          ) : null}
        </SearchContainer>
      </Container>
    );
  }
);

export default ArrayInput;
