import styled, { keyframes } from 'styled-components/macro';
import derpling from '../../../../../assets/images/derpling.svg';

const blink = keyframes`
  0% {
    transform: scaleY(0);
  }
  95%{
    transform: scaleY(0);
  }
  97%{
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
`;
const shake = keyframes`
0% { transform: matrix3d(0.985, 0.174, 0, 0, -0.174, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  3.4% { transform: matrix3d(0.996, 0.087, 0, 0, -0.087, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.7% {transform: matrix3d(0.999, 0.044, 0, 0, -0.044, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  6.81% {transform: matrix3d(1, -0.022, 0, 0, 0.022, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.41% {transform: matrix3d(0.997, -0.079, 0, 0, 0.079, 0.997, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  10.21% {transform: matrix3d(0.996, -0.089, 0, 0, 0.089, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  13.61% { transform: matrix3d(0.996, -0.094, 0, 0, 0.094, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  14.11% { transform: matrix3d(0.996, -0.091, 0, 0, 0.091, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  17.52% { transform: matrix3d(0.999, -0.047, 0, 0, 0.047, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.72% { transform: matrix3d(1, -0.028, 0, 0, 0.028, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  21.32% {  transform: matrix3d(1, 0.01, 0, 0, -0.01, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { transform: matrix3d(0.999, 0.04, 0, 0, -0.04, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  25.23% {transform: matrix3d(0.999, 0.045, 0, 0, -0.045, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.03% { transform: matrix3d(0.999, 0.047, 0, 0, -0.047, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.93% {transform: matrix3d(0.999, 0.044, 0, 0, -0.044, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  35.54% {  transform: matrix3d(1, 0.01, 0, 0, -0.01, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  36.74% { transform: matrix3d(1, 0.002, 0, 0, -0.002, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  41.04% {transform: matrix3d(1, -0.015, 0, 0, 0.015, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  44.44% {transform: matrix3d(1, -0.017, 0, 0, 0.017, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  52.15% {transform: matrix3d(1, -0.004, 0, 0, 0.004, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  59.86% {  transform: matrix3d(1, 0.005, 0, 0, -0.005, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  63.26% {  transform: matrix3d(1, 0.004, 0, 0, -0.004, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  75.28% { transform: matrix3d(1, -0.001, 0, 0, 0.001, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85.49% {transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  90.69% {transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% {transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
`;

export const Derpling = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${derpling});
  background-repeat: no-repeat;
  background-position: 50% 38%;
  background-size: 50%;
`;

export const Container = styled.div`
  position: fixed;
  width: 2.75rem;
  height: 2.75rem;
  bottom: 1.25rem;
  right: 1.25rem;
  z-index: 25001;
  background-color: var(--color-primary);
  display: grid;
  place-content: center;
  border-radius: var(--border-radius-full);
  transition: box-shadow 0.3s, transform 0.3s ease-out;
  cursor: pointer;
  &:before {
    transform-origin: 50% 0%;
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius-full);
    box-shadow: 0px 0px 3rem rgba(var(--color-primary-rgb), 0.6);
    opacity: 0;
    transition: all 0.4s ease-out;
    z-index: 25000;
  }
  &:after {
    transform-origin: 50% 0%;
    display: block;
    content: '';
    position: absolute;
    width: 46%;
    height: 15%;
    background-color: #fff;
    top: 41%;
    left: 27%;
    animation: ${blink} 5s both steps(100) infinite;
    will-change: transform;
  }
  &:hover {
    transform: translateY(-1px);
    box-shadow: var(--shadow-md);
    > div {
      transform: translateY(-2px);
      animation: ${shake} 1s both;
      will-change: transform;
    }
  }
  &:active {
    transform: scale(0.95);
    transform-origin: 50% 100%;
    transition: box-shadow 0.3s, transform 0s;
    &:before {
      box-shadow: 0px 0px 0px rgba(var(--color-primary-rgb), 1);
      opacity: 1;
      transition: all 0s;
    }
  }
`;

export const Badge = styled.div`
  border-radius: var(--border-radius-full);
  position: absolute;
  left: -0.75em;
  top: -0.75rem;
  width: 1rem;
  height: 1rem;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  display: grid;
  place-content: center;
  font-size: var(--font-size-xs);
  background-color: var(--color-primary-dark);
  color: var(--color-white);
`;
