import { css } from 'styled-components';
import { colors } from './colors';

const { bifaDark, lineMedium } = colors;

export const hiddenWithTransition = css`
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.15s linear, opacity 0.15s linear;
`;

export const visible = css`
  visibility: visible;
  opacity: 1;
`;

export const autoresizeTextarea = css`
  textarea {
    overflow: hidden;
    border: 1px solid transparent;
    border-radius: 4px;
    outline: none;
    width: 100%;
    resize: none;
    padding: 0.3rem 0.6rem;
    color: ${bifaDark};
    font-size: 0.85rem;
    line-height: 1.1rem;
    white-space: pre-wrap;
    &::placeholder {
      font-style: italic;
    }
    &:hover,
    &:focus {
      border: 1px solid ${lineMedium};
    }
    &:disabled {
      background-color: transparent;
      border-color: transparent;
    }
  }
`;
