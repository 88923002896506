import styled, { css } from 'styled-components/macro';

export const Sidebar = styled.div`
  border-right: 1px solid var(--color-text-lightest);
  overflow-y: auto;
  overflow-x: hidden;
`;
export const Header = styled.div`
  position: sticky;
  top: 0;
  padding: 0.5rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid var(--color-text-lightest);
  background-color: var(--color-background);
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: var(--font-size-sm);
  width: 100%;
`;
export const AddButtonContainer = styled.div`
  margin-left: 1rem;
`;

export const Time = styled.div`
  white-space: pre;
  text-align: center;
  color: var(--color-primary);
  font-size: var(--font-size-xs);
  font-weight: 700;
  line-height: 1.175;
`;
export const Text = styled.div`
  margin-left: 0.75rem;
  min-width: 0;
`;
export const Title = styled.div`
  font-weight: 700;
  color: var(--color-text-darker);
  font-size: var(--font-size-sm);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
`;
export const Description = styled.div`
  color: var(--color-text);
  font-size: var(--font-size-xs);
  margin-top: 0.1rem;
  min-height: 2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

const handleDone = done => {
  if (done) {
    return css`
      ${Time} {
        color: var(--color-text);
      }
      ${Title} {
        color: var(--color-text);
        text-decoration: line-through;
      }
      ${Description} {
        color: var(--color-text-light);
        text-decoration: line-through;
      }
    `;
  }
  return '';
};
const handleActive = active => {
  if (active) {
    return css`
      margin: -1px -1px 0 0;
      padding-top: calc(1rem + 1px);
      padding-left: 1rem;
      background-color: var(--color-background-is-active);
      border-bottom-color: var(--color-background-is-active);
    `;
  }
  return '';
};

export const Item = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 3.5ch 1fr;
  border-bottom: 1px solid var(--color-text-lightest);
  padding: 1rem 1rem 1rem 0;
  margin-left: 1rem;
  ${({ done }) => handleDone(done)}
  ${({ active }) => handleActive(active)}
`;
