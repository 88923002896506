import styled from 'styled-components/macro';

// eslint-disable-next-line
export const DerplingContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  transform-origin: 100% 50%;
  transform: translateX(5rem);
  transition: transform 0.2s cubic-bezier(0.36, 0, 0.66, -0.56);
  z-index: 5000;
  &.is-visible {
    transform: translateX(0);
    transition: transform 0.4s cubic-bezier(0.34, 1.62, 0.64, 1);
  }
`;
