import React from 'react';
import { TableCell, CellContents } from './table-style';

const Cell = props => {
  const { value, data, renderer, highlightBy, stretch, ...others } = props;

  return (
    <TableCell
      value={highlightBy === undefined ? value : highlightBy}
      {...others}
    >
      <CellContents stretch={stretch}>
        {renderer ? renderer(value, data) : value}
      </CellContents>
    </TableCell>
  );
};

export default Cell;
