import {
  types,
  flow,
  getParent,
  applySnapshot,
  getSnapshot,
  destroy
} from 'mobx-state-tree';
import moment from 'moment';
import Decimal, { MomentType, LazyEmployee } from './types';
import axios from '../common/utils/axios';

export const EquipmentEntry = types
  .model('Equipment', {
    id: types.identifierNumber,
    category: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    price: types.maybeNull(Decimal),
    serial: types.maybeNull(types.string),
    employee_id: types.maybeNull(LazyEmployee),
    start: types.maybeNull(MomentType),
    end: types.maybeNull(MomentType),
    created_at: types.maybeNull(MomentType),
    updated_at: types.maybeNull(MomentType),
    draft: types.maybeNull(types.boolean),
    serverId: types.maybeNull(types.number)
  })

  .views(self => ({
    get lastUpdate() {
      const difference = moment.duration(
        moment(self.updated_at).diff(moment())
      );
      return difference.humanize(true);
    }
  }))
  .actions(self => ({
    update(data) {
      const beforeUpdate = getSnapshot(self);
      Object.keys(data).forEach(key => {
        // eslint-disable-next-line no-prototype-builtins
        if (self.hasOwnProperty(key)) {
          const value = data[key] === '' ? null : data[key];
          self[key] = value; // eslint-disable-line no-param-reassign
        }
      });

      const afterUpdate = getSnapshot(self);

      if (beforeUpdate !== afterUpdate) {
        if (!self.draft) {
          self.saveChanges();
        } else if (self.draft && self.name) {
          self.publishDraft();
        }
      }
    },
    publishDraft: flow(function* publishDraft() {
      const employee = getParent(self, 3);

      try {
        const { data, status } = yield axios.post(
          `/api/v1/employees/${employee.id}/equipment`,
          JSON.stringify({
            equipment: {
              category: self.category,
              description: self.description,
              name: self.name,
              price: self.price,
              start: self.start,
              serial: self.serial
            }
          })
        );
        if (status === 200) {
          applySnapshot(self, {
            ...data,
            draft: false,
            serverId: data.id,
            id: self.id
          });
        }
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    }),
    saveChanges: flow(function* saveChanges() {
      const employee = getParent(self, 2);
      try {
        const { data, status } = yield axios.put(
          `/api/v1/employees/${employee.id}/equipment/${self.serverId ||
            self.id}`,
          JSON.stringify(self)
        );

        if (status === 200) {
          self.updated_at = data.updated_at; // eslint-disable-line no-param-reassign
        }
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    }),
    deleteEntry: flow(function* deleteEntry() {
      if (self.draft) {
        self.remove();
      } else {
        const employee = getParent(self, 2);
        try {
          const { status } = yield axios.delete(
            `/api/v1/employees/${employee.id}/equipment/${self.serverId ||
              self.id}`
          );
          if (status === 200) {
            self.remove();
          }
        } catch (error) {
          console.error(error); // eslint-disable-line no-console
        }
      }
    }),
    remove() {
      getParent(self, 2).remove(self);
    }
  }));

export const Equipment = types
  .model({
    items: types.optional(types.array(EquipmentEntry), [])
  })
  .actions(self => ({
    add(item) {
      self.items.push(item);
    },
    createDraft(data) {
      self.discardDraft();
      const newEntry = {
        ...data,
        draft: true,
        updated_at: moment(),
        start: moment(),
        category: null,
        description: null,
        name: null,
        price: null,
        end: null,
        id: Math.floor(Math.random() * -10000)
      };
      self.items.unshift(newEntry);
    },
    get: flow(function* get() {
      const employee = getParent(self, 1);
      try {
        const { data } = yield axios.get(
          `/api/v1/employees/${employee.id}/equipment`
        );
        self.items = data; // eslint-disable-line no-param-reassign
      } catch (error) {
        console.error('Failed to load employee tags', error); // eslint-disable-line no-console
      }
    }),
    discardDraft() {
      const draft = self.items.find(item => item.draft);
      if (draft) {
        draft.remove();
      }
    },
    remove(item) {
      destroy(item);
    }
  }))
  .views(self => ({
    get currentDraft() {
      return self.items.find(item => item.draft);
    }
  }));
