import { validate } from 'validate.js'; // eslint-disable-line import/no-extraneous-dependencies
import {
  validateDateRange,
  validateDateInRange,
  validateDate,
  validateIban
} from './custom-validators';

const getEndFromAttr = attributes => {
  if (attributes.end) {
    return attributes.end;
  } else if (attributes.end_date) {
    return attributes.end_date;
  }
  return attributes.expires;
};

export const initializeModelUtils = () => {
  validate.validators.validDateRange = (value, options, key, attributes) => {
    const start = attributes.start ? attributes.start : attributes.start_date;
    const end = getEndFromAttr(attributes);
    return validateDateRange(start, end);
  };

  validate.validators.dateInProjectRange = (
    value,
    options,
    key,
    attributes
  ) => {
    const { start, end } = attributes.project;
    return validateDateInRange(value, start, end, 'project');
  };

  validate.validators.validDate = value => {
    validateDate(value);
  };

  validate.validators.validIban = value => {
    return validateIban(value);
  };
};

export default { initializeModelUtils };
