import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import styled, { css, keyframes } from 'styled-components/macro';
import { SyncContext } from 'contexts/sync/sync-context';
import { Icon } from 'components/atoms';

const fadeOut = keyframes`
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
`;

const handleColor = type => {
  switch (type) {
    case 'SUCCESS':
      return 'var(--color-success)';
    case 'PENDING':
      return 'var(--color-purple)';
    case 'WARNING':
      return 'var(--color-warning)';
    case 'ERROR':
      return 'var(--color-error)';
    default:
      return 'var(--color-text-light)';
  }
};

const Message = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: var(--font-size-xs);
  transition: all 0.1s;
  color: ${({ type }) => handleColor(type)};
  margin: 1rem 0;
  animation: ${({ type }) =>
    type === 'SUCCESS' &&
    css`
      ${fadeOut} 0.6s 3.5s ease-out both;
    `};
`;

const IconContainer = styled.div`
  width: 1rem;
  margin-right: 0.5rem;
  display: grid;
  place-content: center;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const GetIcon = ({ type }) => {
  switch (type) {
    case 'SUCCESS':
      return <Icon name='checkCircled' color='var(--color-success)' />;
    case 'PENDING':
      return <Icon name='update' color='var(--color-purple)' />;
    case 'ERROR':
      return <Icon name='exclamationTriangleFilled' />;
    default:
      return '';
  }
};

const SyncStatus = () => {
  const { sync } = useContext(SyncContext);

  useEffect(() => {
    return () => {
      sync.reset();
    };
  }, []);

  return (
    <Message type={sync.status?.type}>
      <IconContainer>
        <GetIcon type={sync.status?.type} />
      </IconContainer>
      <MessageContainer>{sync.status?.message}</MessageContainer>
    </Message>
  );
};

export default observer(SyncStatus);
