import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Button } from 'components/atoms';
import { PaginationContainer, Page } from './pagination-style';

const indexes = (value, start = 1) => [...Array(value + 1).keys()].slice(start);

const Divider = () => (
  <Button
    as={Page}
    transparent
    disabled
    icon='dotsHorizontal'
    iconSize='var(--font-size-lg)'
  />
);

const calculateVisiblePages = (max, siblings, current) => {
  const minEntries = siblings + 5;
  const siblingItems = 3 + 2 * siblings;

  if (max < minEntries + 2) {
    return indexes(max);
  }

  const left = Math.max(current - siblings, 1);
  const right = Math.min(current + siblings, max);

  const showLeftDots = left > 2;
  const showRightDots = right < max - 2;

  // Only one dots to show
  if (showLeftDots !== showRightDots) {
    return [
      ...indexes(showLeftDots ? 1 : siblingItems),
      'DOTS',
      ...indexes(max, showRightDots ? max : max - siblingItems + 1)
    ];
  }

  // Show both dots
  return [1, 'LEFT', ...indexes(right, left), 'RIGHT', max];
};

const Pagination = observer(({ max, current, onChange }) => {
  const siblingCount = 1;

  const pages = calculateVisiblePages(max, siblingCount, current);

  return max > 1 ? (
    <PaginationContainer>
      <Button
        as={Page}
        transparent
        icon='chevronLeft'
        iconSize='var(--font-size-lg)'
        onClick={() => onChange(current - 1)}
        disabled={current < 2}
      />
      {pages.map(page =>
        typeof page === 'string' ? (
          <Divider key={page} />
        ) : (
          <Button
            as={Page}
            transparent
            active={current === page}
            onClick={() => onChange(page)}
            key={page}
          >
            {page}
          </Button>
        )
      )}
      <Button
        as={Page}
        transparent
        icon='chevronRight'
        iconSize='var(--font-size-lg)'
        onClick={() => onChange(current + 1)}
        disabled={current > max - 1}
      />
    </PaginationContainer>
  ) : null;
});

export default Pagination;

Pagination.propTypes = {
  max: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};
