import styled, { keyframes } from 'styled-components/macro';
import PopupComponent from 'reactjs-popup2';

const modalIn = keyframes`
  0% {
    transform:scale(0.92);
    opacity:0;
  }
  100% {
    transform:scale(1);
    opacity:1;
  }
`;

const Popup = styled(PopupComponent)`
  &-content {
    border-radius: var(--border-radius-sm);
    box-shadow: var(--shadow-md);
    border: none;
    font-size: var(--font-size-sm);
    background: var(--color-modal);
    border-top: 1px solid var(--color-text-lightest);
    margin-top: 0.25rem;
    overflow: hidden;
    animation: ${modalIn} 0.3s var(--ease-out-expo) both;
    z-index: 9999999999 !important;
  }
`;

export default Popup;
