import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';

import Number from '../number/number';

const Balance = observer(({ employee }) => {
  useEffect(() => {
    employee.loadSummary(moment());
  }, []);

  const summary = employee.summary(moment());
  const balance = summary ? summary.balance : 0;

  return <Number value={balance} both suffix='h' />;
});

export default Balance;
