/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext, useRef } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import DayPicker from 'react-day-picker/DayPicker';
import 'react-day-picker/lib/style.css';
import classNames from 'classnames';

import { formatDate, parseDate } from 'react-day-picker/moment';
import { Icon, Popup } from 'components/atoms';
import { ViewportContext } from 'contexts';

import {
  CalendarContainer,
  CalendarNavbar,
  DatePickerContainer
} from './date-picker-style';

const isOutOfRange = (date, min, max) => {
  const isBeforeMinDate = min && moment(date).isBefore(min);
  const isAfterMaxDate = max && moment(date).isAfter(moment(max).add(1, 'day'));

  return isBeforeMinDate || isAfterMaxDate;
};

const renderDay = (minDate, maxDate) => d => {
  return (
    <div
      className={classNames('date-picker__content', {
        'DayPicker-Day--disabled': isOutOfRange(d, minDate, maxDate)
      })}
    >
      {d.getDate()}
    </div>
  );
};

const DatePicker = observer(props => {
  const {
    onChange,
    value: selectedDate,
    disabled,
    placeholder,
    allowEmptyValue = false,
    onOpen,
    onClose,
    closeOnSelect = false,
    position,
    minDate,
    maxDate,
    ...rest
  } = props;

  const { breakpoint } = useContext(ViewportContext);
  const popupRef = useRef();

  const [dateValue, setDateValue] = useState(
    allowEmptyValue
      ? null
      : selectedDate?.format('D.M.YYYY') || moment().format('D.M.YYYY')
  );

  useEffect(() => {
    if (selectedDate && selectedDate.isValid()) {
      setDateValue(selectedDate.format('D.M.YYYY'));
    }
  }, [selectedDate]);

  // eslint-disable-next-line consistent-return
  const handleDayChange = value => {
    if (isOutOfRange(value, minDate, maxDate)) {
      return;
    }
    const newValue = value === undefined ? value : moment(value).startOf('day');
    onChange(newValue);
    setDateValue(value);
    if (closeOnSelect) {
      popupRef.current.close();
    }
  };
  const CustomOverlay = ({ children }) => {
    return <div style={{ display: 'none' }}>{children}</div>;
  };
  const Navbar = ({ onPreviousClick, onNextClick, month, nextMonth }) => {
    let isPreviousMonthInsideOfRange = true;
    let isNextMonthInsideOfRange = true;
    if (minDate) {
      const previousMonth = moment(month).subtract(1, 'months');
      isPreviousMonthInsideOfRange = moment(minDate).isSameOrBefore(
        moment(previousMonth).endOf('month')
      );
    }
    if (maxDate)
      isNextMonthInsideOfRange = moment(maxDate).isSameOrAfter(
        moment(nextMonth).startOf('month')
      );

    return (
      <CalendarNavbar>
        {isPreviousMonthInsideOfRange ? (
          <div onClick={() => onPreviousClick()}>
            <Icon name='chevronLeft' />
          </div>
        ) : (
          <div />
        )}
        {isNextMonthInsideOfRange ? (
          <div onClick={() => onNextClick()}>
            <Icon name='chevronRight' />
          </div>
        ) : (
          <div />
        )}
      </CalendarNavbar>
    );
  };

  const displayValue = dateValue || moment();

  return (
    <DatePickerContainer {...rest}>
      <Popup
        closeOnDocumentClick
        {...(breakpoint.sm
          ? { position: position || 'bottom left' }
          : { modal: true })}
        trigger={() => (
          <div>
            <DayPickerInput
              inputProps={{ disabled }}
              value={dateValue}
              placeholder={placeholder}
              format='D.M.YYYY'
              formatDate={formatDate}
              parseDate={parseDate}
              onDayChange={handleDayChange}
              overlayComponent={CustomOverlay}
              keepFocus
            />
          </div>
        )}
        disabled={disabled}
        onOpen={onOpen}
        onClose={onClose}
        ref={popupRef}
      >
        <CalendarContainer
          className={classNames({
            'disable-outside': minDate || maxDate
          })}
        >
          <DayPicker
            onDayClick={handleDayChange}
            selectedDays={moment(displayValue, 'D.M.YYYY').toDate()}
            month={moment(displayValue, 'D.M.YYYY').toDate()}
            renderDay={renderDay(minDate, maxDate)}
            firstDayOfWeek={1}
            numberOfMonths={1}
            showWeekNumbers
            showOutsideDays
            weekdaysShort={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
            navbarElement={<Navbar />}
          />
        </CalendarContainer>
      </Popup>
    </DatePickerContainer>
  );
});

export default DatePicker;
