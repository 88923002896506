import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import { DerplingContext } from 'contexts';

import { Badge, Container, Derpling } from './derpling-button-style';

const DerplingButton = () => {
  const derpling = useContext(DerplingContext);
  const { openAllAvailable, showBadge } = derpling;

  return (
    <>
      <Container onClick={() => openAllAvailable()}>
        {showBadge ? <Badge>!</Badge> : null}
        <Derpling />
      </Container>
    </>
  );
};

export default observer(DerplingButton);
