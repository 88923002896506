import React, { useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { observer } from 'mobx-react';
import { ViewportContext } from 'contexts';
import { Icon } from 'components/atoms';
import {
  StyledPopup,
  Button,
  Contents,
  Footer,
  YearsWrapper
} from './month-selector-style';

const moment = extendMoment(Moment);

const MonthSelector = observer(props => {
  const { onOpen, todayButton, yearMode, children } = props;
  const { breakpoint } = useContext(ViewportContext);

  const [selectedDate, setSelectedDate] = useState(props.date);
  const [arrows, setArrows] = useState({ top: false, bottom: true });

  const popupRef = useRef();

  const handleChange = selection => {
    setSelectedDate(selection);
    props.onChange(selection);
  };

  const onMonthClick = selection => {
    handleChange(selection);
  };

  const onYearClick = (selection, range) => {
    const selectedMonth = selectedDate.month();
    const date = selection.month(selectedMonth);
    if (range.contains(date)) {
      handleChange(date);
    } else {
      handleChange(selection);
    }
  };

  const onTodayClick = () => {
    handleChange(moment());
    popupRef.current.close();
  };

  const createRange = () => {
    const { start, end } = props;
    const rangeStart = !start ? end.clone().subtract(5, 'years') : start;
    return moment.range(rangeStart, end);
  };

  const createContents = (range, contentsArray, type, format, onClick) => {
    return (
      <div>
        {contentsArray.map(item => {
          return (
            <Button
              type='button'
              className={selectedDate.isSame(item, type) ? 'is-selected' : ''}
              key={item}
              onClick={() => onClick(item, range)}
            >
              {item.format(format)}
            </Button>
          );
        })}
      </div>
    );
  };

  const renderMonths = () => {
    if (yearMode) return null;

    const range = createRange();
    const span = moment.range(
      moment(selectedDate).startOf('year'),
      moment(selectedDate).endOf('year')
    );
    const intersect = range.intersect(span);
    let months;
    if (!intersect) {
      months = [selectedDate];
    } else {
      months = Array.from(intersect.by('month'));
    }
    const contents = createContents(
      range,
      months,
      'month',
      'MMMM',
      onMonthClick
    );
    return contents;
  };

  const renderYears = () => {
    const range = createRange();
    const monthsInRange = Array.from(range.reverseBy('month'));
    const uniqueYearsInRange = monthsInRange.filter((value, index, self) => {
      const i = self.map(e => e.year()).indexOf(value.year());
      return i === index;
    });
    const contents = createContents(
      range,
      uniqueYearsInRange,
      'year',
      'YYYY',
      onYearClick
    );
    return contents;
  };

  const handleYearScroll = event => {
    const { scrollTop, scrollHeight, offsetHeight } = event.target;

    const top = scrollTop > 5;
    const bottom = scrollTop + offsetHeight < scrollHeight - 10;
    // TODO: This re-renders on every tick, should probably fix
    setArrows({ top, bottom });
  };

  return (
    <div>
      <StyledPopup
        closeOnEscape
        closeOnDocumentClick
        position={breakpoint.md ? 'bottom center' : 'bottom right'}
        trigger={children}
        arrow={false}
        onOpen={onOpen}
        contentStyle={false}
        ref={popupRef}
      >
        <React.Fragment>
          <Contents>
            {renderMonths()}
            <YearsWrapper onScroll={handleYearScroll}>
              {arrows.top && <Icon name='chevronUp' />}
              {renderYears()}
              {arrows.bottom && <Icon name='chevronDown' />}
            </YearsWrapper>
          </Contents>
          {!todayButton || yearMode ? null : (
            <Footer>
              <Button type='button' onClick={onTodayClick}>
                <span className='label'>Today</span>
              </Button>
            </Footer>
          )}
        </React.Fragment>
      </StyledPopup>
    </div>
  );
});

export default MonthSelector;

const yearAfterCurrentYear = moment().add(1, 'years');

MonthSelector.defaultProps = {
  className: '',
  end: yearAfterCurrentYear,
  start: null,
  date: moment(),
  controller: null,
  onClose: () => {},
  onOpen: () => {},
  todayButton: false,
  modal: false
};

MonthSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  date: PropTypes.object,
  start: PropTypes.object,
  end: PropTypes.object,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  controller: PropTypes.node,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  todayButton: PropTypes.bool,
  modal: PropTypes.bool
};
