import { types, flow } from 'mobx-state-tree';
import axios from '../common/utils/axios';
import { MomentType } from './types';

export const Survey = types
  .model('Survey', {
    survey_id: types.identifier,
    post_id: types.optional(types.string, ''),
    result_id: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    createdAt: MomentType,
    completed: types.optional(types.boolean, false)
  })
  .actions(self => ({
    checkCompleteStatus: flow(function* checkCompleteStatus(employeeId) {
      try {
        const { data } = yield axios.get(
          `https://api.surveyjs.io/public/Survey/isCompleted?resultId=${self.result_id}&clientId=${employeeId}`
        );
        if (data === 'no') {
          self.completed = false; // eslint-disable-line no-param-reassign
        } else if (data === 'completed') {
          self.completed = true; // eslint-disable-line no-param-reassign
        }
      } catch (error) {
        console.log('Error loading surveys from SurveyJS', error); // eslint-disable-line no-console
      }
    }),
    setCompleted(value) {
      self.completed = value; // eslint-disable-line no-param-reassign
    }
  }));

export default Survey;
