import styled from 'styled-components/macro';
import { Input } from 'components/atoms/simple-input/simple-input-style';

export const Container = styled.div`
  width: 100%;
  padding: ${({ padding }) => padding && 'var(--sideMargin)'};

  > span {
    margin-top: 10vh;
    text-align: center;
  }
`;

export const CategorySection = styled.div`
  padding: 1rem 0;
`;

export const CategoryTitle = styled.h3`
  padding-left: 0.25rem;
  font-size: var(--font-size-lg);
  text-transform: capitalize;
  color: var(--color-text-darkest);
`;

export const CategoryTags = styled.div`
  padding: 1rem 0;
`;

export const SearchInput = styled(Input)`
  border-color: var(--color-text-lightest);
  border-radius: var(--border-radius-lg);
  padding: 0.4rem 0.8rem;
  margin-left: 0.5rem;
  font-size: var(--font-size-md);
`;
