import React, { useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { AuthContext } from '../../contexts/auth/auth-context';

const AuthRoute = observer(({ children, ...rest }) => {
  const { pathname: from } = useLocation();
  const { isAuth, settings } = useContext(AuthContext);

  const isIndex = from === '/';
  const isLogin = from === '/login';

  // TODO: Safety redirection shouldn't be necessary / should be updated to refresh
  const Home = () => <Redirect to={settings.home || '/hours'} />;
  const Login = () => <Redirect to={{ pathname: '/login', state: { from } }} />;

  const content = () => (isIndex || isLogin ? <Home /> : children);
  const redirect = () => (isLogin ? null : <Login />);

  return <Route {...rest}>{isAuth ? content() : redirect()}</Route>;
});

export default AuthRoute;
