import { types, destroy, flow, applySnapshot } from 'mobx-state-tree';
import moment from 'moment';
import axios from '../common/utils/axios';

import {
  MomentType,
  PercentType,
  LazyEmployee,
  LazyProject,
  NullStringType
} from './types';

import { EmployeeCv } from './cv/cv-model';
import { Employee } from './employee-model';
import { Continuation } from './assignment-model';

const EmployeeAbsence = types.model('EmployeeAbsence', {
  id: types.identifierNumber,
  until: types.maybeNull(MomentType),
  reason: types.maybeNull(types.string)
});

const ResourcingProject = types.model('ResourcingProject', {
  id: types.identifierNumber,
  name: types.optional(types.string, ''),
  contact: types.optional(types.string, ''),
  color: types.optional(types.string, '#40b1bf'),
  start: types.optional(MomentType, moment().startOf('day')),
  end: types.optional(MomentType, moment().add(6, 'months')),
  description: types.optional(types.string, ''),
  category: types.optional(types.string, ''),
  common: types.optional(types.boolean, false),
  customer: types.maybeNull(types.string),
  managers: types.array(LazyEmployee)
});

const ResourcingAssignment = types.model('ResourcingAssignment', {
  id: types.identifierNumber,
  task: types.optional(NullStringType),
  start: types.optional(MomentType, moment('')),
  end: types.optional(MomentType, moment('')),
  deletable: types.optional(types.boolean, true),
  alloc: types.optional(PercentType, '1'),
  employee: types.maybeNull(LazyEmployee),
  project: types.union(LazyProject, ResourcingProject),
  continuation: types.maybeNull(Continuation),
  color: types.maybeNull(types.string)
});

const ResourcingOffer = types.model('ResourcingOffer', {
  id: types.identifierNumber,
  name: types.optional(types.string, ''),
  customer: types.maybeNull(types.string)
});

const ResourcingDeal = types.model('ResourcingDeal', {
  id: types.identifierNumber,
  name: types.optional(types.string, ''),
  customer: types.maybeNull(types.string)
});

export const Resourcing = types
  .model('Resourcing', {
    id: types.identifierNumber,
    absent_until: types.maybeNull(MomentType),
    allocation: types.maybeNull(PercentType, '1'),
    available_at: types.maybeNull(MomentType),
    company: types.maybeNull(types.string),
    employee: types.maybeNull(Employee),
    suspended: types.maybeNull(MomentType),
    cv: types.optional(EmployeeCv, () => EmployeeCv.create({ id: -1 })),
    cv_id: types.maybeNull(types.number),
    cv_updated_at: types.maybeNull(MomentType),
    days: types.maybeNull(types.number),
    deals: types.array(ResourcingDeal),
    latest_entry: types.maybeNull(MomentType),
    location: types.maybeNull(types.string),
    offers: types.maybeNull(types.array(ResourcingOffer)),
    offers_for: types.maybeNull(types.string),
    active_projects: types.array(ResourcingAssignment),
    role: types.string,
    rotation: types.optional(types.number, 0),
    sales_note: types.maybeNull(types.string),
    tags: types.array(types.number),
    title: types.maybeNull(types.string),
    absence: types.maybeNull(EmployeeAbsence),
    latest_project: types.maybeNull(ResourcingAssignment),
    metadata: types.model({
      offer_ideas: types.maybeNull(types.string),
      ambitions: types.maybeNull(types.string),
      notice: types.maybeNull(types.string),
      salary_rate: types.maybeNull(types.string),
      industry_expertise: types.maybeNull(types.string)
    })
  })
  .volatile(() => ({
    collapsed: true
  }))
  .views(self => {
    return {
      get customers() {
        return [...new Set(self.deals.map(d => d.customer).filter(Boolean))];
      }
    };
  })
  .actions(self => ({
    setContinuation(continuation, continuationTo) {
      self.continuation = continuation; // eslint-disable-line no-param-reassign
      self.continuation_end = continuationTo; // eslint-disable-line no-param-reassign
    },
    setCollapsed(collapse) {
      self.collapsed = collapse; // eslint-disable-line no-param-reassign
    },
    setRotation(value) {
      self.rotation = value; // eslint-disable-line no-param-reassign
      self.employee.setRotation(value);
    },
    addDeal(deal) {
      self.deals.push(deal);
    },
    removeDeal: flow(function* removeDeal(deal) {
      const removed = self.deals.find(({ id }) => deal.id === id);
      yield axios.delete(`/api/v1/deals/${removed.id}/employees/${self.id}`);
      destroy(removed);
    }),
    updateMetadata: flow(function* updateMetadata(props) {
      const metadata = { ...self.metadata, ...props };
      const employee = { metadata };
      const { data } = yield axios.put(
        `/api/v1/employees/${self.id}`,
        JSON.stringify({ employee })
      );

      applySnapshot(self, { ...self, metadata: data.metadata });
    })
  }));

export default Resourcing;
