import React from 'react';
import { CustomPicker } from 'react-color';
import { Hue, Saturation, Alpha } from 'react-color/lib/components/common';

import {
  ColorPickerContainer,
  SaturationContainer,
  SecondaryContainer,
  ColorCode,
  SliderContainer,
  RoundPicker
} from './color-picker-style';

const ColorPicker = props => {
  const { onChange, label, showAlpha } = props;

  return (
    <ColorPickerContainer>
      <SaturationContainer>
        <Saturation {...props} onChange={onChange} />
      </SaturationContainer>
      <SecondaryContainer>
        <h4>{label || 'Pick Color'}</h4>
        <ColorCode {...props} />
        <SliderContainer>
          <Hue {...props} pointer={RoundPicker} onChange={onChange} />
        </SliderContainer>
        {showAlpha && (
          <SliderContainer>
            <Alpha {...props} pointer={RoundPicker} onChange={onChange} />
          </SliderContainer>
        )}
      </SecondaryContainer>
    </ColorPickerContainer>
  );
};

export default CustomPicker(ColorPicker);
