import styled from 'styled-components/macro';

const handleSize = props => {
  switch (true) {
    case props.small:
      return 'var(--font-size-xs)';
    case props.large:
      return 'var(--font-size-xl)';
    default:
      return 'var(--font-size-sm)';
  }
};

const handleColor = props => {
  switch (true) {
    case props.error:
      return 'var(--color-error-dark)';
    case props.disabled:
      return 'var(--color-text-light)';
    default:
      return 'var(--color-text-darkest)';
  }
};

const handleWeight = props => {
  switch (true) {
    case props.small:
    case props.disabled:
      return 400;
    default:
      return 600;
  }
};

// eslint-disable-next-line
export const Label = styled.span`
  position: ${({ absolute }) => absolute && 'absolute'};
  font-size: ${handleSize};
  color: ${handleColor};
  margin-bottom: 0.25rem;
  font-weight: ${handleWeight};
  display: block;
  text-transform: ${({ capitalize }) => capitalize && 'capitalize'};
`;

export const Page = styled.div`
  width: 100%;
  padding: var(--sideMargin);
`;
