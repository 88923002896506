import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Modal } from 'components/atoms';
import { AvatarUploader } from 'components/organisms';
import { Button, Container, Overlay, AvatarContainer } from './avatar-style';

export const Avatar = ({ title, style, size, list, large, ...rest }) => {
  const [error, setError] = useState(false);
  return (
    <AvatarContainer
      title={title}
      style={style}
      error={error}
      size={size}
      $list={list}
      $large={large}
    >
      <img {...rest} alt='' onError={() => setError(true)} />
      <Icon name='profile' size='50%' />
    </AvatarContainer>
  );
};

const EmployeeAvatar = props => {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    role,
    onDelete,
    enableDelete,
    enableEdit,
    className,
    ...others
  } = props;

  const style = {
    ...others.style,
    ...{
      border: ['external', 'freelance'].includes(role)
        ? '2px solid red'
        : 'none',
      verticalAlign: 'middle'
    }
  };

  const createBtn = (icon, clickHandler) => (
    <Button
      style={{ height: others.size, width: others.size }}
      onClick={clickHandler}
    >
      <Icon name={icon} size='1.5rem' />
    </Button>
  );

  return (
    <>
      <Container size={others.size}>
        <Avatar {...others} style={style} />
        <Overlay enableDelete={enableDelete} enableEdit={enableEdit} />
        {enableDelete && createBtn('trash', () => onDelete())}
        {enableEdit && createBtn('pencil2', () => setModalOpen(true))}
      </Container>
      {enableEdit && (
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <AvatarUploader closeModal={() => setModalOpen(false)} />
        </Modal>
      )}
    </>
  );
};

EmployeeAvatar.propTypes = {
  enableDelete: PropTypes.bool,
  enableEdit: PropTypes.bool,
  onDelete: PropTypes.func,
  role: PropTypes.string,
  src: PropTypes.string
};

EmployeeAvatar.defaultProps = {
  enableDelete: false,
  enableEdit: false,
  onDelete: () => {},
  role: 'user',
  src: null
};

export default EmployeeAvatar;
