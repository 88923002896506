const localeData = {
  fi: {
    locale: 'fi-FI',
    currency: 'EUR',
    messages: {
      language: 'Finnish',
      cvLink: 'Katso koko CV',
      contact: 'Yhteyshenkilönne',
      offerDescription:
        '<p>Tältä sivulta näette projektiinne tarjotut henkilöt, ja pääsette tarkastelemaan heidän ansioluetteloitaan. Tarjoukseen liittyvät tarkemmat hintatiedot ja muut yksityiskohdat löydätte sähköpostiviestistä, jonka liitteenä tämän sivun linkki toimitettiin.&nbsp;</p><p>Huomioittehan että Bitfactorilla on välimyyntivaraus työntekijöihinsä, joten on mahdollista että projektiinne osallistuu toinen henkilö samalla osaamisella ja kokemustasolla. Otattehan mahdollisimman pian yhteyttä omaan <a href="#yhteyshenkilonne">Bitfactor-yhteyshenkilöönne</a>.</p>',
      description: 'Kuvaus',
      experience: 'Kokemus',
      toolsAndTech: 'Työkalut ja Teknologiat',
      certificates: 'Sertifikaatit',
      education: 'Koulutus',
      workHistory: 'Työhistoria',
      apprentice: 'Harjoittelija',
      professional: 'Ammattilainen',
      expert: 'Asiantuntija',
      title: 'Nimi',
      issued: 'Pvm',
      date: 'Pvm',
      lang: 'Kieli',
      languages: 'Kielitaito',
      proficiency: 'Taso',
      'lang:basic': 'Perusteet',
      'lang:fluent': 'Sujuva',
      'lang:native': 'Natiivi',
      candidate: 'Kandidaatti'
    }
  },
  en: {
    locale: 'en',
    currency: 'EUR',
    messages: {
      language: 'English',
      cvLink: 'Full resume',
      contact: 'Contact',
      offerDescription:
        '<p>On this page, you can browse the resumes of the people attached to your offer. <br>Information on pricing and other details related to the offer can be found in the e-mail in which you received this link.&nbsp;</p><p>Please note that Bitfactor has an intermediate sales reservation for its employees, so it is possible that another person with the same level of expertise and experience will be involved in your project. Please contact <a href="#yhteyshenkilonne">your Bitfactor sales representative</a> as soon as possible.</p>',
      description: 'Description',
      experience: 'Experience',
      toolsAndTech: 'Tools and Technologies',
      certificates: 'Certificates',
      education: 'Education',
      workHistory: 'Work History',
      apprentice: 'Apprentice',
      professional: 'Professional',
      expert: 'Expert',
      title: 'Title',
      issued: 'Issued',
      date: 'Date',
      lang: 'Language',
      languages: 'Language skills',
      proficiency: 'Proficiency',
      'lang:basic': 'Basic',
      'lang:fluent': 'Fluent',
      'lang:native': 'Native',
      candidate: 'Candidate'
    }
  },
  sv: {
    locale: 'sv',
    currency: 'EUR',
    messages: {
      description: 'Beskrivning',
      experience: 'Erfarenhet',
      toolsAndTech: 'Verktyg och Tekniker',
      certificates: 'Certifikat',
      education: 'Utbildning',
      workHistory: 'Jobbhistorik',
      apprentice: 'Lärling',
      professional: 'Professionell',
      expert: 'Expert',
      title: 'Namn',
      issued: 'Datum',
      date: 'Datum',
      lang: 'Språk',
      languages: 'Språkkunskaper',
      proficiency: 'Skicklighet',
      'lang:basic': 'Basisk',
      'lang:fluent': 'Flytande',
      'lang:native': 'Modersmål',
      candidate: 'Kandidat'
    }
  }
};

export default localeData;
