import styled, { css } from 'styled-components/macro';
import { breakpointsRefresh as breakpoints } from 'common/styles';

export const Container = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding: var(--sideMargin) var(--sideMargin) calc(var(--sideMargin) * 2)
    var(--sideMargin);
  width: 100%;
  position: relative;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const DeleteButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const Backlink = styled.div`
  margin-bottom: 1rem;
  color: var(--color-text);
  display: flex;
  align-items: center;
  font-size: var(--font-size-xs);
  span {
    margin-left: 0.5rem;
  }
  ${breakpoints.md} {
    display: none;
  }
`;

const unstyleInput = css`
  width: 100%;
  height: 100%;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  all: inherit;
  outline: none;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  opacity: 1;
  -webkit-text-fill-color: inherit;
  -webkit-appearance: none;
`;
export const Input = styled.input`
  ${unstyleInput}
  &:disabled, &:focus {
    ${unstyleInput}
  }
  &::placeholder {
    color: var(--color-input-placeholder);
  }
  // Set placeholder for empty date input for Safari
  @supports (-webkit-hyphens: none) {
    &[type='date'][value=''] {
      visibility: hidden;
      &:before {
        content: attr(placeholder);
        display: block;
        position: absolute;
        visibility: visible;
      }
    }
  }
`;
export const Textarea = styled.textarea`
  ${unstyleInput}
  & :disabled,
  & :focus {
    ${unstyleInput}
  }
  & :disabled {
    white-space: pre-wrap;
  }
`;
export const Title = styled.div`
  font-size: var(--font-size-xl);
  font-weight: 600;
  color: var(--color-text-darker);
  ${Input} {
    color: var(--color-text-darker);
    -webkit-text-fill-color: var(--color-text-darker);
  }
`;
export const Time = styled.div`
  font-size: var(--font-size-sm);
  color: var(--color-text);
  position: absolute;
  top: 0;
  right: 1rem;
`;
export const Body = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  max-width: 80ch;
  line-height: 1.55;
  color: var(--color-text-dark);
  ${Input} {
    color: var(--color-text-dark);
    -webkit-text-fill-color: var(--color-text-dark);
  }
`;
export const MetaContainer = styled.div`
  max-width: 80ch;
  line-height: 1.55;
  color: var(--color-text-dark);
  display: grid;
  grid-template-columns: 1fr 1fr;

  & + ${Body} {
    margin-top: 2rem;
  }
  ${Input} {
    &:disabled,
    &:focus {
      color: var(--color-text-dark);
      -webkit-text-fill-color: var(--color-text-dark);
    }
    &::placeholder {
      color: var(--color-input-placeholder);
    }
  }
`;

export const MetaItem = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`;
export const MetaTitle = styled.div`
  font-weight: 700;
  color: var(--color-text-darker);
`;
export const MetaValue = styled.div``;
