import { types, destroy, detach } from 'mobx-state-tree';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { withValidations } from 'mst-validatejs';
import { MomentType } from '../types';
import Project from './project-model';

const Employer = types
  .model('Employer', {
    title: types.optional(types.string, ''),
    employer: types.optional(types.string, ''),
    end_date: types.optional(MomentType, moment()),
    start_date: types.optional(MomentType, moment()),
    projects: types.array(Project),
    id: types.maybeNull(types.string)
  })
  .preProcessSnapshot(snapshot => {
    return {
      id: uuidv4(),
      ...snapshot
    };
  })
  .postProcessSnapshot(snapshot => {
    delete snapshot.id; // eslint-disable-line no-param-reassign
    return snapshot;
  })
  .extend(
    withValidations({
      start_date: {
        validDateRange: true
      },
      end_date: {
        validDateRange: true
      }
    })
  )
  .actions(self => ({
    setName(name) {
      self.employer = name; // eslint-disable-line no-param-reassign
    },
    setTitle(title) {
      self.title = title; // eslint-disable-line no-param-reassign
    },
    setStartDate(date) {
      self.start_date = date; // eslint-disable-line no-param-reassign
    },
    setEndDate(date) {
      self.end_date = date; // eslint-disable-line no-param-reassign
    },
    addProject(project) {
      self.projects.push(project);
    },
    removeProject(project) {
      destroy(project);
    },
    reorderProjects(startIndex, endIndex) {
      self.projects.splice(endIndex, 0, detach(self.projects[startIndex]));
    }
  }));

export default Employer;
