import { types, flow, destroy } from 'mobx-state-tree';
import moment from 'moment';

import axios from '../common/utils/axios';
import { MomentType } from './types';

const Notification = types
  .model('Notification', {
    id: types.identifierNumber,
    title: types.string,
    body: types.string,
    read_at: types.maybeNull(MomentType),
    created_at: MomentType
  })
  .views(self => ({
    get unread() {
      return !self.read_at;
    },
    get timestamp() {
      return moment
        .duration(moment(self.created_at).diff(moment()))
        .humanize(true);
    }
  }))
  .actions(self => ({
    markAsRead: flow(function* markAsRead() {
      try {
        self.setRead();
        yield axios.post('/api/v1/notifications/read', {
          notification_ids: [self.id]
        });
      } catch (error) {
        console.log('Could not mark notification as read:', error); // eslint-disable-line no-console
      }
    }),
    archive: flow(function* archive() {
      try {
        yield axios.delete(`/api/v1/notifications/${self.id}`);
      } catch (error) {
        console.log('Could not archive notification:', error); // eslint-disable-line no-console
      }
    }),
    setRead() {
      self.read_at = moment(); // eslint-disable-line no-param-reassign
    }
  }));

export const NotificationList = types
  .model('NotificationList', {
    notifications: types.map(Notification),
    initialized: types.maybeNull(types.boolean, false)
  })
  .views(self => ({
    unread() {
      return [...self.notifications.values()].filter(
        notification => notification.unread
      );
    },
    all() {
      return [...self.notifications.values()];
    },
    isEmpty() {
      return !self.all().length;
    }
  }))
  .actions(self => ({
    load: flow(function* load() {
      if (!self.initialized) {
        try {
          const { data } = yield axios.get('/api/v1/notifications');
          data.map(notification => {
            const { content, ...rest } = notification;
            return self.notifications.put({ ...content, ...rest });
          });
          self.initialized = true; // eslint-disable-line no-param-reassign
        } catch (error) {
          console.log('Error loading notification list', error); // eslint-disable-line no-console
        }
      }
      return self;
    }),
    archive(notification) {
      notification.archive();
      destroy(notification);
    },
    markAllRead: flow(function* markAllRead() {
      const notification_ids = self.unread().map(notification => {
        notification.setRead();
        return notification.id;
      });
      yield axios.post('/api/v1/notifications/read', { notification_ids });
    })
  }));

export default NotificationList;
