import { types, flow, destroy } from 'mobx-state-tree';
import { chunkProcessor } from 'mobx-utils';
import { observable } from 'mobx';
import moment from 'moment';

import axios from '../common/utils/axios';
import { Assignment } from './assignment-model';
import { employeeIds } from './employee-list-model';
import { calculateDateRangeQuery } from '../common/utils/time';

const batchQueue = observable.array();

export const AssignmentsList = types
  .model('AssigmnetList', {
    assignments: types.map(Assignment),
    unsaved: types.map(Assignment)
  })
  .views(self => ({
    forEmployee(employee) {
      return [...self.assignments.values()].filter(
        a => a.employee.id === employee.id
      );
    },
    forProject(project, unsaved = false) {
      const list = unsaved ? self.unsaved : self.assignments;
      return [...list.values()].filter(a => a.project.id === project.id);
    },
    inRangeForEmployee(range, employee) {
      const assignments = self.forEmployee(employee).filter(a => {
        return (
          range.from.isSameOrAfter(a.start, 'day') &&
          range.to.isSameOrBefore(a.end, 'day')
        );
      });
      assignments.sort((a, b) => a.project.name.localeCompare(b.project.name));
      return assignments;
    },
    assignmentsInMonthForEmployee(month, employee) {
      const assignments = self
        .forEmployee(employee)
        .filter(
          a =>
            a.start.isSameOrBefore(month.endOf('month')) &&
            a.end.isSameOrAfter(month.startOf('month'))
        );
      assignments.sort((a, b) => a.project.name.localeCompare(b.project.name));
      return assignments;
    },
    for(categories, employees) {
      const eIds = employeeIds(employees);
      return [...self.assignments.values()].filter(a => {
        return (
          a.project &&
          categories.includes(a.project.category) &&
          eIds.includes(a.employee.id)
        );
      });
    }
  }))
  .actions(self => ({
    load: flow(function* load(employee, month = moment()) {
      const { start, end } = calculateDateRangeQuery(month, true);
      try {
        const { data } = yield axios.get(
          `/api/v1/employees/${employee.id}/assignments?start=${start}&end=${end}`
        );
        data.forEach(a => self.assignments.put(a));
      } catch (error) {
        console.log('Error loading project list', error); // eslint-disable-line no-console
      }
      return self;
    }),
    assignmentsFor: flow(function* assingmnetsFor(project) {
      try {
        const { data } = yield axios.get(
          `/api/v1/assignments?project_id=${project}`
        );
        data.forEach(a => self.assignments.put(a));
      } catch (error) {
        console.log('Error loading project assignments', error); // eslint-disable-line no-console
      }
      return self;
    }),
    assignmentsWithinRange: flow(function* assignmentsWithinRange(start, end) {
      const sd = start.format('YYYY-MM-DD');
      const ed = end.format('YYYY-MM-DD');
      try {
        const { data } = yield axios.get(
          `/api/v1/assignments?start=${sd}&end=${ed}`
        );
        data.forEach(a => self.assignments.put(a));
      } catch (error) {
        console.log('Error loading project list', error); // eslint-disable-line no-console
      }
      return self;
    }),
    create: flow(function* create(project, employee, params = {}) {
      const a = Assignment.create({
        id: -1,
        employee: employee.id,
        start: project.start,
        end: project.end,
        project: project.id,
        ...params
      });
      try {
        const { data } = yield axios.post(
          `/api/v1/projects/${project.id}/assignments`,
          JSON.stringify(a)
        );
        const assignment = Assignment.create(data);
        self.assignments.put(assignment);
      } catch (error) {
        console.log('Could not add new assignment', error); // eslint-disable-line no-console
      }
    }),
    // TODO: Proper names
    init(project, data) {
      const ids = [...self.unsaved.values()].map(a => a.id);
      const id = Math.min(...ids, 0) - 1;
      const assignment = Assignment.create({
        id,
        project: project.id,
        start: project.start,
        end: project.end,
        ...data
      });
      self.unsaved.put(assignment);
      return assignment;
    },
    cancel(assignment) {
      destroy(assignment);
    },
    saveNewAssignment: flow(function* _saveNew(assignment, reset = true) {
      try {
        const { data } = yield axios.post(
          `/api/v1/projects/${assignment.project.id}/assignments`,
          JSON.stringify(assignment)
        );
        if (reset) destroy(assignment);
        return self.assignments.put(Assignment.create(data));
      } catch (error) {
        console.log('Could not save new assignment', error); // eslint-disable-line no-console
        return null;
      }
    }),
    delete: flow(function* _delete(assignment) {
      try {
        yield axios.delete(`/api/v1/assignments/${assignment.id}`);
        destroy(assignment);
      } catch (error) {
        console.log('Could not delete assignment', assignment, error); // eslint-disable-line no-console
      }
    }),
    batch(projects) {
      batchQueue.push(...projects);
    },
    batchLoad: flow(function* batchLoad(ids) {
      const { data } = yield axios.get(
        `/api/v1/assignments?projects=${JSON.stringify(ids)}`
      );
      data.map(a => self.assignments.put(a));
    }),
    afterCreate() {
      chunkProcessor(batchQueue, data => self.batchLoad(data), 50, 0);
    }
  }));

export const assignmentIds = assignments => {
  return assignments.map(a => a.id);
};

export const employeesFromAssignments = assignments => {
  // The incoming assignment list can could contain multiple assignments
  // for a single employee ... hence the Set/spread to return only unique employees
  return [...new Set(assignments.map(a => a.employee))];
};

export const filterByProjectId = (assignments, projectId) => {
  return assignments.filter(a => a.project.id === projectId);
};

export const filterByProject = (assignments, name) => {
  if (name === '' || !name) {
    return assignments;
  }
  const r = new RegExp(name, 'i');
  return assignments.filter(a => a.project.name.search(r) >= 0);
};

export const filterByEmployee = (assignments, name) => {
  if (name === '' || !name) {
    return assignments;
  }
  const r = new RegExp(name, 'i');
  return assignments.filter(a => a.employee.fullName().search(r) >= 0);
};

export const groupByEmployee = (assignments, employees = undefined) => {
  const emps = employeesFromAssignments(assignments);

  // Map the provided employees and select the assignments for those
  let groupedAssignments = emps.map(e => {
    return {
      employee: e,
      assignments: assignments.filter(a => a.employee.id === e.id)
    };
  });

  const earliestEndDate = assignmentArray => {
    const endDates = assignmentArray.map(a => a.end);
    return moment.max(endDates);
  };

  // return employee all assinments amount
  const employeeAssignmentsAllocations = assignmentArray => {
    return assignmentArray
      .map(assignment => assignment.alloc)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
  };

  groupedAssignments = groupedAssignments.sort((a, b) => {
    const allocationA = employeeAssignmentsAllocations(a.assignments);
    const allocationB = employeeAssignmentsAllocations(b.assignments);
    const endDateA = earliestEndDate(a.assignments);
    const endDateB = earliestEndDate(b.assignments);
    if (endDateA > endDateB) {
      return 1;
    }
    if (endDateA < endDateB || allocationA < allocationB) {
      return -1;
    }
    return 0;
  });

  const ae = employeeIds(emps);
  const difference = employees.filter(e => !ae.includes(e.id));
  difference.forEach(employee => {
    groupedAssignments.unshift({
      employee,
      assignments: [] // eslint-disable object-shorthand
    });
  });

  return groupedAssignments;
};

export const filterGroupedAssignmentsByEmployee = (
  assignments,
  employeeName
) => {
  if (!employeeName) {
    return assignments;
  }
  const r = new RegExp(employeeName, 'i');
  return assignments.filter(a => a.employee.fullName().search(r) >= 0);
};

export const filterGroupedAssignmentsByByProject = (
  assignments,
  projectName
) => {
  if (!projectName) {
    return assignments;
  }
  const r = new RegExp(projectName, 'i');

  return assignments.filter(item => {
    // eslint-disable-next-line no-param-reassign
    item.assignments = item.assignments.filter(
      a => a.project.name.search(r) >= 0
    );
    return item.assignments.length > 0;
  });
};

export const sortByEmployeeName = assignments =>
  assignments.sort((a, b) =>
    `${a.employee.last_name} ${a.employee.first_name}`.localeCompare(
      `${b.employee.last_name} ${b.employee.first_name}`
    )
  );

export const sortByTask = assignments =>
  // sort alphabetically - but put the empty strings last:
  assignments.sort((a, b) => {
    if (!a.task) {
      return 1;
    }
    if (!b.task) {
      return -1;
    }
    return a.task.localeCompare(b.task);
  });

export const sortByBilling = (assignments, month) => {
  assignments.sort((a, b) => {
    const summaryA = a.summary(month) || { billing: '0' };
    const summaryB = b.summary(month) || { billing: '0' };
    return summaryB.billing - summaryA.billing;
  });
};

export const sortByHours = (assignments, month) => {
  assignments.sort((a, b) => {
    const summaryA = a.summary(month) || { hours: '0' };
    const summaryB = b.summary(month) || { hours: '0' };
    return summaryB.hours - summaryA.hours;
  });
};

export const sortByEndDate = assignments => {
  assignments.sort((a, b) => a.end.diff(b.end));
};

export const sortByExtPrice = assignments => {
  assignments.sort((a, b) => b.external_price - a.external_price);
};

export const sortById = assignments => {
  return assignments.sort((a, b) => a.id - b.id);
};

export default AssignmentsList;
