import { types, flow, resolveIdentifier, getSnapshot } from 'mobx-state-tree';
import { observable } from 'mobx';
import moment from 'moment';

import axios from '../common/utils/axios';
import { Deal } from './deal-model';

const batchQueue = observable.array();

export const DealList = types
  .model('DealList', {
    deals: types.map(Deal),
    state: types.maybeNull(
      types.enumeration(['pending', 'ready', 'error']),
      'pending'
    )
  })
  .views(self => ({
    inMonth(month) {
      const selectedRange = moment.range(
        moment(month).startOf('month'),
        moment(month).endOf('month')
      );
      const dealsFiltered = [...self.deals.values()].filter(o => {
        const dealRange = moment.range(
          moment(o.start).startOf('day'),
          moment(o.expires).endOf('day')
        );
        return dealRange.overlaps(selectedRange);
      });
      return dealsFiltered;
    },
    all() {
      return [...self.deals.values()];
    },
    isLoaded() {
      return self.state === 'ready';
    }
  }))
  .actions(self => ({
    createDeal(data) {
      const deal = Deal.create({ ...data });
      return self.deals.put(deal);
    },
    load: flow(function* load() {
      if (!self.isLoaded()) {
        try {
          const { data } = yield axios.get('/api/v1/deals');
          data.map(deal => self.createDeal(deal));
          self.state = 'ready'; // eslint-disable-line no-param-reassign
        } catch (error) {
          console.log('Error loading deals list', error); // eslint-disable-line no-console
        }
      }
      return self;
    }),
    loadDeal(id, batch = false) {
      const deal = resolveIdentifier(Deal, self, id);
      if (deal) {
        return deal;
      }
      const d = Deal.create({ id });
      if (batch) {
        batchQueue.push(d.id);
      } else {
        d.load(id);
      }
      self.deals.put(d);
      return d;
    },
    addDeal: flow(function* addDeal(params) {
      const deal = getSnapshot(params);
      const { data } = yield axios.post(
        '/api/v1/deals',
        JSON.stringify({ deal })
      );
      self.createDeal(data);
      return data;
    }),
    deleteDeal: flow(function* deleteDeal(id) {
      self.deals.delete(id);
      yield axios.delete(`/api/v1/deals/${id}`);
    })
  }));

export const sortByName = deals =>
  deals.sort((a, b) => a.name.localeCompare(b.name));

export default DealList;
