import styled from 'styled-components/macro';

import { breakpointsRefresh as breakpoints } from 'common/styles';
import { IconContainer } from 'components/atoms/icon/icon';

export const BottomBarContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 var(--sideMargin);
  position: fixed;
  bottom: -1px;
  right: 0;
  left: 0;

  ${breakpoints.md} {
    padding-left: calc(13rem + var(--sideMargin)); // Derived from Sidebar
  }

  height: 5rem;
  background-color: var(--color-modal);

  box-shadow: var(--shadow-md-reverse);
  z-index: 50000;
`;

export const BottomBarInfo = styled.div`
  flex: 1;
  font-weight: 800;
  color: var(--color-primary-dark);
`;

export const BottomBarError = styled(BottomBarInfo)`
  color: var(--color-error-dark);

  ${IconContainer} {
    vertical-align: middle;
    margin-right: 0.5rem;
  }
`;

export const BottomBarActions = styled.div`
  flex: 0;
  display: flex;
  flex-direction: row;

  > * {
    margin-left: 0.5rem;
  }
`;
