import { useEffect, useRef } from 'react';

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (didMount.current) {
      return func();
    }
    didMount.current = true;
  }, deps);
};

export default useDidMountEffect;
