import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import notificationStore from 'stores/notificationStore';

import { Message, MessageContainer } from './derpling-message-style';

export const DerplyRestErrorFormatter = props => {
  const errorItems = Object.keys(props.error);
  const errors = errorItems.map(
    errorItem => `${errorItem} ${props.error[errorItem].join(', ')}`
  );
  return <span>{errors.join(' AND ')}</span>;
};

DerplyRestErrorFormatter.propTypes = {
  error: PropTypes.object.isRequired
};

const DerplingMessage = () => {
  const [fading, setFading] = useState(false);

  useEffect(() => {
    if (notificationStore.open) {
      setTimeout(() => {
        setFading(true);
      }, notificationStore.duration - 1000);

      setTimeout(() => {
        setFading(false);
      }, notificationStore.duration);
    }
  }, [notificationStore.open]);

  return (
    <MessageContainer className={notificationStore.open && 'is-open'}>
      {notificationStore.open && (
        <Message className={fading && 'animate-out'}>
          {notificationStore.message}
        </Message>
      )}
    </MessageContainer>
  );
};

export default inject('notificationStore')(observer(DerplingMessage));
