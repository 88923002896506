import { types } from 'mobx-state-tree';
import { withValidations } from 'mst-validatejs';

import Decimal, { MomentType, NullStringType } from './types';
import { Assignment } from './assignment-model';

export const TimeEntry = types
  .model('TimeEntry', {
    id: types.identifierNumber,
    hours: Decimal,
    comment: types.optional(NullStringType),
    day: MomentType,
    assignment: types.late(() => types.reference(Assignment)),
    projectCategory: types.optional(NullStringType)
  })
  .extend(
    withValidations({
      hours: {
        // eslint-disable-next-line
        numericality: (value, attributes, attributeName) => {
          if (
            attributes.projectCategory === 'unpaid' ||
            attributes.projectCategory === 'holiday'
          ) {
            return {
              equalTo: 7.5,
              message: 'only 7.5 allowed for holiday or unpaid'
            };
          }
          return {
            strict: true,
            divisibleBy: 0.5,
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 24,
            notLessThanOrEqualTo: 'No more than 24 hours',
            notGreaterThanOrEqualTo: 'No negative values',
            notValid: 'Check format',
            notDivisibleBy: 'Only at half hours intervals'
          };
        }
      }
    })
  )
  .views(self => ({
    toCsv() {
      // eslint-disable-next-line prettier/prettier
      return `${self.day.format('DD.MM.YYYY')},"${
        self.assignment.project.name
      }","${self.assignment.description}",${self.hours},"${self.comment}"`;
    }
  }));

export default TimeEntry;
