import styled from 'styled-components/macro';

export const Container = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;

  animation: fadeinbackground 0.22s ease-in 1;
  animation-fill-mode: forwards;
  display: ${props => (props.visible ? 'block' : 'none')};

  z-index: 998;

  @keyframes fadeinbackground {
    from {
      background-color: rgb(0, 0, 0, 0);
    }
    to {
      background-color: rgb(0, 0, 0, 0.3);
    }
  }
`;

export default Container;
