import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react';

import { DerpContext } from 'contexts';

import { Icon, Button } from 'components/atoms';

import {
  Notifications,
  NotificationItem,
  EmptyListIndicator,
  NotificationTitle,
  NewDot,
  NotificationBody,
  Derpling,
  NotificationFooter,
  NotificationMetadata
} from './notification-list-style';

const NotificationList = observer(() => {
  const { notifications } = useContext(DerpContext);

  useEffect(() => {
    notifications.load();
  }, []);

  return (
    <Notifications>
      {notifications.all().map(notification => (
        <NotificationItem key={notification.id}>
          <NotificationTitle read={!notification.unread}>
            <div>
              <NewDot />
              {notification.title}
            </div>
            <span>{notification.timestamp}</span>
          </NotificationTitle>
          <NotificationBody>{notification.body}</NotificationBody>
          <NotificationFooter>
            <NotificationMetadata>
              <Derpling />
              system
            </NotificationMetadata>
            <div>
              {notification.unread && (
                <Button
                  transparent
                  icon='check'
                  iconSize='var(--font-size-base)'
                  onClick={() => notification.markAsRead()}
                />
              )}
              <Button
                transparent
                icon='trash'
                iconSize='var(--font-size-base)'
                onClick={() => notifications.archive(notification)}
              />
            </div>
          </NotificationFooter>
        </NotificationItem>
      ))}
      {notifications.isEmpty() && (
        <EmptyListIndicator>
          <div>
            <Icon name='bell' size='5rem' fill='var(--color-text-lightest)' />
            No notifications
          </div>
        </EmptyListIndicator>
      )}
    </Notifications>
  );
});

export default NotificationList;
