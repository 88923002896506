import React from 'react';
import { observer } from 'mobx-react';

import { Icon } from 'components/atoms';

import { Window, ButtonContainer } from './derpling-popup-style';

const DerplingPopup = props => {
  const { onClose, children, visible } = props;

  return (
    <Window className={visible && 'is-open'}>
      <ButtonContainer>
        <Icon name='cross2' onClick={() => onClose()} />
      </ButtonContainer>
      {children}
    </Window>
  );
};

export default observer(DerplingPopup);
