import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { DebounceInput } from 'react-debounce-input';

import axios from 'common/utils/axios';
import useClickOutside from 'common/hooks/useClickOutside';

import { SimpleInput, Button } from 'components/atoms';

import {
  Container,
  CustomerContainer,
  CustomerAction,
  SearchContainer,
  CustomerList,
  CustomerWrapper,
  NewCustomer,
  SourcedCustomer
} from './customer-search-style';

import logo from './hubspot-logo.svg';

const search = value => {
  return axios.get(`/api/v1/customers?query=${value}`);
};

const Customer = ({ customer }) =>
  typeof customer === 'string' ? (
    customer
  ) : (
    <SourcedCustomer>
      {customer.name}
      {customer.metadata?.source === 'hubspot' && (
        <img alt='hubspot' src={logo} />
      )}
    </SourcedCustomer>
  );

const CustomerSearch = observer(
  ({ customer, onSelect, reset, filtered = [], show = 5, ...rest }) => {
    const [data, setData] = useState([]);
    const [query, setQuery] = useState('');
    const [hide, setHide] = useState(true);
    const [autofocus, setAutofocus] = useState(false);

    const setFilteredData = items => {
      setData(items.filter(item => !filtered.includes(item.id)).slice(0, show));
    };

    const createNewCustomer = () => {
      if (query.length) {
        axios
          .post('/api/v1/customers', JSON.stringify({ name: query.trim() }))
          .then(({ data: newCustomer }) => onSelect(newCustomer))
          .catch(err => {
            console.log('Error creating new customer', err); // eslint-disable-line no-console
          });
      }
    };

    useEffect(() => {
      if (query) {
        search(query).then(response => setFilteredData(response.data));
      } else {
        setData([]);
      }
    }, [query]);

    useEffect(() => {
      setQuery('');
    }, [customer]);

    const ref = useRef(null);
    useClickOutside(ref, () => setHide(true));

    const handleQuickSelect = (event, item) => {
      if (event.key === 'Enter') {
        if (item) {
          onSelect(item);
        } else {
          createNewCustomer();
        }
      }
    };

    const handleReset = () => {
      reset();
      setAutofocus(true);
    };

    return (
      <Container>
        {customer ? (
          <CustomerContainer>
            <Customer customer={customer} small />
            {reset && (
              <CustomerAction
                as={Button}
                icon='cross2'
                small
                transparent
                onClick={handleReset}
              />
            )}
          </CustomerContainer>
        ) : (
          <SearchContainer onFocus={() => setHide(false)} ref={ref}>
            <SimpleInput
              as={DebounceInput}
              value={query}
              onChange={setQuery}
              debounceTimeout={300}
              onKeyDown={e => handleQuickSelect(e, data[0])}
              autoFocus={autofocus}
              icon='search'
              {...rest}
            />
            {!hide && (data.length || query.length) ? (
              <CustomerList>
                {data.map(item => (
                  <CustomerWrapper
                    key={item.id}
                    tabIndex='0'
                    onClick={() => onSelect(item)}
                    onKeyDown={e => handleQuickSelect(e, item)}
                  >
                    <Customer customer={item} />
                  </CustomerWrapper>
                ))}
                {!data.length && query.length ? (
                  <CustomerWrapper
                    tabIndex='0'
                    onClick={createNewCustomer}
                    onKeyDown={e => handleQuickSelect(e)}
                  >
                    <NewCustomer>New customer: {query}</NewCustomer>
                  </CustomerWrapper>
                ) : null}
              </CustomerList>
            ) : null}
          </SearchContainer>
        )}
      </Container>
    );
  }
);

export default CustomerSearch;
