import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import Icon from '../icon/icon';
import { Input, Label } from './simple-input-style';

export const SimpleInput = observer(props => {
  let input;

  const {
    disabled,
    id,
    name,
    target,
    onChange,
    onCommit,
    placeholder,
    className,
    hasError,
    inputRef,
    children,
    component,
    options,
    colorText,
    colorHover,
    colorFocus,
    colorPlaceholder,
    colorBackground,
    blurred,
    autosize,
    icon,
    iconSize,
    ...others
  } = props;

  const [visited, setVisited] = useState(false);

  // Reset input value if target changes
  useEffect(() => {
    if (target && input) {
      input.value = props.defaultValue;
    }
  }, [target]);

  const autoSizeTextArea = () => {
    if (autosize && component === 'textarea' && input.scrollHeight > 0) {
      input.style.height = 'auto';
      input.style.height = `${input.scrollHeight}px`;
    }
  };

  const handleChange = event => {
    autoSizeTextArea();
    onChange(event.target.value);
  };

  useEffect(autoSizeTextArea, []);

  return (
    <Label htmlFor={id} hasError={hasError} ref={inputRef} iconSize={iconSize}>
      <Input
        as={component}
        disabled={disabled}
        $hasError={hasError}
        $colorText={colorText}
        $colorHover={colorHover}
        $colorFocus={colorFocus}
        $colorPlaceholder={colorPlaceholder}
        $colorBackground={colorBackground}
        $autosize={autosize}
        $blurred={blurred && !visited}
        placeholder={placeholder}
        ref={el => {
          input = el;
        }}
        onFocus={() => setVisited(true)}
        onChange={e => handleChange(e)}
        onBlur={() => onCommit(input.value)}
        {...others}
      >
        {options}
      </Input>
      {Boolean(icon) && <Icon name={icon} size={iconSize} />}

      {children}
    </Label>
  );
});

SimpleInput.defaultProps = {
  disabled: false,
  blurred: false,
  autosize: false,
  placeholder: '',
  className: '',
  hasError: false,
  onChange: () => {},
  onCommit: () => {},
  inputRef: null,
  children: null
};

SimpleInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onCommit: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  blurred: PropTypes.bool,
  autosize: PropTypes.bool,
  hasError: PropTypes.bool,
  inputRef: PropTypes.object,
  children: PropTypes.node
};

export default SimpleInput;
