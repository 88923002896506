import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';

import Login from '../../pages/login/login';

import axios, { setAuthToken } from '../../common/utils/axios';
import useDidMountEffect from '../../common/hooks';

const AuthContext = React.createContext({});

const AUTH0 = new auth0.WebAuth({
  domain: process.env.AUTH_USE_MOCK_DATA
    ? process.env.AUTH_URL
    : 'bitfactor.eu.auth0.com',
  clientID: 'gYD4SJLyxUjPzANJT1nx24Ut6jevq7eK',
  redirectUri: `${window.location.origin}/login`,
  responseType: 'token id_token',
  scope: 'openid'
});

const decodeUser = token => {
  const { id, role, email } = jwtDecode(token);
  return { id, role, email };
};

interface AuthProps extends RouteComponentProps {
  derp: any;
  userAuthenticated?: () => void;
  children: React.ReactNode;
}

const AuthProvider = observer(
  withRouter(
    ({ location, history, derp, userAuthenticated, children }: AuthProps) => {
      const [token, setToken] = useState(localStorage.getItem('id_token'));

      const getAndAuthUser = useCallback(
        tkn => {
          if (!tkn) return null;

          setAuthToken(tkn);
          userAuthenticated();
          return derp.employees.getOrCreate(decodeUser(tkn));
        },
        [token]
      );

      const [user, setUser] = useState(() => getAndAuthUser(token));
      const [settings, setSettings] = useState(
        JSON.parse(localStorage.getItem('settings') || '{}')
      );
      const [inProgress, setInProgress] = useState(!!token);
      const [target, setTarget] = useState(location);

      useEffect(() => {
        axios.interceptors.response.use(
          // No processing for successful responses
          undefined,
          error => {
            if (error.response.status === 401) {
              setToken(null);
            }
            return Promise.reject(error);
          }
        );
      }, []);

      useEffect(() => {
        localStorage.setItem('settings', JSON.stringify(settings));
      }, [settings]);

      useDidMountEffect(() => {
        if (token) {
          localStorage.setItem('id_token', token);
          setUser(getAndAuthUser(token));
        } else {
          localStorage.removeItem('id_token');
          localStorage.removeItem('settings');
          setUser(null);
        }
      }, [token]);

      useEffect(() => {
        if (user) {
          history.push(target);
        }
      }, [user]);

      useEffect(() => {
        const { hash } = location;
        if (!!hash && /#access_token=(.*)&/.test(hash)) {
          setInProgress(true);
          if (process.env.AUTH_USE_MOCK_DATA) {
            const urlParams = new URLSearchParams(hash);
            const result: any = {
              idToken: urlParams.get('id_token')
            };
            axios
              .get('/api/v1/login', {
                headers: { Authorization: `Bearer ${result.idToken}` }
              })
              .then(({ data }) => {
                setTarget(result.state);
                setToken(data.access_token);
                setSettings(data.settings);
              });
          } else {
            AUTH0.parseHash((_, result) => {
              if (result && result.accessToken && result.idToken) {
                axios
                  .get('/api/v1/login', {
                    headers: { Authorization: `Bearer ${result.idToken}` }
                  })
                  .then(({ data }) => {
                    setTarget(result.state);
                    setToken(data.access_token);
                    setSettings(data.settings);
                  });
              }
            });
          }
        }
        return () => setInProgress(false);
      }, [location]);

      const login = (state, connection) => {
        AUTH0.authorize({ state, connection });
      };

      const logout = () => {
        setToken(null);
      };

      // If we have the token render a null element as once the user authenticated user
      // is resolve we get shown the correct page, otherwise show the Login screen
      //
      // Instead of the null we could show a breif 'transition' screen but the duration is
      // realative short -> null will suffice fr now
      return (
        <AuthContext.Provider
          value={{
            inProgress,
            isAuth: !!token,
            user,
            login,
            logout,
            settings,
            derp
          }}
        >
          {user ? children : <Login />}
        </AuthContext.Provider>
      );
    }
  )
);

AuthProvider.defaultProps = {
  userAuthenticated: () => {}
};

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };

export default AuthProvider;
