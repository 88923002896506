import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { SimpleInput } from 'components/atoms';

import { TitleContainer, Placeholder } from '../cv-style';

const CvTitle = observer(({ value, onChange, editable, placeholder }) => {
  const [focused, setFocused] = useState(false);

  const updateTitle = title => {
    onChange(title);
    setFocused(false);
  };

  return (
    <TitleContainer>
      <SimpleInput
        disabled={!editable}
        onCommit={updateTitle}
        defaultValue={value}
        placeholder={placeholder || 'Add title'}
        onFocus={() => setFocused(true)}
      />
      <Placeholder $hidden={focused}>{value}</Placeholder>
    </TitleContainer>
  );
});

CvTitle.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

CvTitle.defaultProps = {
  value: '',
  onChange: () => {}
};

export default CvTitle;
