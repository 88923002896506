import React, { createContext, useReducer } from 'react';

export const SyncContext = createContext();

const initialState = { type: null, message: '' };

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET':
      return {
        type: null,
        message: action.message
      };
    case 'PENDING':
      return {
        type: 'PENDING',
        message: action.message || 'Saving…'
      };
    case 'READY':
      return {
        type: 'SUCCESS',
        message: action.message || 'Saved'
      };
    case 'ERROR':
      return {
        type: 'ERROR',
        message: action.message
      };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

export const SyncContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    sync: {
      status: state,

      set: message => {
        dispatch({ type: 'SET', message });
      },
      pending: message => {
        dispatch({ type: 'PENDING', message });
      },
      ready: message => {
        dispatch({ type: 'READY', message });
      },
      error: message => {
        dispatch({ type: 'ERROR', message });
      },
      reset: () => {
        dispatch({ type: 'RESET' });
      }
    }
  };
  return (
    <SyncContext.Provider value={value}>{props.children}</SyncContext.Provider>
  );
};
