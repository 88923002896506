import React, { Fragment } from 'react';
import localeData from './locale-data';

export const formatText = (language, id, formatter = t => t) => {
  return formatter(`${localeData[language].messages[id]}`);
};

const FormattedText = props => {
  const { language, id } = props;
  return <Fragment>{formatText(language, id)}</Fragment>;
};

export default FormattedText;
