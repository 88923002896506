import moment from 'moment';
import holidayStore from '../../stores/holidayStore';

export function validateHours(h) {
  // Only accept single or double hours and with dot or comma and 0 or 5 after
  // like 4 | 10 | 5.5 | 8.0
  return h.match(/(^$)|^([0-9]{1,2})?[.,]{0,1}([0,5]{1})?$/);
}

export function normalizeHours(h) {
  if (typeof h === 'number') return h;
  if (!h) return NaN;
  const n = h.replace(/,/g, '.');
  return parseFloat(n);
}

/**
 *
 * @param {*} d current date
 * @param {boolean} fullMonth return month end date
 */
export function calculateDateRangeQuery(d, fullMonth = false) {
  const date = moment(d);
  const start = date.startOf('month').format('YYYY-MM-DD');

  const notCurrentMonth =
    date.endOf('month').isBefore(moment()) ||
    date.endOf('month').isAfter(moment().endOf('month'));

  let end;
  if (fullMonth || notCurrentMonth) {
    end = date.endOf('month').format('YYYY-MM-DD');
  } else {
    // Always query the running months end as the current date
    end = moment().format('YYYY-MM-DD');
  }
  return {
    start,
    end
  };
}

export function dateValues(d) {
  const day = d.date();
  const month = d.month() + 1;
  const year = d.year();
  return { day, month, year };
}

export function isHoliday(d) {
  return holidayStore.isHoliday(d) !== undefined;
}

export function isWorkingDay(d) {
  return d !== undefined && d.day() > 0 && d.day() < 6;
}

export function calculateWorkingDays(startDate, endDate) {
  const dateArray = [];
  let currentDate = startDate;
  const diff = endDate.diff(startDate, 'days');
  while (currentDate <= endDate) {
    const singleDay = diff === 0;
    const multipleWorkingDays =
      diff > 0 && isWorkingDay(currentDate) && !isHoliday(currentDate);
    if (singleDay || multipleWorkingDays) {
      dateArray.push(moment(currentDate));
    }
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}

export function calculateStartAndEndOfMonth(d) {
  const date = moment(d);
  const start = date.startOf('month').format('YYYY-MM-DD');
  const end = date.endOf('month').format('YYYY-MM-DD');
  return { start, end };
}

export function isSelectingFirstDay(from, to, day, multi) {
  const firstDayIsNotSelected = !from;
  const rangeIsSelected = from && to;
  const weekendSelected = !isWorkingDay(day);
  const holidaySelected = isHoliday(day) || isHoliday(from) || isHoliday(to);
  const selectionStartsWithWeekend = from && !isWorkingDay(from);
  const startIsEnd = rangeIsSelected && moment(to).isSame(day);

  if (weekendSelected || holidaySelected) {
    return true;
  }
  return (
    !multi ||
    firstDayIsNotSelected ||
    selectionStartsWithWeekend ||
    weekendSelected ||
    startIsEnd ||
    holidaySelected
  );
}

export function formatDateRange({ from, to }) {
  const f = {
    start: from?.format('YYYY-MM-DD'),
    end: to?.format('YYYY-MM-DD')
  };

  return { ...f, range: `${f.start}_${f.end}` };
}

export default {
  validateHours,
  calculateDateRangeQuery,
  dateValues,
  isSelectingFirstDay,
  isWorkingDay,
  formatDateRange
};
