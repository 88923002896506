import React, { useState, useContext } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import DayPicker from 'react-day-picker/DayPicker';
import 'react-day-picker/lib/style.css';

import { Icon, Popup } from 'components/atoms';
import { ViewportContext, DateContext } from 'contexts';

import {
  CalendarContainer,
  CalendarNavbar,
  DatePickerContainer,
  DateRangeContainer
} from './date-range-picker-style';

function renderDay(d) {
  return <div className='date-picker__content'>{d.getDate()}</div>;
}

const DateRangePicker = observer(props => {
  const {
    onChange,
    initialRange,
    disabled,
    placeholder,
    onOpen,
    position,
    format = 'D.M.',
    ...rest
  } = props;

  const { breakpoint } = useContext(ViewportContext);
  const { month } = useContext(DateContext);

  const defaultRange = {
    from: moment(month).startOf('month'),
    to: moment(month).endOf('month')
  };

  const [range, setRange] = useState(initialRange || defaultRange);
  const [clickIndex, setClickIndex] = useState(0);

  const handleDayChange = value => {
    const prop = ['from', 'to'][clickIndex];

    if (clickIndex === 1) {
      if (moment(value).isBefore(range.from)) {
        return setRange(prev => ({
          ...prev,
          from: moment(value)
        }));
      }
    }

    if (clickIndex === 0) {
      if (moment(value).isAfter(range.to)) {
        return setRange(prev => ({
          ...prev,
          to: moment(value)
        }));
      }
    }

    setRange(prev => ({
      ...prev,
      [prop]: moment(value)
    }));

    return setClickIndex(prev => (prev + 1) % 2);
  };

  const Navbar = ({ onPreviousClick, onNextClick }) => {
    return (
      <CalendarNavbar>
        <div onClick={() => onPreviousClick()}>
          <Icon name='chevronLeft' />
        </div>
        <div onClick={() => onNextClick()}>
          <Icon name='chevronRight' />
        </div>
      </CalendarNavbar>
    );
  };

  const formatDateRange = r =>
    `${moment(r.from).format(format)} - ${moment(r.to).format(format)}`;

  const handleClose = () => {
    onChange(range);
  };

  const displayValue = {
    from: range.from.toDate(),
    to: range.to.toDate()
  };

  return (
    <DatePickerContainer {...rest} format={format}>
      <Popup
        closeOnDocumentClick
        {...(breakpoint.sm
          ? { position: position || 'bottom left' }
          : { modal: true })}
        trigger={() => (
          <DateRangeContainer>{formatDateRange(range)}</DateRangeContainer>
        )}
        disabled={disabled}
        onClose={handleClose}
      >
        <CalendarContainer>
          <DayPicker
            mode='range'
            onDayClick={day => handleDayChange(day)}
            selectedDays={displayValue}
            month={displayValue.to || moment().toDate()}
            renderDay={renderDay}
            firstDayOfWeek={1}
            numberOfMonths={2}
            showWeekNumbers
            weekdaysShort={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
            navbarElement={<Navbar />}
            modifiers={displayValue}
          />
        </CalendarContainer>
      </Popup>
    </DatePickerContainer>
  );
});

export default DateRangePicker;
