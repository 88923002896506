import {
  types,
  flow,
  getParent,
  applySnapshot,
  getSnapshot,
  destroy
} from 'mobx-state-tree';
import moment from 'moment';
import { MomentType, LazyEmployee } from './types';
import axios from '../common/utils/axios';

export const EmployeeLogEntry = types
  .model('EmployeeLog', {
    id: types.identifierNumber,
    author: types.maybeNull(LazyEmployee),
    employee: types.maybeNull(LazyEmployee),
    type: types.enumeration(['hrm', 'sales', 'mentoring']),
    title: types.maybeNull(types.string),
    day: types.maybeNull(MomentType),
    entry: types.maybeNull(types.string),
    updated_at: types.maybeNull(MomentType),
    status: types.maybeNull(types.integer),
    draft: types.maybeNull(types.boolean),
    serverId: types.maybeNull(types.number)
  })

  .views(self => ({
    get lastUpdate() {
      const difference = moment.duration(
        moment(self.updated_at).diff(moment())
      );
      return difference.humanize(true);
    }
  }))
  .actions(self => ({
    update(data) {
      const beforeUpdate = getSnapshot(self);

      Object.keys(data).forEach(key => {
        // eslint-disable-next-line no-prototype-builtins
        if (self.hasOwnProperty(key)) {
          self[key] = data[key]; // eslint-disable-line no-param-reassign
        }
      });

      const afterUpdate = getSnapshot(self);

      if (beforeUpdate !== afterUpdate) {
        if (!self.draft) {
          self.saveChanges();
        } else if (self.draft && self.title && self.entry) {
          self.publishDraft();
        }
      }
    },
    publishDraft: flow(function* publishDraft() {
      const employee = getParent(self, 3);
      const { type } = getParent(self, 2);

      try {
        const { data, status } = yield axios.post(
          `/api/v1/employees/${employee.id}/logs`,
          JSON.stringify({
            employee_log: {
              day: self.day,
              title: self.title,
              entry: self.entry,
              log_type: type
            }
          })
        );
        if (status === 200) {
          applySnapshot(self, {
            ...data,
            draft: false,
            serverId: data.id,
            id: self.id
          });
        }
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    }),
    saveChanges: flow(function* saveChanges() {
      const employee = getParent(self, 2);
      try {
        const { data, status } = yield axios.put(
          `/api/v1/employees/${employee.id}/logs/${self.serverId || self.id}`,
          JSON.stringify(self)
        );

        if (status === 200) {
          self.updated_at = data.updated_at; // eslint-disable-line no-param-reassign
        }
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    }),
    deleteEntry: flow(function* deleteEntry() {
      if (self.draft) {
        self.remove();
      } else {
        const employee = getParent(self, 2);
        try {
          const { status } = yield axios.delete(
            `/api/v1/employees/${employee.id}/logs/${self.serverId || self.id}`
          );
          if (status === 200) {
            self.remove();
          }
        } catch (error) {
          console.error(error); // eslint-disable-line no-console
        }
      }
    }),
    remove() {
      getParent(self, 2).remove(self);
    }
  }));

export const EmployeeLogs = types
  .model({
    items: types.optional(types.array(EmployeeLogEntry), []),
    type: types.enumeration(['hrm', 'sales', 'mentoring'])
  })
  .actions(self => ({
    add(item) {
      self.items.push(item);
    },
    createDraft(data) {
      self.discardDraft();
      const newEntry = {
        ...data,
        draft: true,
        updated_at: moment(),
        day: moment(),
        type: self.type,
        id: Math.floor(Math.random() * -10000)
      };
      self.items.unshift(newEntry);
    },
    loadEmployeeLogs: flow(function* loadEmployeeLogs() {
      const employee = getParent(self, 1);
      try {
        const { data } = yield axios.get(
          `/api/v1/employees/${employee.id}/logs?type=${self.type}`
        );
        self.items = data; // eslint-disable-line no-param-reassign
      } catch (error) {
        console.error('Failed to load employee tags', error); // eslint-disable-line no-console
      }
    }),
    discardDraft() {
      const draft = self.items.find(item => item.draft);
      if (draft) {
        draft.remove();
      }
    },
    remove(item) {
      destroy(item);
    }
  }))
  .views(self => ({
    get currentDraft() {
      return self.items.find(item => item.draft);
    }
  }));
