import { types, flow, resolveIdentifier } from 'mobx-state-tree';

import axios from '../common/utils/axios';
import { MomentType } from './types';

const ProjectEstimate = types.model({
  id: types.identifierNumber,
  name: types.string,
  start: MomentType,
  end: MomentType,
  color: types.string,
  total_value: types.number,
  total_working_days: types.number,
  total_billing_amount: types.number,
  total_reported_hours: types.number,
  current_month_billing: types.number,
  value_remaining: types.number,
  value_correction: types.number,
  value_next_six_months: types.number,
  value_six_to_twelve_months: types.number,
  days_remaining: types.integer,
  days_next_six_months: types.integer,
  days_six_to_twelve_months: types.integer,
  organization_name: types.maybeNull(types.string),
  monthly_estimates: types.optional(types.string, '{}')
});

const EstimateCorrection = types.model({
  id: types.identifierNumber,
  created_at: MomentType,
  value: types.number,
  description: types.maybeNull(types.string)
});

const dateParams = (date, key = 'start') => ({
  params: { [key]: date.format('YYYY-MM-DD') }
});

export const ProjectEstimates = types
  .model('ProjectEstimates', {
    estimates: types.map(ProjectEstimate),
    corrections: types.map(EstimateCorrection)
  })
  .views(self => ({
    withTotals() {
      const projects = [...self.estimates.values()];
      const totals = projects.reduce((r, p) => {
        [
          'value_next_six_months',
          'value_six_to_twelve_months',
          'value_remaining',
          'current_month_billing',
          'total_billing_amount'
        ].forEach(k => {
          r[k] = (r[k] || 0) + p[k]; // eslint-disable-line no-param-reassign
        });
        return r;
      }, {});
      return { totals, projects };
    },
    groupedWithTotals() {
      const { totals, projects } = self.withTotals();
      const grouped = projects.reduce((r, p) => {
        r[p.organization_name] = [...(r[p.organization_name] || []), p]; // eslint-disable-line no-param-reassign
        return r;
      }, {});
      return { totals, projects: grouped };
    },
    forProject(id) {
      const project = resolveIdentifier(ProjectEstimate, self, id);
      return project || {};
    },
    getCorrections() {
      return [...self.corrections.values()];
    }
  }))
  // TODO: add try/catch error handling to yield functions
  .actions(self => ({
    load: flow(function* load(date) {
      const { data } = yield axios.get(
        '/api/v1/project_reports/',
        dateParams(date)
      );
      data.map(p => self.estimates.put(p));

      return self;
    }),
    loadForProject: flow(function* loadForProject(id, date) {
      const { data } = yield axios.get(
        `/api/v1/project_reports/${id}/`,
        dateParams(date)
      );
      self.estimates.put(data);
    }),
    addCorrection: (data, id, date) => {
      return axios
        .post(
          `/api/v1/projects/${id}/estimate_corrections`,
          JSON.stringify(data)
        )
        .then(() => {
          self.loadForProject(id, date);
          self.loadCorrections(id, date);
        });
    },
    loadCorrections: flow(function* loadCorrections(id, before) {
      try {
        const { data } = yield axios.get(
          `/api/v1/projects/${id}/estimate_corrections/`,
          dateParams(before, 'before')
        );
        self.clearCorrections(); // Halp?
        data.map(ec => self.corrections.put(ec));
      } catch (err) {
        console.log('Error loading estimate corrections:', err); // eslint-disable-line no-console
      }
    }),
    clearCorrections: () => {
      self.corrections.clear();
    }
  }));

export default ProjectEstimates;
