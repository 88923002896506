import React, { useContext } from 'react';

import { UiContext } from 'contexts';

import Button from 'components/atoms/button/button';

import ToggleButton from './panel-toggle-style';

const defaultLabel = { true: 'Show info panel', false: 'Hide info panel' };

const PanelToggle = ({ label = defaultLabel }) => {
  const { collapseLeftPanel, setCollapseLeftPanel } = useContext(UiContext);

  const toggle = () => {
    setCollapseLeftPanel(!collapseLeftPanel);
  };

  const icon = collapseLeftPanel ? 'caretSort' : 'shrink';

  return (
    <ToggleButton>
      <Button icon={icon} small transparent onClick={toggle}>
        {label[collapseLeftPanel]}
      </Button>
    </ToggleButton>
  );
};

export default PanelToggle;
