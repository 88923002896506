const IBAN = require('iban');

export const validateDateRange = (start, end) => {
  if (start.isAfter(end) || !start.isValid() || !end.isValid()) {
    return 'Start has to be before end';
  }
  return undefined;
};

export const validateDateInRange = (value, start, end, rangeName) => {
  if (value.isBefore(start) || value.isAfter(end, 'day')) {
    return `Outside the ${rangeName}`;
  }
  return undefined;
};

export const validateDate = day => {
  if (!day.isValid()) {
    return 'error';
  }
  return undefined;
};

export const validateIban = iban => {
  if (!IBAN.isValid(iban) && iban !== '') {
    return 'error';
  }
  return undefined;
};

export const isUndefined = value => {
  if (value !== undefined) {
    return true;
  }
  return false;
};

export default {
  validateDateRange,
  validateDateInRange,
  validateDate,
  validateIban,
  isUndefined
};
