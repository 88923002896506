import styled from 'styled-components/macro';
import { BasicButton } from 'components/atoms/button/button-style';
import { IconContainer } from 'components/atoms/icon/icon';

const baseColor = 'var(--color-text-darker)';

const handleTextColor = ({ active, deleted }) => {
  switch (true) {
    case deleted:
      return 'var(--color-white)';
    case active:
      return 'var(--color-primary-dark)';
    default:
      return baseColor;
  }
};

const handleBgColor = ({ active, deleted }) => {
  switch (true) {
    case deleted:
      return 'var(--color-error)';
    case active:
      return 'var(--color-primary-lightest)';
    default:
      return 'var(--color-background)';
  }
};

const handleBorderColor = ({ active, deleted }) => {
  switch (true) {
    case deleted:
      return 'var(--color-error-dark)';
    case active:
      return 'var(--color-primary-lighter)';
    default:
      return 'var(--color-text-light)';
  }
};

const handleIconColor = ({ deleted, deletable }) => {
  switch (true) {
    case deleted:
      return 'var(--color-white)';
    case deletable:
      return 'var(--color-error-dark)';
    default:
      return 'var(--color-primary-dark)';
  }
};

export const LabelContainer = styled.span`
  padding-left: 0.15em;
  padding-right: 0.15em;
  vertical-align: middle;
  font-weight: 500;
`;

export const SavingStatus = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const TagButton = styled(BasicButton)`
  position: relative;
  background-color: ${handleBgColor};
  border: 1px solid ${handleBorderColor};
  color: ${handleTextColor};

  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding: ${({ small }) => small && '0.15rem 0.3rem'};
  font-size: ${({ small }) => small && 'var(--font-size-xs)'};
  margin: 0.25rem;

  pointer-events: ${({ editable, deletable }) =>
    !editable && !deletable && 'none'};

  &:hover {
    background-color: ${({ deletable }) =>
      deletable
        ? 'var(--color-error-lightest)'
        : 'var(--color-primary-lightest)'};
    border-color: ${({ deletable }) =>
      deletable ? 'var(--color-error-light)' : 'var(--color-primary-lightest)'};
  }

  &:hover,
  &:hover > ${IconContainer} {
    color: ${({ deletable }) => deletable && 'var(--color-error-dark)'};
  }

  ${IconContainer} {
    color: ${handleIconColor};
  }

  > ${IconContainer}, > ${LabelContainer} {
    visibility: ${({ saving }) => saving && 'hidden'};
  }
`;

export const TagCount = styled.span`
  display: inline-block;
  vertical-align: middle;

  background-color: var(--color-primary-lightest);
  border-radius: var(--border-radius-xl);

  padding: 0 0.5rem;
  margin-left: 0.25rem;
  font-weight: 700;
  color: var(--color-primary);
`;
