import { types, destroy, detach } from 'mobx-state-tree';
import { isArray } from 'util';
import Trait from './trait-model';

const Traits = types
  .model('Traits', {
    traits: types.array(Trait)
  })
  .preProcessSnapshot(snapshot => {
    if (isArray(snapshot)) {
      return { traits: snapshot };
    }
    return snapshot;
  })
  .postProcessSnapshot(snapshot => {
    return snapshot.traits;
  })
  .actions(self => ({
    addTrait(data) {
      self.traits.push(data);
    },
    removeTrait(trait) {
      destroy(trait);
    },
    reorder(startIndex, endIndex) {
      self.traits.splice(endIndex, 0, detach(self.traits[startIndex]));
    }
  }));

export default Traits;
