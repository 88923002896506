import {
  types,
  flow,
  resolveIdentifier,
  destroy,
  getSnapshot,
  getRoot
} from 'mobx-state-tree';
import { withValidations } from 'mst-validatejs';
import moment from 'moment';

import axios from '../common/utils/axios';
import { MomentType, LazyEmployee } from './types';
import { calculateDateRangeQuery, formatDateRange } from '../common/utils/time';
import { Assignment } from './assignment-model';
import { BillingSummary } from './billing-summary-model';
import { Tag } from './tag-list-model';

const ProjectCustomer = types.model('ProjectCustomer', {
  id: types.identifierNumber,
  name: types.optional(types.string, ''),
  color: types.optional(types.string, '#40b1bf'),
  contact: types.optional(types.string, ''),
  managers: types.array(LazyEmployee)
});

export const Project = types
  .model('Project', {
    id: types.identifierNumber,
    name: types.optional(types.string, ''),
    contact: types.optional(types.string, ''),
    color: types.optional(types.string, '#40b1bf'),
    start: types.optional(MomentType, moment().startOf('day')),
    end: types.optional(MomentType, moment().add(6, 'months')),
    description: types.optional(types.string, ''),
    category: types.optional(types.string, ''),
    common: types.optional(types.boolean, false),
    tags: types.map(Tag),
    deletable: types.optional(types.boolean, false),
    estimated_value: types.maybeNull(types.number),
    customer: types.maybeNull(ProjectCustomer),
    billable_customer: types.maybeNull(ProjectCustomer),
    managers: types.array(LazyEmployee)
  })
  .postProcessSnapshot(snapshot => {
    const {
      id,
      common,
      assignments,
      deletable,
      customer,
      billable_customer,
      managers,
      ...apiAcceptedData
    } = snapshot;
    return {
      ...apiAcceptedData,
      customer_id: customer?.id || null,
      billable_customer_id: billable_customer?.id || null
    };
  })
  .extend(
    withValidations({
      name: {
        length: { minimum: 1, message: 'name is required' }
      },
      category: {
        length: { minimum: 1, message: 'category is required' }
      },
      start: {
        validDateRange: true
      },
      end: {
        validDateRange: true
      }
    })
  )
  // TODO: add try/catch error handling to yield functions
  .actions(self => ({
    load: flow(function* load(id) {
      const { data } = yield axios.get(`/api/v1/projects/${id}`);
      self.id = data.id; // eslint-disable-line no-param-reassign
      self.name = data.name; // eslint-disable-line no-param-reassign
      self.contact = data.contact; // eslint-disable-line no-param-reassign
      self.color = data.color; // eslint-disable-line no-param-reassign
      self.start = data.start; // eslint-disable-line no-param-reassign
      self.end = data.end; // eslint-disable-line no-param-reassign
      self.description = data.description; // eslint-disable-line no-param-reassign
      self.category = data.category; // eslint-disable-line no-param-reassign
      self.common = data.common; // eslint-disable-line no-param-reassign
      self.deletable = data.deletable; // eslint-disable-line no-param-reassign
      self.estimated_value = data.estimated_value; // eslint-disable-line no-param-reassign
      self.customer = data.customer; // eslint-disable-line no-param-reassign
      self.billable_customer = data.billable_customer; // eslint-disable-line no-param-reassign
      self.managers = data.managers; // eslint-disable-line no-param-reassign
    }),
    setName(name) {
      self.name = name; // eslint-disable-line no-param-reassign
    },
    setColor(color) {
      self.color = color; // eslint-disable-line no-param-reassign
    },
    setContact(contact) {
      self.contact = contact; // eslint-disable-line no-param-reassign
    },
    setCategory(category) {
      self.category = category; // eslint-disable-line no-param-reassign
    },
    setEndDate(date) {
      self.end = date; // eslint-disable-line no-param-reassign
    },
    setStartDate(date) {
      self.start = date; // eslint-disable-line no-param-reassign
    },
    setDescription(description) {
      self.description = description; // eslint-disable-line no-param-reassign
    },
    setEstimatedValue(value) {
      self.estimated_value = value; // eslint-disable-line no-param-reassign
    },
    setCustomer(name) {
      self.customer = name; // eslint-disable-line no-param-reassign
    },
    setBillableCustomer(name) {
      self.billable_customer = name; // eslint-disable-line no-param-reassign
    },
    save: flow(function* save() {
      const project = getSnapshot(self);
      if (self.id === -1) {
        const { data } = yield axios.post(
          '/api/v1/projects',
          JSON.stringify({ project })
        );
        self.id = data.id; // eslint-disable-line no-param-reassign
      } else {
        yield axios.put(`/api/v1/projects/${self.id}`, JSON.stringify(project));
      }
      return self;
    }),
    loadTags: flow(function* loadTags() {
      try {
        const params = { model_type: 'project', model_id: self.id };
        const { data } = yield axios.get('/api/v1/tags', { params });
        data.map(tag => self.tags.put(tag));
      } catch (error) {
        console.error('Failed to load project tags', error); // eslint-disable-line no-console
      }
    }),
    toggleTag: flow(function* toggleTag(tagId) {
      try {
        const params = { model_type: 'project', model_id: self.id };
        const { data } = yield axios.put(
          `/api/v1/tags/${tagId}/toggle`,
          params
        );
        if (self.hasTag(tagId)) {
          destroy(self.tags.get(tagId));
        } else {
          self.tags.put(data);
        }
      } catch (error) {
        console.error('Could not toggle tag', error); // eslint-disable-line no-console
      }
      return self;
    }),
    loadAssignments() {
      const { assignments } = getRoot(self);
      return assignments.assignmentsFor([self.id]);
    },
    createNewAssignment(e, data) {
      const { assignments } = getRoot(self);
      return assignments.create(self, e, data);
    },
    initNewAssignment() {
      const { assignments } = getRoot(self);
      return assignments.init(self);
    },
    deleteAssignment(assignment) {
      const { assignments } = getRoot(self);
      assignments.delete(assignment);
    },
    update: flow(function* update(project) {
      self.name = project.name; // eslint-disable-line no-param-reassign
      self.color = project.color; // eslint-disable-line no-param-reassign
      self.contact = project.contact; // eslint-disable-line no-param-reassign
      self.start = project.start; // eslint-disable-line no-param-reassign
      self.end = project.end; // eslint-disable-line no-param-reassign
      self.description = project.description; // eslint-disable-line no-param-reassign
      const { id, state, common, deletable, assignments, ...others } = self;
      yield axios.put(`/api/v1/projects/${project.id}`, JSON.stringify(others));
    }),
    loadSummary: flow(function* loadSummary(dateRange) {
      // TODO: Refactor this statement out from here
      yield self.loadAssignments();
      const { start, end, range } = formatDateRange(dateRange);
      if (start && end) {
        const { data } = yield axios.get(
          `/api/v1/projects/${self.id}/assignments/summary.json?start=${start}&end=${end}`
        );
        // eslint-disable-next-line array-callback-return
        data.map(a => {
          const assignment = resolveIdentifier(Assignment, getRoot(self), a.id);
          assignment.addBillingSummary(
            BillingSummary.create({
              id: range,
              hours: a.reported_hours,
              billing: a.total_billable_amount
            })
          );
        });
      }
    }),
    downloadSummary: flow(function* downloadSummary(dateRange) {
      const { from: start, to: end } = dateRange;
      const url = `/api/v1/projects/${self.id}/assignments/summary.csv?start=${
        start !== null ? start.format('YYYY-MM-DD') : ''
      }&end=${end !== null ? end.format('YYYY-MM-DD') : ''}`;
      const { data } = yield axios.get(url, {
        headers: { Accept: 'text/csv' },
        responseType: 'blob'
      });
      return data;
    }),
    addOrRemoveManager: flow(function* addOrRemoveManager(id, adding) {
      try {
        const action = adding ? 'post' : 'delete';
        const { data } = yield axios[action](
          `/api/v1/projects/${self.id}/manager/${id}`
        );
        self.managers = data; // eslint-disable-line no-param-reassign
      } catch (err) {
        console.log('Error updating project managers'); // eslint-disable-line no-console
      }
    }),
    updateCustomer: flow(function* updateCustomer(customer_id) {
      try {
        const { data } = yield axios.put(
          `/api/v1/projects/${self.id}`,
          JSON.stringify({ customer_id })
        );
        self.setCustomer(data.customer);
      } catch (err) {
        console.log('Error updating project customer id', err); // eslint-disable-line no-console
      }
    }),
    updateBillableCustomer: flow(function* updateBillableCustomer(
      billable_customer_id
    ) {
      try {
        const { data } = yield axios.put(
          `/api/v1/projects/${self.id}`,
          JSON.stringify({ billable_customer_id })
        );
        self.setBillableCustomer(data.billable_customer);
      } catch (err) {
        console.log('Error updating project billable customer id', err); // eslint-disable-line no-console
      }
    }),
    changeTab(tab) {
      self.tab = tab; // eslint-disable-line no-param-reassign
    }
  }))
  .views(self => ({
    isReady() {
      return self.state === 'ready';
    },
    hasTag(tagId) {
      return self.tags.has(tagId);
    },
    allTags() {
      return [...self.tags.values()];
    },
    getAssignments(unsaved) {
      const { assignments } = getRoot(self);
      return assignments.forProject(self, unsaved);
    },
    activeAssignments({ month, range }) {
      // Assume one is present
      const { start, end } = range
        ? { start: range.from, end: range.to }
        : calculateDateRangeQuery(month, true);

      return self.getAssignments().filter(assignment => {
        return moment
          .range(start, end)
          .overlaps(moment.range(assignment.start, assignment.end), {
            adjacent: true
          });
      });
    },
    isAssigned(employee) {
      const assigned = self
        .getAssignments()
        .filter(a => a.employee.id === employee.id);
      return assigned.length > 0;
    },
    summaryTotal(dateRange) {
      let hours = 0;
      let billing = 0;
      // eslint-disable-next-line array-callback-return
      self.getAssignments().map(a => {
        const summary = a.summary(dateRange);
        if (summary) {
          hours += parseFloat(summary.hours);
          billing += parseFloat(summary.billing);
        }
      });

      return BillingSummary.create({
        id: formatDateRange(dateRange).range,
        hours: hours.toString(),
        billing: billing.toString()
      });
    }
  }))
  .volatile(() => ({
    tab: 'members'
  }));

export default Project;
