import { types, destroy, detach } from 'mobx-state-tree';
import { isArray } from 'util';

import Employer from './employer-model';

const WorkHistory = types
  .model('WorkHistory', {
    employers: types.array(Employer)
  })
  .preProcessSnapshot(snapshot => {
    if (isArray(snapshot)) {
      return { employers: snapshot };
    }
    return snapshot;
  })
  .postProcessSnapshot(snapshot => {
    return snapshot.employers;
  })
  .actions(self => ({
    addCurrentCompany(current = 'Loihde Oy') {
      self.employers.push(
        Employer.create({
          employer: current
        })
      );
    },
    addEmployer(employer) {
      self.employers.push(employer);
    },
    removeEmployer(employer) {
      destroy(employer);
    },
    reorderEmployers(startIndex, endIndex) {
      self.employers.splice(endIndex, 0, detach(self.employers[startIndex]));
    }
  }));

export default WorkHistory;
