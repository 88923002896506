import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useReducer
} from 'react';
import { observer } from 'mobx-react';

import { DebounceInput } from 'react-debounce-input';

import { ViewportContext, AuthContext } from 'contexts';
import { canAccess } from 'common/rbac';

import { Icon, Button, Checkbox } from 'components/atoms';
import { DatePicker } from 'components/molecules';
import { StyledPopup } from 'components/molecules/month-selector/month-selector-style';

import {
  FiltersContainer,
  FiltersMenu,
  FilterTitle,
  AddFiltersButton,
  FilterButtonText,
  ActiveFilter,
  ActiveFilterRemove,
  FilterInputs,
  FilterInputContainer,
  FilterHint,
  OptionContainer,
  FilterCount,
  ActiveRowCount
} from './filter-style';

import { filterFunction, filterReducer } from './filter-helpers';

const FilterInput = ({ minOrMax }) => {
  const ref = useRef(null);
  const [activeInput, setActiveInput] = useState(null);

  useEffect(() => {
    if (activeInput === minOrMax) {
      ref.current.focus();
    }
  }, [activeInput]);

  // TODO: Temp
  const [allocationRange, setAllocationRange] = useState({
    min: 0,
    max: 100
  });

  return (
    <FilterInputContainer>
      <DebounceInput
        id={`set-range-${minOrMax}`}
        key={`set-range-${minOrMax}`}
        type='number'
        value={allocationRange[minOrMax]}
        min={0}
        max={100}
        inputRef={ref}
        onClick={() => setActiveInput(minOrMax)}
        onBlur={() => {
          setActiveInput(null);
        }}
        onChange={e => {
          e.preventDefault();
          setAllocationRange({
            ...allocationRange,
            [minOrMax]: e.target.value
          });
        }}
        debounceTimeout={200}
      />
    </FilterInputContainer>
  );
};

const maxItems = breakpoint => {
  switch (true) {
    case breakpoint.xxl:
      return 6;
    case breakpoint.xl:
      return 4;
    case breakpoint.lg:
      return 3;
    default:
      return 0;
  }
};

const Filters = observer(props => {
  const { filters: filterData, onChange, onActive, initial = [], data } = props;
  const [state, dispatch] = useReducer(filterReducer, {
    filters: filterData,
    activeFilters: initial
  });
  const [allowClose, setAllowClose] = useState(true);
  const { breakpoint } = useContext(ViewportContext);
  const {
    user: { role }
  } = useContext(AuthContext);

  const popupRef = useRef();
  const { filters, activeFilters } = state;

  const hasFilter = option =>
    activeFilters.some(
      o =>
        o.title === option.title ||
        (o.menuTitle && o.menuTitle === option.menuTitle)
    );

  const setFilters = payload => dispatch({ type: 'active.reset', payload });
  const updateFilterCounts = () =>
    dispatch({ type: 'filters.count', payload: { activeFilters, data } });

  useEffect(() => {
    updateFilterCounts();
    onChange?.(filterFunction(activeFilters)); // eslint-disable-line no-unused-expressions
    onActive?.(activeFilters); // eslint-disable-line no-unused-expressions
  }, [activeFilters]);

  useEffect(() => {
    if (initial.length) {
      setFilters(initial);
    }
  }, [initial]);

  useEffect(() => {
    updateFilterCounts();
  }, [data]);

  useEffect(() => {
    dispatch({ type: 'filters.reset', payload: filterData });
    updateFilterCounts();
  }, [filterData]);

  const itemsToShow = maxItems(breakpoint);

  const permitted = item => (item.rbac ? canAccess(role, item.rbac) : true);

  const addFilter = payload => dispatch({ type: 'active.add', payload });
  const removeFilter = payload => dispatch({ type: 'active.remove', payload });

  // TODO: Simplify value handing somehow?
  const handleDateChange = (option, category, field) => value => {
    const payload = { value, option, category, field };
    dispatch({ type: 'datepicker.set', payload }); // This is for display purposes

    if (!value) {
      removeFilter(option);
    } else if (hasFilter(option)) {
      dispatch({ type: 'active.update', payload: { ...option, value } });
    } else {
      addFilter({ option, category, field, value });
    }
  };

  const activeRows = data ? filterFunction(activeFilters)()(data).length : 0;

  return (
    <FiltersContainer>
      <StyledPopup
        closeOnEscape
        closeOnDocumentClick={allowClose}
        position='bottom left'
        trigger={
          <AddFiltersButton>
            <Icon name='filter' size='1rem' />
            <FilterButtonText>Filters</FilterButtonText>
          </AddFiltersButton>
        }
        arrow={false}
        contentStyle={false}
        ref={popupRef}
      >
        <FiltersMenu>
          {filters.map(({ category, options, field }) => (
            <div key={category}>
              <FilterTitle>{category}</FilterTitle>
              {options.filter(permitted).map(option => (
                <OptionContainer key={option.title}>
                  <div>
                    <Checkbox
                      size='1rem'
                      label={option.menuTitle || option.title}
                      checked={hasFilter(option)}
                      onChange={checked => {
                        if (checked === true) {
                          addFilter({ option, category, field });
                        } else {
                          removeFilter(option);
                        }
                      }}
                      disabled={option.datePicker && !option.value}
                    />
                    {option.rangeSelector && (
                      <FilterInputs>
                        <FilterInput minOrMax='min' key='possu' id='hassu' />
                        <span style={{ margin: '0 0.5rem' }}>-</span>
                        <FilterInput minOrMax='max' key='possu2' id='hassu2' />
                      </FilterInputs>
                    )}
                    {option.datePicker && (
                      <FilterInputs>
                        <DatePicker
                          allowEmptyValue
                          closeOnSelect
                          value={option.value}
                          placeholder='D.M.YYYY'
                          onOpen={() => setAllowClose(false)}
                          onClose={() => setAllowClose(true)}
                          onChange={handleDateChange(option, category)}
                          style={{ fontSize: '0.75rem' }}
                        />
                      </FilterInputs>
                    )}
                    {Boolean(option.hint) && (
                      <FilterHint>{option.hint}</FilterHint>
                    )}
                  </div>
                  <FilterCount>
                    <div>{option.count}</div>
                  </FilterCount>
                </OptionContainer>
              ))}
            </div>
          ))}
          <div
            style={{
              display: 'flex',
              fontSize: 'var(--font-size-sm)',
              marginTop: '1.5rem'
            }}
          >
            <Button
              transparent
              onClick={() => {
                setFilters([]);
                popupRef.current.close();
              }}
            >
              Clear all
            </Button>

            <Button
              style={{ marginLeft: '0.5rem' }}
              onClick={() => popupRef.current.close()}
            >
              Apply
            </Button>
          </div>
        </FiltersMenu>
      </StyledPopup>

      {data && <ActiveRowCount count={activeRows}>{activeRows}</ActiveRowCount>}

      {activeFilters.slice(0, itemsToShow).map(filter => (
        <ActiveFilter key={filter.title}>
          {filter.title}
          {filter.datePicker && `: ${filter.value.format('D.M.YYYY')}`}

          <ActiveFilterRemove>
            <Icon
              name='cross2'
              size='1rem'
              onClick={() => {
                removeFilter(filter);
              }}
            />
          </ActiveFilterRemove>
        </ActiveFilter>
      ))}
      {activeFilters.length > itemsToShow ? (
        <ActiveFilter onClick={() => popupRef.current.open()}>
          <ActiveFilterRemove noPadding>
            {itemsToShow > 0
              ? `+ ${activeFilters.length - itemsToShow}`
              : `${activeFilters.length} active`}
          </ActiveFilterRemove>
        </ActiveFilter>
      ) : null}
    </FiltersContainer>
  );
});

export default Filters;
