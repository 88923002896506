import React from 'react';
import Permission from './permission';
import { rbac } from './rules';

const EmployeeHoursReadPermission = props => (
  <Permission perform={rbac.EMPLOYEE_HOURS_READ} {...props}>
    {props.children}
  </Permission>
);

export default EmployeeHoursReadPermission;
