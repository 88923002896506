import React from 'react';
import PropTypes from 'prop-types';

import { Can } from './can';

import usePermission from './use-permission';
import './permission.scss';
import Denied from './denied';

const Permission = props => {
  usePermission(props.perform, props.granted, props.denied, props.data);
  if (props.unauthorized) {
    return <Denied />;
  }

  return (
    <Can
      perform={props.perform}
      yes={() => {
        return props.children;
      }}
      no={() => {
        return props.silent ? null : <Denied />;
      }}
      data={props.data}
    />
  );
};

Permission.defaultProps = {
  granted: () => {},
  denied: () => {},
  data: {},
  unauthorized: false
};

Permission.propTypes = {
  children: PropTypes.node.isRequired,
  granted: PropTypes.func,
  denied: PropTypes.func,
  data: PropTypes.object,
  unauthorized: PropTypes.bool
};
export default Permission;
