import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthContext, DateContext, DerpContext } from '../../contexts';
import { assignmentIds } from '../../models/assignment-list-model';

const useEmployee = () => {
  const { user } = useContext(AuthContext);
  const derp = useContext(DerpContext);

  const history = useHistory();
  const pathItems = history.location.pathname.split('/');
  const id = parseInt(pathItems[pathItems.indexOf('employees') + 1], 10); // id is placed after 'employees' in the path

  const [employee] = useState(id ? derp.employees.getOrCreate({ id }) : user);

  return employee;
};

export const useEmployeeWithUpdatedModels = () => {
  const derp = useContext(DerpContext);
  const dateCtx = useContext(DateContext);
  const [month, setMonth] = useState(dateCtx.month);
  const employee = useEmployee();

  useEffect(() => {
    setMonth(dateCtx.month);
  }, [dateCtx.month]);

  const { assignments, timeentries } = derp;
  useEffect(() => {
    employee.loadSummary(month);
    employee.loadSalaryData(month);
    employee.loadBalanceCorrections();
    employee.loadAllocations();
    assignments.load(employee, month).then(() => {
      const a = assignments.assignmentsInMonthForEmployee(month, employee);
      timeentries.timeEntriesFor(assignmentIds(a));
    });
  }, [employee, month]);
  return employee;
};

export default useEmployee;
