import styled from 'styled-components/macro';
import { breakpointsRefresh } from 'common/styles';

const breakpoints = breakpointsRefresh;

export const MonthRangeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -0.125rem;
  font-size: var(--font-size-xs);
  ${breakpoints.sm} {
    font-size: var(--font-size-sm);
  }
`;
export const DateContainer = styled.div`
  cursor: pointer;
  padding: 0.125rem;
  border-radius: var(--border-radius-xs);
  &:hover {
    background-color: var(--color-input-hover);
  }
`;

export const WarningMessage = styled.div`
  margin-top: 0.25rem;
  color: var(--color-error);
  font-size: var(--font-size-xs);
`;
