import { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

function createRootElement(id) {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
}

function addRootElement(rootElem) {
  document.body.insertBefore(
    rootElem,
    document.body.lastElementChild.nextElementSibling
  );
}

export const usePortal = id => {
  const rootElemRef = useRef(null);

  useEffect(
    function setupElement() {
      const existingParent = document.querySelector(`#${id}`);
      const parentElem = existingParent || createRootElement(id);
      if (!existingParent) {
        addRootElement(parentElem);
      }
      parentElem.appendChild(rootElemRef.current);
      return function removeElement() {
        rootElemRef.current.remove();
        if (!parentElem.childElementCount) {
          // Remove only elements created by usePortal function
          if (!existingParent) {
            parentElem.remove();
          }
        }
      };
    },
    [id]
  );

  function getRootElem() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  }

  return getRootElem();
};

export const Portal = ({ target, children }) => {
  return createPortal(children, usePortal(target));
};
