import styled from 'styled-components/macro';
import { breakpointsRefresh } from 'common/styles';

const breakpoints = breakpointsRefresh;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 2rem 8ch 2rem;
  cursor: pointer;
  font-size: var(--font-size-sm);
  margin-left: auto;
  height: 2rem;
  width: 100%;
  place-content: stretch end;
  ${breakpoints.md} {
    grid-template-columns: 2rem 15ch 2rem;
  }
`;
export const Arrow = styled.button`
  align-items: center;
  display: grid;
  place-content: center;
  border: 1px solid var(--color-text-lightest);
  background-color: transparent;
  cursor: pointer;
  color: var(--color-text-darker);
  &:hover {
    color: var(--color-text-darkest);
    background-color: var(--color-background-is-active);
  }
  &.prev {
    border-right: none;
    border-radius: var(--border-radius-md) 0 0 var(--border-radius-md);
  }
  &.next {
    border-left: none;
    border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
  }
  & + div {
    height: 100%;
  }
`;
export const CurrentMonth = styled.div`
  color: var(--color-text-darker);
  display: grid;
  place-content: center;
  height: 100%;

  &:hover {
    color: var(--color-text-darkest);
    background-color: var(--color-background-is-active);
  }
  span {
    font-size: var(--font-size-sm);
    margin-right: 0.25rem;
    color: var(--color-text-dark);
    letter-spacing: 0.01em;
  }
  border: 1px solid var(--color-text-lightest);

  ${breakpoints.md} {
    span {
      display: grid;
      place-content: center;
      font-size: var(--font-size-sm);
      margin-right: 0;
      color: var(--color-text-darker);
      letter-spacing: 0;
    }
    div {
      display: none;
    }
  }
`;
