import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import axios from '../../common/utils/axios';

import {
  isSelectingFirstDay,
  isWorkingDay,
  isHoliday,
  calculateStartAndEndOfMonth
} from './../../common/utils/time';

export const DateContext = React.createContext();

const resolveMonth = month => {
  return month && moment(month).isValid() ? moment(month) : moment();
};

export const DateContextProvider = props => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const monthParams = params.get('month');
  const [month, setMonth] = useState(resolveMonth(monthParams));
  const [selectedDays, updateSelectedDays] = useState({
    from: moment(),
    to: moment()
  });
  const [capacity, setCapacity] = useState(0);

  const updateCapacity = async m => {
    const { start, end } = calculateStartAndEndOfMonth(m);
    const { data } = await axios.get(
      `/api/v1/settings/capacity?start=${start}&end=${end}`
    );
    setCapacity(data.capacity);
  };

  useEffect(() => {
    updateCapacity(month);
  }, []);

  useEffect(() => {
    if (monthParams) {
      history.push(`${pathname}?month=${month.format('YYYY-MM-DD')}`);
    }
  }, [month]);

  const setSelectedDays = (selectedDay, multiSelect = false) => {
    const { from, to } = selectedDays;
    const day = moment(selectedDay);

    if (isSelectingFirstDay(from, to, day, multiSelect)) {
      updateSelectedDays({
        from: day.clone(),
        to: day.clone()
      });
    } else {
      // If the end day is on a weekend, cap it to the last friday
      if (!isWorkingDay(day)) {
        const t = (7 - day.day()) % 5;
        day.setDate(day.day() - t);
      }

      if (isHoliday(day)) day.substract(1, 'days').toDate();

      if (day.isBefore(from)) {
        updateSelectedDays({
          from: day.clone(),
          to: to.clone()
        });
      } else {
        updateSelectedDays({
          from: from.clone(),
          to: day.clone()
        });
      }
    }
  };

  const updateMonth = m => {
    setMonth(m);
    if (m.isSame(moment(), 'month')) {
      setSelectedDays(moment());
    } else {
      setSelectedDays(m);
    }
    updateCapacity(m);
  };

  return (
    <DateContext.Provider
      value={{
        month,
        selectedDays,
        updateMonth,
        setSelectedDays,
        capacity
      }}
    >
      {props.children}
    </DateContext.Provider>
  );
};

DateContext.propTypes = {
  children: PropTypes.node.isRequired
};
