/* eslint-disable class-methods-use-this */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import CvTitle from 'pages/cv/cv-employee-title/cv-employee-title';
import { EmployeeAvatar } from 'components/molecules';

import {
  Badge,
  Container,
  EmployeeDetails,
  AvatarContainer,
  NameContainer,
  Name,
  Label
} from './employee-template-style';

const EmployeeTemplate = observer(props => {
  const {
    employee,
    children,
    showControls,
    enableAvatarEdit,
    editable
  } = props;

  const lastCvUpdate = employee.cv ? employee.cv.lastUpdate() : '-';

  return (
    <Container>
      <AvatarContainer>
        <EmployeeAvatar
          enableEdit={enableAvatarEdit}
          size='100%'
          src={employee.avatar}
        />
      </AvatarContainer>
      <EmployeeDetails>
        <NameContainer>
          <Name>{employee.fullName()}</Name>
          <Badge>{employee.cost_center}</Badge>
        </NameContainer>
        <div>
          <Label>Title</Label>
          <CvTitle
            value={employee.cv.title}
            onChange={employee.cv.setTitle}
            editable={editable}
          />
          <Label>Location</Label>
          <CvTitle
            value={employee.location}
            onChange={employee.setLocation}
            editable={editable}
            placeholder='Add location'
          />
        </div>
      </EmployeeDetails>

      {showControls && (
        <div className='employee-template__last_update'>
          <p>CV Updated</p>
          <p>{lastCvUpdate}</p>
        </div>
      )}
      {children}
    </Container>
  );
});

export default EmployeeTemplate;

EmployeeTemplate.propTypes = {
  employee: PropTypes.object.isRequired,
  pathActionButton: PropTypes.node,
  children: PropTypes.node,
  gridWithAvatar: PropTypes.bool,
  avatarBadge: PropTypes.bool,
  showControls: PropTypes.bool,
  hideHeader: PropTypes.bool,
  enableAvatarEdit: PropTypes.bool
};

EmployeeTemplate.defaultProps = {
  pathActionButton: null,
  children: null,
  gridWithAvatar: false,
  avatarBadge: false,
  showControls: false,
  hideHeader: false,
  enableAvatarEdit: false
};
