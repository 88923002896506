import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { MonthSelector } from 'components/molecules';
import { Icon } from 'components/atoms';

import { ViewportContext } from 'contexts';

import {
  Container,
  Arrow,
  CurrentMonth
} from './navigatable-month-selector-style';

const NavigatableMonthSelector = props => {
  const { onChange, selectedMonth, useToday, yearMode, ...others } = props;

  const { breakpoint } = useContext(ViewportContext);

  const useStartOfMonth = d => props.useToday && d.startOf('month');

  const period = yearMode ? 'years' : 'months';

  const previous = () => {
    const newDate = selectedMonth.clone().subtract(1, period);
    useStartOfMonth(newDate);
    onChange(newDate);
  };

  const next = () => {
    const newDate = selectedMonth.clone().add(1, period);
    useStartOfMonth(newDate);
    onChange(newDate);
  };

  const month = selectedMonth.format(breakpoint.lg ? 'MMMM' : 'MM');
  const year = selectedMonth.format(breakpoint.lg || yearMode ? 'YYYY' : 'YY');
  const showToday = useToday && selectedMonth.isSame(moment(), 'day');

  const currentMonth = () => {
    switch (true) {
      case yearMode:
        return year;
      case showToday:
        return 'Today';
      default:
        return `${month}${breakpoint.lg ? ' ' : '/'}${year}`;
    }
  };

  return (
    <Container>
      <Arrow className='prev' onClick={previous}>
        <Icon name='chevronLeft' size='1rem' />
      </Arrow>
      <MonthSelector
        todayButton
        yearMode={yearMode}
        onChange={d => onChange(d)}
        date={selectedMonth}
        highlightToday={useToday}
        {...others}
      >
        <CurrentMonth>
          <span>{currentMonth()}</span>
        </CurrentMonth>
      </MonthSelector>

      <Arrow type='button' className='next' onClick={next}>
        <Icon name='chevronRight' size='1rem' />
      </Arrow>
    </Container>
  );
};

export default NavigatableMonthSelector;

NavigatableMonthSelector.propTypes = {
  selectedMonth: PropTypes.instanceOf(moment).isRequired,
  onChange: PropTypes.func.isRequired,
  useToday: PropTypes.bool
};

NavigatableMonthSelector.defaultProps = {
  useToday: false
};
