import styled, { keyframes } from 'styled-components/macro';
import Popup from 'reactjs-popup2';
import { breakpointsRefresh as breakpoints } from 'common/styles';

import { BasicButton } from '../button/button-style';

export const slideIn = keyframes`
 // Hack to help reactjs-popup positioning
  0.01% {
    transform:scale(0.8);
    opacity:0;
  }
  100% {
    transform:scale(1);
    opacity:1;
  }
`;

const handleOrigin = ({ position }) => {
  const [a, b] = (position || 'bottom right').split(' '); // Insurance
  return `${b === 'right' ? 100 : 0}% ${a === 'bottom' ? 0 : 100}%`;
};

export const StyledPopup = styled(Popup)`
  &-content {
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-xl);
    border: none;
    font-size: var(--font-size-sm);
    background: var(--color-modal);
    border: 1px solid var(--color-text-lightest);
    overflow: hidden;
    transform-origin: ${handleOrigin};
    animation: ${slideIn} 0.3s var(--ease-out-expo) both;
    margin: ${({ mobilePopup }) =>
      mobilePopup === true ? '-0.5rem 0 0 -0.5rem' : '0.75rem 0 0 0'};
    opacity: 0;
  }
`;
export const Label = styled.span`
  color: ${({ color }) => color || 'var(--color-text-darker)'};
  white-space: nowrap;
`;
export const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem 0.25rem 0.5rem;
  border: 1px solid var(--color-text-lightest);
  color: var(--color-text-dark);
  background-color: transparent;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  &:hover {
    border: 1px solid var(--color-text-lighter);
    color: var(--color-text-darker);
    ${Label} {
      color: var(--color-text-darkest);
    }
  }
  & + & {
    margin-left: 0.5rem;
    ${breakpoints.xl} {
      margin-left: 1rem;
    }
  }

  ${Label} {
    margin-left: 0.5rem;
  }
  &:disabled {
    cursor: not-allowed;
    color: var(--color-text-light);
    ${Label} {
      color: var(--color-text-light);
    }
    &:hover {
      border: 1px solid var(--color-text-lightest);
    }
  }
`;

export const DropdownTrigger = styled(BasicButton)`
  margin: -0.35rem -0.75rem;
`;

export const ActionMenuBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-size: var(--font-size-sm);
`;
export const ActionMenuList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  font-size: var(--font-size-sm);
  display: grid;
  grid-template-columns: auto auto;
  ${Label} {
    padding: 0.55rem 0 0.55rem 1.5rem;
    &:not(:first-of-type) {
      border-top: 1px solid var(--color-text-lightest);
    }
  }
`;
export const IconContainer = styled.div`
  padding: 0 1.25rem;
  height: 100%;
  color: ${({ color }) => color};
  &:not(:first-of-type) {
    border-top: 1px solid var(--color-text-lightest);
  }
  div {
    height: 100%;
  }
`;
export const MobileItem = styled.div`
  display: contents;
  cursor: pointer;

  &.is-disabled {
    pointer-events: none;
    ${Label} {
      color: var(--color-text-light);
    }
    ${IconContainer} {
      color: var(--color-text-light);
    }
  }

  &:hover > * {
    background-color: var(--color-background-hint);
  }
`;
export const ActionMenuToggle = styled.div`
  position: relative;
  width: 1rem;
  height: 1rem;
  display: grid;
  place-content: center;
  z-index: 10000;
  padding: 1.25rem 1.35rem 1.25rem 1.5rem;
  margin-left: auto;
  cursor: pointer;
  div {
    z-index: 10002;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0.5rem;
    bottom: 0.75rem;
    background-color: var(--color-text-lightest);
    width: 1px;
    z-index: 10003;
  }
  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 4.75rem;
    pointer-events: none;
    z-index: 10001;
    background: linear-gradient(
      -90deg,
      var(--color-background) 2.5rem,
      var(--color-background-transparent)
    );
  }
`;
