import styled from 'styled-components/macro';

const ToggleButton = styled.div`
  margin: -0.15rem -0.5rem;

  & > button {
    padding: 0.15rem 0.5rem;
    font-size: var(--font-size-xs);
    font-size: var(--font-size-sm);

    > div {
      transform: rotate(90deg);
    }
  }
`;
export default ToggleButton;
