import styled, { keyframes, css } from 'styled-components/macro';

import { Container } from 'components/organisms/derpling/derpling-button/derpling-button-style';
import derpling from '../../../assets/images/derpling.svg';

export const LoginPage = styled.div`
  --brand: #dacde5;

  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  > svg {
    width: 12rem;

    > :not(:last-child) {
      fill: var(--color-nega) !important;
    }
  }

  // Dark mode switch
  > div:last-child {
    position: absolute;
    bottom: 0.5rem;
    right: 1rem;
  }
`;

export const Login = styled.div`
  width: 400px;
`;

export const LoginTopRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 945 868'%3E%3Cpath fill='%23d9cae6' d='M0 0h366v502H0zM366 502h579v366H366z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 15vw;
  height: 15vw;
`;

export const LoginBottomLeft = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 695.43 1353.4'%3E%3Cpath fill='%23d9cae6' d='M0 0v293.22c206.79 0 364.67 167.29 364.67 383.48S206.52 1060.18 0 1060.18v293.22c392.89 0 695.43-295.17 695.43-676.7S392.89 0 0 0Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 15vw;
  height: 15vw;
`;

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - var(--sideMargin));
  max-width: 28rem;
  padding: calc(var(--sideMargin) * 2) var(--sideMargin);
  background: var(--color-modal);
  border-radius: var(--border-radius-base);
  box-shadow: var(--shadow-lg);
  border: 1px solid var(--color-background-hint);
  z-index: 1;
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DerplingContainer = styled(Container)`
  position: relative;
  background-color: var(--color-modal);
  cursor: default;

  width: 5rem;
  height: 5rem;
  right: unset;
  bottom: unset;

  pointer-events: ${({ disabled }) => disabled && 'none'};

  &:after {
    background-color: var(--brand);
  }

  &:hover {
    box-shadow: unset;
  }
`;

export const Derpling = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--brand);
  mask-image: url(${derpling});
  mask-repeat: no-repeat;
  mask-position: 50% 38%;
  mask-size: 50%;
`;

export const DerplingTitle = styled.h1`
  margin-left: var(--font-size-xxs);
  margin-bottom: 3rem;
  font-size: var(--font-size-xl);
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: var(--font-size-xxs);
  color: var(--brand);
`;

const rotate = keyframes`
  to {
    --angle: 0deg;
  }
`;

const mRotate = keyframes`
  to {
    --angle: 90deg;
  }
`;

const gBorder = css`
  conic-gradient(
    from var(--angle),
    #ea4435 0% 7%,
    #4185f3 7% 26%,
    #33a852 26% 56%,
    #fabd03 56% 72%,
    #ea4435 72% 100%
  )
  1;
`;

const mBorder = css`
  conic-gradient(
    from var(--angle),
    #ffbb04 0% 25%,
    #06a5f0 25% 50%,
    #f35324 50% 75%,
    #82bc07 75% 100%
  )
  1;
`;

const dBorder = css`
  linear-gradient(
    0deg, var(--brand),
    var(--brand)
  )
  1;
`;

const lock = keyframes`
  from {
    fill: var(--color-text-lightest);
  }
  to {
    fill: var(--brand);
  }
`;

const getAngle = ({ visual }) => (visual === 'google' ? 25 : 0);
const getBorder = ({ visual }) => {
  switch (visual) {
    case 'google':
      return gBorder;
    case 'msoft':
      return mBorder;
    default:
      return dBorder;
  }
};
const getAnimation = ({ visual }) => (visual === 'google' ? rotate : mRotate);

export const LoginButton = styled.button`
  @property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
  }
  --angle: ${getAngle}deg;

  display: flex;
  align-items: center;

  background-color: var(--color-background);
  padding: 1rem;
  width: 100%;
  color: var(--color-text-darkest);

  cursor: pointer;
  margin: 0.5rem 0;

  border: 2px solid;
  border-image: linear-gradient(0deg, var(--color-text-lightest) 0% 100%) 1;
  border-radius: var(--border-radius-md);

  &:active,
  &:focus,
  &:hover {
    outline: 0;
    text-decoration: none;
  }

  transition: border-image 200ms ease-out;

  &:hover {
    border-image: ${getBorder}
    animation: ${getAnimation} 1s cubic-bezier(0.87, 0, 0.17, 1);
    animation-fill-mode: both;
  }

  svg {
    margin-right: 1.5rem;
    height: 2rem;
    width: 2rem;
  }

  > div {
    display: inline-table;

    path {
      fill: var(--color-text-lightest);
    }
  }

  &:hover > div path {
    fill: var(--brand);
    animation: ${lock} 200ms ease-out;
  }

  &:not(:hover) > div:last-child {
    display: none;
  }

  &:hover > div:first-child {
    display: none;
  }
`;

export const LoginDivider = styled.div`
  position: relative;
  width: 25%;
  margin: 1rem auto;
  border-bottom: 1px solid var(--color-text-lightest);

  &:after {
    content: 'OR';
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: var(--color-modal);
    padding: 0 0.85rem;

    font-size: var(--font-size-xs);
    color: var(--color-text-lightest);
  }
`;

export const LoginDisclaimer = styled.div`
  font-size: var(--font-size-xs);
  font-weight: 100;
  color: var(--color-text-light);
  letter-spacing: 0.005em;
`;

const popIn = keyframes`
  to {
    opacity: 1;
    transform: translate(-5rem, 0rem) scale(1);
  }
`;

const popOut = keyframes`
  from {
    opacity: 1;
    transform: translate(-5rem, 0rem) scale(1);
  }
  to {
    opacity: 0;
    transform: translate(-4.5rem, 0.5rem) scale(0.6);
  }
`;

export const DerplingMessage = styled.div`
  position: absolute;
  bottom: 78.5%;
  right: 0.5rem;
  padding: 0.4rem 0.85rem;

  max-width: 125%;
  width: max-content;

  border-radius: var(--border-radius-xxl);
  background-color: var(--color-primary-lightest);

  transform: translate(-4.5rem, 0.5rem) scale(0.6);
  transform-origin: bottom right;

  font-size: var(--font-size-xs);
  font-weight: 100;
  color: var(--color-text-darker);

  opacity: 0;
  z-index: 25002;

  animation: ${popIn} 1s var(--ease-out-expo) 0.4s;
  animation-fill-mode: forwards;

  white-space: pre-wrap;

  &.animate-out {
    animation: ${popOut} 0.3s var(--ease-in-expo);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: -0.1rem;
    height: 1rem;
  }

  &:before {
    border-bottom-left-radius: 0.8rem 0.7rem;
    border-right: 1rem solid var(--color-primary-lightest);
    right: -0.35rem;
    transform: translate(0, -0.1rem);
  }

  &:after {
    background-color: var(--color-modal);
    border-bottom-left-radius: 0.5rem;
    right: -40px;
    transform:translate(-30px, -2px);
    width: 10px;
  }
}
`;
