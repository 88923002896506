import React, { useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';

import useEmployee from 'common/hooks/useEmployee';

import { DragAndDrop } from 'components/atoms';
import { ModalConfirmDialog } from 'components/molecules';

import {
  Container,
  EditorContainer,
  RangeSlider
} from './avatar-uploader-style';

const AvatarUploader = props => {
  const [avatar, setAvatar] = useState(props.avatar);
  const [value, setValue] = useState(1);
  const [loading, setLoading] = useState(false);
  const editor = useRef();
  const employee = useEmployee();

  const saveImage = () => {
    if (editor) {
      setLoading(true);
      const canvasScaled = editor.current.getImageScaledToCanvas();
      const a = canvasScaled.toDataURL('image/jpeg');
      employee.updateAvatar(a).then(() => {
        setLoading(false);
        props.closeModal();
      });
    }
  };

  const onDrop = acceptedFiles => {
    setAvatar(acceptedFiles[0]);
  };

  return (
    <ModalConfirmDialog
      heading='Change your profile picture'
      confirmLabel={loading ? 'Saving…' : 'Save'}
      cancelAction={() => props.closeModal()}
      confirmAction={saveImage}
      confirmDisabled={!avatar || loading}
    >
      <Container>
        {!avatar ? (
          <DragAndDrop
            dropCb={onDrop}
            accept='image/*'
            multiple={false}
            text="Drag 'n' drop picture, or click to select…"
            icon='filePlus'
          />
        ) : (
          <EditorContainer>
            <AvatarEditor
              image={avatar}
              ref={editor}
              width={500}
              height={500}
              border={1}
              color={[0, 0, 0, 0.32]}
              scale={value}
              borderRadius={250}
              rotate={0}
            />
            <RangeSlider
              className='slider'
              type='range'
              min='1'
              max='2'
              step='0.01'
              value={value}
              onChange={e => setValue(parseFloat(e.target.value))}
            />
          </EditorContainer>
        )}
      </Container>
    </ModalConfirmDialog>
  );
};

export default AvatarUploader;
