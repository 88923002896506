import styled from 'styled-components/macro';
import { breakpointsRefresh } from 'common/styles';

import { BasicButton } from '../button/button-style';

export { RowAction } from 'components/organisms/table/table-style';

const breakpoints = breakpointsRefresh;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Button = styled(BasicButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.75rem;
  width: 1.75rem;
  padding: 0;
  background: var(--color-primary);
  border-radius: var(--border-radius-xs);
  &:hover {
    background-color: var(--color-primary-light);
  }

  ${breakpoints.md} {
    padding: 0;
  }

  &:nth-child(2) {
    margin-left: 0.75rem;
    background: var(--color-text);

    &:hover {
      background-color: var(--color-text-light);
    }
  }
`;
