import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Icon } from 'components/atoms';
import { TagButton, LabelContainer, TagCount, SavingStatus } from './tag-style';

const Tag = observer(props => {
  const [processing, setProcessing] = useState(false);
  const { tag, model, deletable, ...rest } = props;
  const { name, count, deleted } = tag;

  const handleDelete = () => {
    if (processing) return;

    setProcessing(true);
    tag.removeOrRestore().finally(() => setProcessing(false));
  };

  const handleToggle = () => {
    if (processing || !props.editable || !model) return;

    setProcessing(true);
    model.toggleTag(tag.id).finally(() => setProcessing(false));
  };

  const handleClick = () => (deletable ? handleDelete() : handleToggle());

  return (
    <TagButton
      type='button'
      deletable={deletable && count === 0}
      deleted={deleted}
      onClick={handleClick}
      saving={processing}
      active={model && model.hasTag(tag.id)}
      {...rest}
    >
      <LabelContainer>{name}</LabelContainer>
      {deletable &&
        (count > 0 ? (
          <TagCount>{count}</TagCount>
        ) : (
          <Icon name={deleted ? 'reset' : 'trash'} size='var(--font-size-sm)' />
        ))}
      {processing && (
        <SavingStatus>
          <Icon name='update' size='var(--font-size-xl)' animated />
        </SavingStatus>
      )}
    </TagButton>
  );
});

export default Tag;

Tag.propTypes = {
  tag: PropTypes.object.isRequired,
  model: PropTypes.object,
  editable: PropTypes.bool,
  deletable: PropTypes.bool
};

Tag.defaultProps = {
  model: null,
  editable: true,
  deletable: false
};
