/* eslint-disable */
import React, { useState } from 'react';

export const DerpContext = React.createContext();

export const DerpContextProvider = props => {
  const [derp, setDerp] = useState(props.derp);

  return (
    <DerpContext.Provider value={derp}>{props.children}</DerpContext.Provider>
  );
};
