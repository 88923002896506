import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Balance } from 'components/atoms';

import { AuthContext } from 'contexts';
import { Nav, NotificationDisplay } from 'components/organisms';

import { EmployeeAvatar, Logout, DarkModeSwitch } from 'components/molecules';

import {
  Container,
  LogoutWrapper,
  Name,
  ProfilePicture,
  Hours
} from './sidebar-style';

const Sidebar = observer(() => {
  const { user, settings } = useContext(AuthContext);

  return (
    <Container>
      <div>
        <ProfilePicture>
          <EmployeeAvatar size='2rem' role={user.role} src={user.avatar} />
          {settings.disabled_features?.includes('Notifications') ? null : (
            <NotificationDisplay />
          )}
        </ProfilePicture>
        <Name>
          <span>{user.fullName()}</span>
          {settings.disabled_features?.includes('Hours') ? null : (
            <Hours>
              <Balance employee={user} />
            </Hours>
          )}
        </Name>
        <Nav />
      </div>
      <LogoutWrapper>
        <Logout />
        <DarkModeSwitch />
      </LogoutWrapper>
    </Container>
  );
});

export default Sidebar;
