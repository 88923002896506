import { types, flow } from 'mobx-state-tree';
import axios from '../common/utils/axios';
import { Resourcing } from './resourcing-model';
import { EmployeeCv } from './cv/cv-model';
import { Employee } from './employee-model';

// TODO: Refactor to handle this on sql side like active projects
const parseLatestProject = list => {
  const assignment = JSON.parse(list).filter(Boolean)[0];
  return assignment
    ? {
        id: assignment.assignment_id,
        alloc: assignment.allocation,
        end: assignment.end,
        project: {
          id: assignment.project_id,
          name: assignment.project_name,
          color: assignment.color
        },
        continuation: assignment.continuation_id
          ? {
              id: assignment.continuation_id,
              start: assignment.continuation_start,
              end: assignment.continuation_end
            }
          : null
      }
    : null;
};

export const ResourceList = types
  .model('ResourceList', {
    resources: types.map(Resourcing)
  })
  .views(self => ({
    all() {
      return [...self.resources.values()];
    }
  }))
  .actions(self => ({
    load: flow(function* load() {
      const { data } = yield axios.get('/api/v1/resourcing');

      self.resources.clear();

      data.forEach(resource => {
        resource.employee = self.addEmployee(resource); // eslint-disable-line no-param-reassign
        resource.deals = JSON.parse(resource.deals); // eslint-disable-line no-param-reassign
        resource.offers = JSON.parse(resource.offers); // eslint-disable-line no-param-reassign
        resource.tags = JSON.parse(resource.tags); // eslint-disable-line no-param-reassign
        // eslint-disable-next-line no-param-reassign
        resource.cv = self.createEmployeeCv(
          resource.cv,
          resource.cv_id,
          resource.cv_updated_at
        );
        resource.active_projects = JSON.parse(resource.active_projects); // eslint-disable-line no-param-reassign
        resource.active_projects.forEach(project => {
          if (project.continuation?.id === null) {
            project.continuation = null; // eslint-disable-line no-param-reassign
          }
        });
        const absence = JSON.parse(resource.absence);
        resource.absence = absence?.id ? absence : null; // eslint-disable-line no-param-reassign

        resource.latest_project = parseLatestProject(resource.latest_project); // eslint-disable-line no-param-reassign
        resource.days = resource.days ? Number(resource.days) : null; // eslint-disable-line no-param-reassign

        // eslint-disable-next-line no-param-reassign
        resource.metadata = resource.metadata
          ? JSON.parse(resource.metadata)
          : {};

        self.resources.put(resource);
      });

      return self;
    }),
    createEmployeeCv(cv, cv_id, cv_updated_at) {
      const parsedCv = JSON.parse(cv);
      const employeeCv = EmployeeCv.create({
        id: cv_id,
        updated_at: cv_updated_at,
        ...parsedCv
      });
      return employeeCv;
    },
    addEmployee(data) {
      const { id, first_name, last_name, avatar, location, role } = data;
      return Employee.create({
        id,
        first_name,
        last_name,
        avatar,
        location,
        role
      });
    }
  }));

export default ResourceList;
