import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  // TODO: Hack, refactor ListAvatar to work without links
  a {
    pointer-events: none;
  }
`;

export const EmployeeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const EmployeeAction = styled.div`
  opacity: 0;
  transition: opacity 0.2s linear;
  @media (pointer: coarse) {
    opacity: 1;
  }
  ${EmployeeContainer}:hover & {
    opacity: 1;
  }
`;

export const SearchContainer = styled.div`
  position: relative;
`;

export const EmployeeList = styled.div`
  position: absolute;
  width: max-content;
  min-width: 100%;
  max-width: 150%;
  padding: 0.5rem;
  margin-left: -0.5rem;
  margin-top: 0.5rem;
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--color-grey-lightest);
  border-radius: var(--border-radius-xs);
  background-color: var(--color-modal);
  color: var(--color-text-dark);

  z-index: 160;
`;

export const EmployeeWrapper = styled.div`
  padding: 0.5rem;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--color-input-hover);
  }

  &:focus {
    outline: none;
    border-color: var(--color-input-focus);
    border-radius: var(--border-radius-xs);
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: var(--border-radius-xs);
  outline: none;
  border: ${props =>
    props.$hasError === true
      ? '1px solid var(--color-error-light)'
      : '1px solid transparent'};
  &:focus-within {
    border: 1px solid
      ${props => props.$colorFocus || 'var(--color-input-focus)'};
  }
  margin-bottom: 0.125rem;
  margin-left: -0.5rem;

  label {
    margin-left: 0;
  }

  input {
    &:focus {
      border: 1px solid transparent;
    }
  }

  > span {
    display: flex;
    margin: 0.15rem 0.25rem;
    padding: 0.15rem 0.5rem;
    border-radius: var(--border-radius-sm);
    align-items: center;
    white-space: nowrap;
    background-color: var(--color-grey-lightest);
    font-size: var(--font-size-xs);
    color: var(--color-text-dark);

    > button {
      padding: 0.1rem 0.2rem;
      margin-right: -0.25rem;
      margin-left: 0.25rem;
    }
  }
`;
