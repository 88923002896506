const rbac = {
  ARCHIVE_READ: 'archive:read',
  EMPLOYEES_READ: 'employees:read',
  EMPLOYEES_SUMMARY_READ: 'employees-report:read',
  EMPLOYEES_TIMELINE_READ: 'employees-timeline:read',
  EMPLOYEE_DETAILS_READ: 'employee-details:read',
  EMPLOYEE_DETAILS_WRITE: 'employee-details:write',
  EMPLOYEE_HOURS_READ: 'employee-hours:read',
  EMPLOYEE_CV_READ: 'employee-cv:read',
  EMPLOYEE_CV_WRITE: 'employee-cv:write',
  EMPLOYEE_PROFILE_READ: 'employee-profile:read',
  EMPLOYEE_ONBOARDING_READ: 'employee-onboarding:read',
  EMPLOYEE_EMPLOYMENT_READ: 'employee-employment:read',
  EXTERNALS_READ: 'externals:read',
  EXTERNALS_WRITE: 'externals:write',
  PROJECTS_READ: 'projects:read',
  PROJECTS_WRITE: 'projects:write',
  PROJECT_DETAILS_READ: 'project-details:read',
  PROJECT_REPORTS_READ: 'project_reports:read',
  PROJECT_CONTACT_WRITE: 'project-contact:write',
  PROJECT_COMMON_READ: 'project-common:read',
  CUSTOMERS_WRITE: 'customers:write',
  CUSTOMER_READ: 'customer:read',
  CUSTOMER_MANAGERS_WRITE: 'customer-managers:write',
  CUSTOMER_PROJECTS_WRITE: 'customer-projects:write',
  DEALS_READ: 'deals:read',
  OFFERS_READ: 'offers:read',
  OFFERS_WRITE: 'offers:write',
  OFFER_DETAILS_READ: 'offer-details:read',
  OFFER_DETAILS_CV_READ: 'offer-details-cv:read',
  OFFER_DETAILS_CV_WRITE: 'offer-details-cv:write',
  HOURS_READ: 'hours:read',
  HOURS_WRITE: 'hours:write',
  HOURS_OVERVIEW: 'hours:overview',
  HOURS_LOCKING: 'hours:lock',
  REPORTS_READ: 'reports:read',
  HOLIDAYS_WRITE: 'holidays:write',
  SETTINGS_READ: 'settings:read',
  GUIDE_READ: 'guide:read',
  IDEAS_READ: 'ideas:read',
  CV_READ: 'cv:read',
  CV_WRITE: 'cv:write',
  CV_SENIORITY: 'cv:seniority',
  GLOBAL_SEARCH: 'global:search',
  TAGS_READ: 'tags:read',
  TAGS_WRITE: 'tags:write',
  LOGS_HRM_READ: 'logs:hrm:read',
  LOGS_SALES_READ: 'logs:sales:read',
  LOGS_MENTORING_READ: 'logs:mentoring:read',
  LOGS_EMPLOYEES_READ: 'logs:employees:read',
  FEEDBACK_READ: 'feedback:read',
  ONBOARDING_WRITE: 'onboarding:write',
  RESOURCING_READ: 'resourcing:read'
};

const projectManagerPermission = ({ managers = [], employee_id = -1 } = {}) =>
  managers.map(manager => manager.id).includes(employee_id);

const accountManagerPermission = ({ account = [], employee_id = -1 } = {}) =>
  account.map(manager => manager.id).includes(employee_id);

const dynamicProjectManager = {
  [rbac.PROJECT_DETAILS_READ]: projectManagerPermission,
  [rbac.PROJECT_REPORTS_READ]: projectManagerPermission,
  [rbac.PROJECTS_WRITE]: projectManagerPermission
};

const dynamicAccountManager = {
  [rbac.CUSTOMERS_WRITE]: accountManagerPermission,
  [rbac.CUSTOMER_PROJECTS_WRITE]: accountManagerPermission,
  [rbac.PROJECT_CONTACT_WRITE]: accountManagerPermission
};

const rules = {
  admin: {
    static: Object.values(rbac)
    /*
    dynamic: {
      "employees:manage": ({ employee_id, manager_id }) => {
        if (!employee_id || !manager_id) return false;
        return employee_id === manager_id;
      }
    }
    */
  },
  hrm: {
    static: [
      rbac.EMPLOYEES_READ,
      rbac.EMPLOYEES_TIMELINE_READ,
      rbac.EMPLOYEE_CV_READ,
      rbac.EMPLOYEE_CV_WRITE,
      rbac.EMPLOYEE_DETAILS_READ,
      rbac.EMPLOYEE_ONBOARDING_READ,
      rbac.EMPLOYEE_PROFILE_READ,
      rbac.CUSTOMER_READ,
      rbac.HOURS_READ,
      rbac.HOURS_WRITE,
      rbac.HOURS_OVERVIEW,
      rbac.PROJECTS_READ,
      rbac.GUIDE_READ,
      rbac.IDEAS_READ,
      rbac.CV_READ,
      rbac.CV_SENIORITY,
      rbac.GLOBAL_SEARCH,
      rbac.TAGS_READ,
      rbac.EXTERNALS_READ,
      rbac.EXTERNALS_WRITE,
      rbac.LOGS_SALES_READ,
      rbac.LOGS_MENTORING_READ,
      rbac.FEEDBACK_READ,
      rbac.RESOURCING_READ
    ],
    dynamic: {
      ...dynamicProjectManager,
      ...dynamicAccountManager
    }
  },
  finance: {
    static: [
      rbac.ARCHIVE_READ,
      rbac.EMPLOYEES_READ,
      rbac.EMPLOYEES_SUMMARY_READ,
      rbac.EMPLOYEES_TIMELINE_READ,
      rbac.EMPLOYEE_DETAILS_READ,
      rbac.EMPLOYEE_HOURS_READ,
      rbac.EMPLOYEE_CV_READ,
      rbac.EMPLOYEE_CV_WRITE,
      rbac.EMPLOYEE_PROFILE_READ,
      rbac.EMPLOYEE_EMPLOYMENT_READ,
      rbac.EMPLOYEE_ONBOARDING_READ,
      rbac.EXTERNALS_READ,
      rbac.EXTERNALS_WRITE,
      rbac.CUSTOMER_READ,
      rbac.PROJECTS_READ,
      rbac.PROJECTS_WRITE,
      rbac.PROJECT_DETAILS_READ,
      rbac.PROJECT_CONTACT_WRITE,
      rbac.PROJECT_COMMON_READ,
      rbac.OFFERS_READ,
      rbac.OFFERS_WRITE,
      rbac.OFFER_DETAILS_READ,
      rbac.OFFER_DETAILS_CV_READ,
      rbac.OFFER_DETAILS_CV_WRITE,
      rbac.DEALS_READ,
      rbac.HOURS_READ,
      rbac.HOURS_WRITE,
      rbac.HOURS_OVERVIEW,
      rbac.HOURS_LOCKING,
      rbac.REPORTS_READ,
      rbac.SETTINGS_READ,
      rbac.GUIDE_READ,
      rbac.IDEAS_READ,
      rbac.CV_READ,
      rbac.CV_WRITE,
      rbac.CV_SENIORITY,
      rbac.GLOBAL_SEARCH,
      rbac.PROJECT_REPORTS_READ,
      rbac.TAGS_READ,
      rbac.TAGS_WRITE,
      rbac.LOGS_SALES_READ,
      rbac.LOGS_MENTORING_READ,
      rbac.FEEDBACK_READ,
      rbac.RESOURCING_READ
    ]
  },
  sales: {
    static: [
      rbac.EMPLOYEES_READ,
      rbac.EMPLOYEES_TIMELINE_READ,
      rbac.EMPLOYEE_CV_READ,
      rbac.EMPLOYEE_CV_WRITE,
      rbac.EMPLOYEE_PROFILE_READ,
      rbac.EMPLOYEE_DETAILS_READ,
      rbac.CUSTOMER_READ,
      rbac.OFFERS_READ,
      rbac.OFFERS_WRITE,
      rbac.OFFER_DETAILS_READ,
      rbac.OFFER_DETAILS_CV_READ,
      rbac.OFFER_DETAILS_CV_WRITE,
      rbac.DEALS_READ,
      rbac.HOURS_READ,
      rbac.HOURS_WRITE,
      rbac.HOURS_OVERVIEW,
      rbac.PROJECTS_READ,
      rbac.GUIDE_READ,
      rbac.IDEAS_READ,
      rbac.CV_READ,
      rbac.CV_SENIORITY,
      rbac.GLOBAL_SEARCH,
      rbac.TAGS_READ,
      rbac.TAGS_WRITE,
      rbac.SETTINGS_READ,
      rbac.LOGS_SALES_READ,
      rbac.LOGS_MENTORING_READ,
      rbac.EXTERNALS_READ,
      rbac.EXTERNALS_WRITE,
      rbac.FEEDBACK_READ,
      rbac.RESOURCING_READ
    ],
    dynamic: {
      ...dynamicProjectManager,
      ...dynamicAccountManager
    }
  },
  user: {
    static: [
      rbac.EMPLOYEES_READ,
      rbac.EMPLOYEE_CV_READ,
      rbac.HOURS_READ,
      rbac.HOURS_WRITE,
      rbac.HOURS_OVERVIEW,
      rbac.PROJECTS_READ,
      rbac.CUSTOMER_READ,
      rbac.GUIDE_READ,
      rbac.IDEAS_READ,
      rbac.CV_READ,
      rbac.EMPLOYEE_PROFILE_READ,
      rbac.OFFERS_READ,
      rbac.OFFER_DETAILS_READ,
      rbac.OFFER_DETAILS_CV_READ,
      rbac.TAGS_READ,
      rbac.FEEDBACK_READ,
      rbac.GLOBAL_SEARCH
    ],
    dynamic: {
      ...dynamicProjectManager,
      ...dynamicAccountManager,
      [rbac.OFFER_DETAILS_CV_WRITE]: ({ owner, editor }) => owner === editor
    }
  },
  external: {
    static: [rbac.EMPLOYEE_PROFILE_READ, rbac.CV_READ, rbac.TAGS_READ]
  },
  freelance: {
    static: [
      rbac.EMPLOYEE_PROFILE_READ,
      rbac.CV_READ,
      rbac.TAGS_READ,
      rbac.HOURS_READ
    ]
  }
};

export default rules;
export { rbac };
