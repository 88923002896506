import styled from 'styled-components/macro';

export const CheckboxContainer = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`;
export const Check = styled.div`
  cursor: pointer;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  outline: none;
  border-radius: 2px;
  border: 1px solid var(--color-text-light);
  transition: all 0.15s ease-in;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  path {
    stroke: #fff;
    stroke-width: ${({ size }) => `calc(${size} * 0.25)`};
    stroke-dasharray: 24;
    stroke-dashoffset: 24;
    transition: all 0.15s ease-in;
  }

  &.is-checked {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    transition: all 0.2s ease-out;
    path {
      stroke-dashoffset: 0;
      transition: all 0.2s ease-out;
    }
  }
`;

export const Label = styled.div`
  margin-left: ${({ size }) => `calc(${size} * 0.25)`};
`;
