import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const Draggable = props => {
  const [dragState, setDragState] = useState({
    startX: 0,
    startY: 0,
    translateX: props.translateX,
    translateY: props.translateY,
    lastTranslateX: props.translateX,
    lastTranslateY: props.translateY,
    active: false,
    xAxis: props.xAxis,
    yAxis: props.yAxis
  });

  // The props are set only on the first pass ... subsequent updates to
  // state need to happen via useEffect
  useEffect(() => {
    if (!dragState.active) {
      setDragState(prev => {
        const next = {
          translateX: props.translateX,
          translateY: props.translateY,
          lastTranslateX: props.translateX,
          lastTranslateY: props.translateY
        };
        return { ...prev, ...next };
      });
    }
  }, [props.translateX, props.translateY]);

  const bounds = Object.assign({}, Draggable.defaultProps.bounds, props.bounds);

  // A helper method to bound the given axis value to the provided limits
  const boundValue = (val, min, max) => Math.min(Math.max(val, min), max);

  const eventListener = useCallback(event => {
    if (['touchmove', 'mousemove'].includes(event.type)) {
      const { clientX, clientY } = event;
      const { pageX, pageY } = event.touches?.[0] || {};
      setDragState(prev => ({
        ...prev,
        translateX: boundValue(
          prev.active && prev.xAxis
            ? (clientX || pageX) - prev.startX + prev.lastTranslateX
            : prev.translateX,
          bounds.left,
          bounds.right
        ),
        translateY: boundValue(
          prev.active && prev.yAxis
            ? (clientY || pageY) - prev.startY + prev.lastTranslateY
            : prev.translateY,
          bounds.top,
          bounds.bottom
        )
      }));
      event.preventDefault();
      event.stopPropagation();
    } else {
      setDragState(prev => {
        const next = {};
        const { translateX: x, translateY: y } = prev;
        if (Math.abs(x) > props.threshold || Math.abs(y) > props.threshold) {
          props.onDragEnd({ x, y });
          next.lastTranslateX = x;
          next.lastTranslateY = y;
        } else {
          next.translateX = 0;
          next.translateY = 0;
        }
        return {
          ...prev,
          ...next,
          active: false
        };
      });
    }
  });

  useEffect(() => {
    const events = ['mousemove', 'touchmove', 'mouseup', 'touchend'];
    if (dragState.active) {
      events.map(e =>
        window.addEventListener(e, eventListener, { passive: false })
      );
    } else {
      events.map(e => window.removeEventListener(e, eventListener));
    }
    return () => events.map(e => window.removeEventListener(e, eventListener));
  }, [dragState.active]);

  useEffect(() => {
    if (dragState.active) {
      props.onDrag(dragState.translateX, dragState.translateY);
    }
  }, [dragState.translateX, dragState.translateY]);

  const onStart = event => {
    if (dragState.active) return;

    const next = {};

    if (event.type === 'touchstart') {
      const { pageX, pageY } = event.touches[0]; // clientX/Y not supported everywhere

      next.startX = pageX;
      next.startY = pageY;
      next.active = true;
    } else if (event.type === 'mousedown') {
      next.startX = event.clientX;
      next.startY = event.clientY;
      next.active = event.buttons === 1;
    }

    if (next.active) {
      props.onDragStart(next);
      setDragState(prev => ({ ...prev, ...next }));
    }
  };

  return (
    <div
      className={props.className}
      style={{
        transform: `translate(${dragState.translateX}px, ${dragState.translateY}px)`,
        transition:
          !dragState.active &&
          'transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)'
      }}
      onMouseDown={onStart}
      onTouchStart={onStart}
    >
      {props.children}
    </div>
  );
};

Draggable.propTypes = {
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
  translateX: PropTypes.number,
  translateY: PropTypes.number,
  xAxis: PropTypes.bool,
  yAxis: PropTypes.bool,
  bounds: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
    bottom: PropTypes.number
  }),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  threshold: PropTypes.number
};

Draggable.defaultProps = {
  onDrag: () => {}, // eslint-disable-line prettier/prettier
  onDragEnd: () => {}, // eslint-disable-line prettier/prettier
  onDragStart: () => {}, // eslint-disable-line prettier/prettier
  translateX: 0,
  translateY: 0,
  xAxis: true,
  yAxis: true,
  bounds: {
    left: Number.MIN_SAFE_INTEGER,
    right: Number.MAX_SAFE_INTEGER,
    top: Number.MIN_SAFE_INTEGER,
    bottom: Number.MAX_SAFE_INTEGER
  },
  className: '',
  threshold: 0
};

export const Viewport = props => {
  const target = useRef();

  return (
    <div
      ref={target}
      className='viewport'
      style={{
        overflow: 'hidden'
      }}
    >
      {props.children}
    </div>
  );
};

Viewport.propTypes = {
  children: PropTypes.node.isRequired
};
