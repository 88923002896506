import React, { useEffect, useState, useContext, Fragment } from 'react';
import { observer } from 'mobx-react';
import sort from 'fast-sort';

import { ViewportContext, AuthContext } from 'contexts';
import { canAccess } from 'common/rbac';

import { RowButtons, Icon } from 'components/atoms';

import {
  TableArea,
  TableContainer,
  HeaderRow,
  Row,
  FooterRow,
  NoResults,
  AddRowContainer,
  RowToAdd,
  RowToAddActions,
  AddRowButton,
  Label,
  StackedCell,
  SubHeader
} from './table-style';
import Cell from './cell';
import HeaderCell from './header-cell';
import Search from './search';

const search = (input, keys, data) => {
  const searchTerms = input.split(/[ ,+]+/);
  let result = data;
  searchTerms.forEach(term => {
    const matches = result.filter(item => {
      return keys.some(key =>
        String(item[key])
          .toLowerCase()
          .match(
            new RegExp(
              term
                .replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
                .replace(/-/g, '\\x2d')
                .toLowerCase(),
              'i'
            )
          )
      );
    });
    result = matches;
  });
  return result;
};

const TableRow = observer(props => {
  const { row, columns, collapsible, overflow, index } = props;

  const collapsibleColumns = () =>
    collapsible.map(column => (
      <Cell
        key={`body-${column.field}`}
        value={row[column.field]}
        renderer={column.renderer}
        data={column.renderer ? row : ''}
        verticalAlign={column.verticalAlign}
        justify={column.justify}
        className={column.className}
        highlightBy={row[column.highlightBy]}
        stretch
        hideOverflow={column.hideOverflow}
        allowWrap={column.allowWrap}
        maxWidth={column.maxWidth}
        noMargin={column.noMargin}
        colWidth={column.width}
      />
    ));

  return (
    <Row className={index % 2 && 'row-odd'} collapsed={row.collapsed}>
      {columns.map(column => (
        <Cell
          key={`body-${column.field}`}
          value={row[column.field]}
          renderer={column.renderer}
          data={column.renderer ? row : ''}
          verticalAlign={column.verticalAlign}
          justify={column.justify}
          className={column.className}
          highlightBy={row[column.highlightBy]}
          stretch={column.stretch}
          hideOverflow={column.hideOverflow}
          allowWrap={column.allowWrap}
          maxWidth={column.maxWidth}
          noMargin={column.noMargin}
          overflow={overflow}
        />
      ))}
      <div style={{ display: 'contents' }}>
        {row.collapsed ? null : collapsibleColumns()}
      </div>
    </Row>
  );
});

const Table = observer(props => {
  const {
    columns,
    collapsible = [],
    rows,
    grouped,
    footer,
    defaultSort,
    defaultOrder = 'asc',
    rowKey,
    className,
    empty = 'No data',
    small,
    rowToAdd,
    onAddRow,
    onSearch,
    addRowLabel,
    addRowPermission,
    onAddRowConfirm,
    onAddRowCancel,
    overflow // TODO: Better name
  } = props;

  // const [data, setData] = useState([]);
  const [footerRow, setFooterRow] = useState(false);
  const [sortBy, setSortBy] = useState(false);
  const [sortReverse, setSortReverse] = useState(defaultOrder === 'desc');
  const [searchValue, setNewSearchValue] = useState('');
  const [activeColumn, setActiveColumn] = useState('');

  const { breakpoint } = useContext(ViewportContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    setFooterRow(footer);
  }, [footer]);

  useEffect(() => {
    if (typeof onSearch === 'function') {
      onSearch(searchValue);
    }
  }, [searchValue]);

  const handleSort = (key, isActive, sortKey) => {
    setActiveColumn(key);
    setSortBy(sortKey);
    setSortReverse(isActive ? !sortReverse : false);
  };

  const handleSearchInputChange = e => {
    setNewSearchValue(e.target.value);
  };

  const keysToSearch = columns
    .filter(column => {
      return column.searchable === true;
    })
    .map(column => {
      return column.field;
    });

  const data = sort([...rows])[sortReverse ? 'desc' : 'asc']([
    r => (typeof sortBy === 'function' ? sortBy(r) : r[sortBy]),
    ...(defaultSort ? [r => r[defaultSort]] : [])
  ]);

  const dataToShow =
    keysToSearch.length > 0 && typeof onSearch === 'undefined'
      ? search(searchValue, keysToSearch, data)
      : data;

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

  const canAdd = !addRowPermission || canAccess(user.role, addRowPermission);

  const renderRow = (row, i) => (
    <TableRow
      row={row}
      columns={columns}
      collapsible={collapsible}
      overflow={overflow}
      index={i}
      key={`row-${row[rowKey || 'id'] || i}`}
    />
  );

  const renderGroup = rowGroup =>
    rowGroup.rows.length ? (
      <Fragment key={rowGroup.title}>
        <SubHeader>{rowGroup.title}</SubHeader>
        {rowGroup.rows.map(renderRow)}
      </Fragment>
    ) : null;

  return (
    <div style={{ width: '100%', height: '100%' }} className={className}>
      {keysToSearch.length > 0 && (
        <Search searchValue={searchValue} onChange={handleSearchInputChange} />
      )}
      <TableArea>
        <TableContainer columns={columns} small={small}>
          <HeaderRow>
            {columns.map(column => (
              <HeaderCell
                key={`header-${column.field}`}
                data={column}
                isActive={column.field === activeColumn}
                onClick={handleSort}
                sortable={column.sortable}
                sortBy={column.sortBy || column.field}
                verticalAlign={column.verticalAlign}
                justify={column.justify}
                className={column.className}
                overflow={overflow}
              />
            ))}
          </HeaderRow>

          {/* TODO: Rows don't always have id prop, need universal solution */}
          {grouped ? data.map(renderGroup) : dataToShow.map(renderRow)}

          {footerRow && (
            <FooterRow>
              {footerRow.map(column => (
                <Cell
                  key={`footer-${column.field}`}
                  value={column.value}
                  renderer={column.renderer}
                  data={column.renderer ? column : ''}
                  verticalAlign={column.verticalAlign}
                  justify={column.justify}
                  className={column.className}
                  overflow={overflow}
                />
              ))}
            </FooterRow>
          )}
        </TableContainer>
        {!dataToShow.length && <NoResults>{empty}</NoResults>}
      </TableArea>

      {addRowLabel && canAdd && (
        <AddRowContainer>
          {!rowToAdd?.row && (
            <AddRowButton onClick={() => onAddRow()}>
              <Icon name='plus' />
              <Label>{addRowLabel}</Label>
            </AddRowButton>
          )}

          {rowToAdd && rowToAdd?.row && (
            <>
              <RowToAdd columns={rowToAdd.columns}>
                {rowToAdd.columns.map(column => (
                  <ConditionalWrapper
                    key={`add-${column.field}-wrapper`}
                    condition={!breakpoint.lg}
                    wrapper={children => (
                      <StackedCell>
                        <div>{column.label}</div>
                        {children}
                      </StackedCell>
                    )}
                  >
                    <Cell
                      key={`add-${column.field}`}
                      value={rowToAdd.row[column.field]}
                      renderer={column.renderer}
                      data={column.renderer ? rowToAdd.row : ''}
                      verticalAlign={column.verticalAlign}
                      justify={column.justify}
                      className={column.className}
                      highlightBy={rowToAdd.row[column.highlightBy]}
                      stretch={column.stretch}
                      hideOverflow={column.hideOverflow}
                      allowWrap={column.allowWrap}
                      maxWidth={column.maxWidth}
                      noMargin={column.noMargin}
                    />
                  </ConditionalWrapper>
                ))}
              </RowToAdd>
              <RowToAddActions>
                <RowButtons
                  onConfirm={() => {
                    onAddRowConfirm();
                  }}
                  onCancel={() => onAddRowCancel()}
                />
              </RowToAddActions>
            </>
          )}
        </AddRowContainer>
      )}
    </div>
  );
});

export default Table;

// COMMENTS

/*
  TODO:
  - Make sure all the previous functionality is working - Moved sorting out of
    state due to an issue with mobx. If rows change, it was possible that table
    stateful data wouldn't change and would have references to objects that were
    removed from the state
*/
