import { types, getSnapshot, flow, getParent } from 'mobx-state-tree';
import moment from 'moment';
import axios from '../common/utils/axios';
import { EmployeeCv } from './cv/cv-model';

import { MomentType } from './types';

const hash = require('object-hash');

const Proposition = types
  .model('Proposition', {
    id: types.identifierNumber,
    employee_id: types.number,
    first_name: types.string,
    last_name: types.string,
    avatar: types.maybeNull(types.string, ''),
    cv: types.optional(EmployeeCv, () => EmployeeCv.create({ id: -1 })),
    published_cv: types.maybeNull(EmployeeCv, () =>
      EmployeeCv.create({ id: -1 })
    ),
    order_num: types.optional(types.maybeNull(types.number), -1),
    cost_center: types.maybeNull(types.string, ''),
    location: types.maybeNull(types.string, ''),
    comment: types.optional(types.string, ''),
    created_at: types.optional(MomentType, moment())
  })
  .preProcessSnapshot(snapshot => {
    if (snapshot.cv) {
      snapshot.cv.id = -1; // eslint-disable-line no-param-reassign
    }
    if (snapshot.published_cv) {
      snapshot.published_cv.id = -1; // eslint-disable-line no-param-reassign
    }
    return snapshot;
  })
  .postProcessSnapshot(sn => {
    const snapshot = Object.assign({}, sn);
    delete snapshot.avatar;
    delete snapshot.first_name;
    delete snapshot.last_name;
    return snapshot;
  })
  .views(self => ({
    fullName() {
      return `${self.last_name} ${self.first_name}`;
    },
    isCvChanged() {
      if (!self.published_cv) {
        return false;
      }
      return (
        hash(getSnapshot(self.cv)) !== hash(getSnapshot(self.published_cv))
      );
    }
  }))
  .actions(self => ({
    saveCv: flow(function* saveCv(attribs) {
      const o = getParent(self, 2);

      const offer = {
        offer: {
          propositions_attributes: [attribs]
        }
      };
      yield axios.put(`/api/v1/offers/${o.id}`, JSON.stringify(offer));

      return self;
    }),
    saveUnpublishedOrChangedCv() {
      self.saveCv({
        id: self.id,
        cv: self.cv
      });
    },
    publish() {
      const draftCv = getSnapshot(self.cv);
      if (!self.published_cv) {
        self.published_cv = EmployeeCv.create({ id: -1 }); // eslint-disable-line no-param-reassign
      }
      self.published_cv.update(draftCv);
      self.saveCv({
        id: self.id,
        published_cv: self.published_cv
      });
    },
    updateCv(newCv) {
      const cv = getSnapshot(newCv);
      self.cv.update(cv);
    },
    setOrderNum(num) {
      self.order_num = num; // eslint-disable-line no-param-reassign
    },
    setComment(value) {
      self.comment = value; // eslint-disable-line no-param-reassign
    },
    save: flow(function* save() {
      yield axios.put(
        `/api/v1/propositions/${self.id}`,
        JSON.stringify({ proposition: getSnapshot(self) })
      );
    })
  }));

export default Proposition;
