import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.API_DOMAIN
});

export const setAuthToken = token => {
  console.log('axios::setAuthToken', token); // eslint-disable-line no-console
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeAuthToken = () => {
  delete instance.defaults.headers.common.Authorization;
};

instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.put['Content-Type'] = 'application/json';
instance.defaults.headers.common['Accept'] = 'application/json'; // eslint-disable-line
export default instance;
