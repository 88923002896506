import styled from 'styled-components/macro';
import { BasicButton } from 'components/atoms/button/button-style';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const activeStyles = `
  font-weight: 500;
  background-color: var(--color-background-is-active);
  color: var(--color-primary-dark);
  pointer-events: none;
`;

export const Page = styled(BasicButton)`
  width: 3rem;
  padding-left: 0;
  padding-right: 0;

  :disabled,
  :disabled:hover {
    background-color: transparent;
    color: var(--color-text-light);
  }

  ${({ active }) => active && activeStyles}
`;
