import { types } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';

const Project = types
  .model('Project', {
    name: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    techs: types.optional(types.string, ''),
    id: types.maybeNull(types.string)
  })
  .preProcessSnapshot(snapshot => {
    return {
      id: uuidv4(),
      ...snapshot
    };
  })
  .postProcessSnapshot(snapshot => {
    delete snapshot.id; // eslint-disable-line no-param-reassign
    return snapshot;
  })
  .actions(self => ({
    setName(name) {
      self.name = name; // eslint-disable-line no-param-reassign
    },
    setDescription(description) {
      self.description = description; // eslint-disable-line no-param-reassign
    },
    setTechs(techs) {
      self.techs = techs; // eslint-disable-line no-param-reassign
    }
  }));

export default Project;
