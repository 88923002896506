import { types, resolveIdentifier } from 'mobx-state-tree';

import { Customer } from './customer-model';

export const CustomerList = types
  .model('CustomerList', {
    customers: types.map(Customer)
  })
  .actions(self => ({
    loadCustomer(id) {
      const customer = Customer.create({ id });
      customer.load();
      self.customers.put(customer);
      return customer;
    },
    getCustomer(id) {
      return resolveIdentifier(Customer, self, id) || self.loadCustomer(id);
    }
  }));

export const sortByName = customers => {
  return customers.sort((a, b) => a.name.localeCompare(b.name));
};

export default CustomerList;
