import React from 'react';
import Permission from './permission';
import { rbac } from './rules';

const EmployeeCvReadPermission = props => (
  <Permission perform={rbac.EMPLOYEE_CV_READ} {...props}>
    {props.children}
  </Permission>
);

export default EmployeeCvReadPermission;
