import styled from 'styled-components/macro';
import { keyframes } from 'styled-components';

export const MessageContainer = styled.div`
  position: fixed;
  right: 5rem;
  bottom: 2.75rem;
  min-height: 2.1rem;
  display: flex;
  align-items: center;
  z-index: 9999999;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0s 0.4s;

  &.is-open {
    pointer-events: all;
    transition: none;
    opacity: 1;
  }

  .is-hidden & {
    opacity: 0;
    pointer-events: none;
  }
`;

const popIn = keyframes`
  to {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
`;

const popOut = keyframes`
  from {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
  to {
    opacity: 0;
    transform: translate(0.5rem, 0.5rem) scale(0.6);
  }
`;

// TODO: Used in login.js, refactor

export const Message = styled.div`
  padding: 0.4rem 0.85rem;

  max-width: 10rem;
  width: max-content;

  border-radius: var(--border-radius-xxl);
  background-color: var(--color-primary-lightest);

  transform: translate(0.5rem, 0.5rem) scale(0.6);
  transform-origin: bottom right;

  font-size: var(--font-size-xs);
  font-weight: 100;
  color: var(--color-text-darker);

  opacity: 0;
  z-index: 25002;

  animation: ${popIn} 1s var(--ease-out-expo) 0.2s;
  animation-fill-mode: forwards;

  white-space: pre-wrap;

  &.animate-out {
    animation: ${popOut} 0.3s var(--ease-in-expo);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: -0.1rem;
    height: 1rem;
  }

  &:before {
    border-bottom-left-radius: 0.8rem 0.7rem;
    border-right: 1rem solid var(--color-primary-lightest);
    right: -0.35rem;
    transform: translate(0, -0.1rem);
  }

  &:after {
    background-color: var(--color-background);
    border-bottom-left-radius: 0.5rem;
    right: -40px;
    transform:translate(-30px, -2px);
    width: 10px;
  }
}
`;
