import styled, { keyframes } from 'styled-components/macro';
import Popup from 'reactjs-popup2';
import { breakpointsRefresh as breakpoints } from 'common/styles';
import { IconContainer } from 'components/atoms/icon/icon';

export const slideIn = keyframes`
  0.01% {
    transform:scale(0.8);
    opacity:0;
  }
  100% {
    transform:scale(1);
    opacity:1;
  }
`;

export const StyledPopup = styled(Popup)`
  &-content {
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-xl);
    border: none;
    font-size: var(--font-size-sm);
    background: var(--color-modal);
    border: 1px solid var(--color-text-lightest);
    margin-top: 0.25rem;
    overflow: hidden;
    transform-origin: 50% 0%;
    transform: scale(1);
    opacity: 0;
    animation: ${slideIn} 0.35s var(--ease-out-expo) both;
    margin-left: 2rem;
    ${breakpoints.md} {
      margin-left: 0;
    }
  }
`;

export const Button = styled.button`
  text-align: left;
  margin-top: 0.1rem;
  border: none;
  border-radius: 0;
  background: transparent;
  color: var(--color-text-dark);
  font-weight: 500;
  cursor: pointer;
  width: auto;
  &:hover {
    color: var(--color-text-darker);
  }
  &.is-selected {
    color: var(--color-text-is-active);
    background-color: var(--color-background-is-active);
    border-radius: var(--border-radius-base);
  }
  &:focus {
    outline: none;
  }
`;
export const Contents = styled.div`
  display: flex;
  padding: 1rem;
  > div,
  > div > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const YearsWrapper = styled.div`
  position: relative;
  overflow-y: scroll;
  max-height: calc(1.5rem * 12);

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${IconContainer} {
    --deg: 180deg;
    position: fixed;
    right: 0;
    width: calc(50% - 2rem);
    margin: 0 1rem;

    background: linear-gradient(
      var(--deg),
      var(--color-background) 65%,
      transparent
    );

    &:last-of-type {
      bottom: 1rem;
      --deg: 0deg;
    }
  }
`;

export const Footer = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  background-color: var(--color-background-hint);
`;
