export const breakpoints = {
  xlarge: '1300px',
  large: '1100px',
  medium: '768px',
  smallerMedium: '720px',
  small: '500px',
  tiny: '360px'
};

export default breakpoints;
