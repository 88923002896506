import { flow, types, destroy } from 'mobx-state-tree';
import moment from 'moment';
import axios from '../common/utils/axios';
import notificationStore from '../stores/notificationStore';
import { TimeEntry } from './time-entry-model';

export const calculateTotals = entries => {
  return entries.reduce(
    (acc, e) => {
      return {
        hours: acc.hours + e.hours,
        billing: acc.billing + e.hours * e.assignment.external_price,
        bonus: acc.bonus + e.hours * e.assignment.price
      };
    },
    {
      hours: 0,
      billing: 0,
      bonus: 0
    }
  );
};

const isDayRange = range =>
  // eslint-disable-next-line no-prototype-builtins
  range.hasOwnProperty('from') && range.hasOwnProperty('to');

export const TimeEntriesList = types
  .model('TimeEntriesList', {
    timeentries: types.map(TimeEntry)
  })
  .views(self => ({
    entriesForAssignment(assignmentId, selectedDays) {
      const selectionStart = selectedDays.from;
      const selectionEnd = selectedDays.to;
      const entries = [...self.timeentries.values()].filter(e => {
        const d = moment(e.day);
        const { id } = e.assignment;
        const isSameDay = d.isSame(selectionStart, 'day');
        const isBetween = d.isBetween(selectionStart, selectionEnd);
        return (isBetween || isSameDay) && id === assignmentId;
      });
      return entries;
    },
    entriesForAssignmentInMonth(assignmentId, month) {
      return [...self.timeentries.values()].filter(
        e => month.isSame(e.day, 'month') && e.assignment.id === assignmentId
      );
    },
    entriesForAssignmentInDay(assignmentId, day) {
      return [...self.timeentries.values()].filter(
        e => day.isSame(e.day, 'day') && e.assignment.id === assignmentId
      );
    },
    entries(employee, day = undefined) {
      return [...self.timeentries.values()].filter(entry => {
        const employeeMatch = entry.assignment.employee.id === employee.id;
        const dayMatchOrAll = day === undefined || entry.day.isSame(day, 'day');
        return employeeMatch && dayMatchOrAll;
      });
    },
    entriesInDayRange(employee, dayRange) {
      return [...self.timeentries.values()].filter(e => {
        const employeeMatch = e.assignment.employee.id === employee.id;
        const isSameDay = e.day.isSame(dayRange.from, 'day');
        const isBetween = e.day.isBetween(dayRange.from, dayRange.to);
        return (isBetween || isSameDay) && employeeMatch;
      });
    },
    dayTotalHours(dayOrRange, employee) {
      const entries = (isDayRange(dayOrRange)
        ? self.entriesInDayRange
        : self.entries)(employee, dayOrRange);
      const sum = entries.reduce((total, entry) => total + entry.hours, 0);
      return sum;
    },
    dayTotalProjectHours(day, employee, projectId) {
      const employeeEntries = self.entries(employee, day);
      const projectEntries = employeeEntries.filter(entry => {
        return entry.assignment.project.id === projectId;
      });
      const sum = projectEntries.reduce(
        (total, entry) => total + entry.hours,
        0
      );
      return sum;
    },
    hasEntries(day, employee) {
      const entries = self.entries(employee, day);
      return entries.length > 0;
    },
    monthlyReportedHours(month, assignments) {
      const assignmentIds = assignments.map(a => a.id);
      // Select only the time entries of the given assignments:
      const entriesOfAssignments = [
        ...self.timeentries.values()
      ].filter(entry => assignmentIds.includes(entry.assignment.id));
      const sum = entriesOfAssignments.reduce((total, entry) => {
        const isSameMonth = entry.day.isSame(month, 'month');
        const hours = isSameMonth && entry.hours ? entry.hours : 0;
        return total + hours;
      }, 0);
      return sum;
    },
    monthlyReport(month, employee) {
      const entries = [...self.timeentries.values()].filter(entry => {
        return (
          entry.assignment.employee.id === employee.id &&
          entry.day.isSame(month, 'month')
        );
      });
      return { ...calculateTotals(entries), entries };
    },
    findEntry(id) {
      return self.timeentries.find(t => t.id === id);
    },
    latestEntry(employee) {
      return self
        .entries(employee)
        .sort((a, b) => b.day.valueOf() - a.day.valueOf())[0];
    }
  }))
  .actions(self => ({
    timeEntriesFor: flow(function* timeEntriesFor(assignments) {
      try {
        const { data } = yield axios.get(
          `/api/v1/timeentries?assignments=${JSON.stringify(assignments)}`
        );
        data.forEach(t => self.timeentries.put(t));
      } catch (error) {
        console.log('Error loading time-entries list', error); // eslint-disable-line no-console
      }
      return self;
    }),
    addEntries: flow(function* addEntries(entryData, assignment, msg = true) {
      try {
        const { data } = yield axios.post(
          '/api/v1/timeentries',
          JSON.stringify({ timeentry: entryData })
        );
        const { id } = assignment;
        const dates = entryData.map(e => e.day);
        [...self.timeentries.values()].forEach(t => {
          const day = moment(t.day).format('YYYY-MM-DD');
          const hit = t.assignment.id === id && dates.includes(day);
          if (hit) destroy(t);
        });
        data.forEach(t => self.timeentries.put(t));
        if (msg) notificationStore.queue('Succesfully updated hours');
        assignment.employee.loadSummary();
        return { status: 'success' };
      } catch (error) {
        const errorData = error.response.data;
        const errorHours = errorData[0] && errorData[0].hours;
        let result;
        if (errorHours) {
          if (msg) notificationStore.queue(`Please check input, ${errorHours}`);
          result = { status: 'error', message: errorHours };
        }
        return result;
      }
    }),
    deleteEntries: flow(function* deleteEntries(
      entries,
      assignment,
      notify = true
    ) {
      const d = entries.map(e => e.id);
      try {
        yield axios.delete(
          `/api/v1/timeentries?time_entries=${JSON.stringify(d)}`
        );
        entries.forEach(entry => destroy(entry));
        if (notify) notificationStore.queue('Succesfully updated hours');
        assignment.employee.loadSummary();
        return { status: 'success' };
      } catch (error) {
        if (notify) {
          notificationStore.queue(error.message);
          notificationStore.queue('Could not update hours please check input');
        }
        return {
          status: 'error',
          message: `Check format: ${error.message}`
        };
      }
    })
  }));

export const sortByName = entries => {
  return entries.sort((a, b) =>
    a.assignment.project.name.localeCompare(b.assignment.project.name)
  );
};
export default TimeEntriesList;
