import React, { useState, useEffect } from 'react';

import { observer } from 'mobx-react';
import { DebounceInput } from 'react-debounce-input';
import { Button, Icon, SimpleInput } from 'components/atoms';
import {
  Sidebar,
  Header,
  Item,
  Time,
  Title,
  Description,
  Text,
  SearchContainer,
  AddButtonContainer
} from './log-sidebar-style';

const LogSidebar = observer(
  ({ items, onAddItem, activeItem, setActiveItem, allowAdd }) => {
    const [searchValue, setNewSearchValue] = useState('');
    const [itemsFiltered, setItemsFiltered] = useState([]);

    const filter = (string, arrayOfObjects) => {
      return arrayOfObjects.filter(o =>
        Object.keys(o).some(k => {
          let data = '';
          if (o[k] && o[k].props) {
            data = JSON.stringify(o[k].props);
          } else {
            data = JSON.stringify(o[k]);
          }
          return data.toLowerCase().includes(string.toLowerCase());
        })
      );
    };

    useEffect(() => {
      if (searchValue.length > 2) {
        setItemsFiltered(filter(searchValue, items));
      } else {
        setItemsFiltered(items);
      }
    }, [items, searchValue]);

    return (
      <Sidebar>
        <Header>
          <SearchContainer>
            <SimpleInput
              as={DebounceInput}
              debounceTimeout={300}
              value={searchValue}
              onChange={value => setNewSearchValue(value)}
              placeholder='Search'
            />
          </SearchContainer>

          {allowAdd && (
            <AddButtonContainer>
              <Button onClick={onAddItem}>
                <Icon name='pencil2' />
              </Button>
            </AddButtonContainer>
          )}
        </Header>
        <div>
          {itemsFiltered.map(item => (
            <Item
              key={item.id}
              active={item.id === activeItem}
              done={item.done}
              onClick={() => setActiveItem(item)}
            >
              <Time>{item.last_modified}</Time>
              <Text>
                <Title>{item.sidebarTitle || item.title}</Title>
                <Description>
                  {!!item.description || item.description === ''
                    ? item.description
                    : ''}
                </Description>
              </Text>
            </Item>
          ))}
        </div>
      </Sidebar>
    );
  }
);

LogSidebar.defaultProps = {};

export default LogSidebar;
