import { types } from 'mobx-state-tree';
import Decimal from './types';

export const TimeEntrySummary = types.model('TimeEntry', {
  day: types.maybeNull(types.string),
  project_name: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  hours: types.maybeNull(Decimal),
  comment: types.maybeNull(types.string)
});

export const EmployeeTimesheet = types
  .model('EmployeeTimesheet', {
    capacity: Decimal,
    entries: types.array(TimeEntrySummary)
  })
  .views(self => ({
    get byProject() {
      return Array.from(
        self.entries.reduce((acc, obj) => {
          if (obj.project_name) {
            acc.set(
              obj.project_name,
              (acc.get(obj.project_name) || []).concat(obj.hours || 0)
            );
          }
          return acc;
        }, new Map()),
        project => {
          const name = project[0];
          const sum = project[1].reduce((p, c) => p + parseFloat(c), 0);
          return {
            project: name,
            sum
          };
        }
      );
    },
    get hasEntries() {
      return self.entries.length > 0;
    },
    get total() {
      return self.entries.reduce((acc, obj) => acc + obj.hours, 0);
    }
  }));

export default EmployeeTimesheet;
