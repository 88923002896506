import React, { createContext, useState, useEffect } from 'react';

const debounce = (func, timeout) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const ViewportContext = createContext({});

export const ViewportContextProvider = props => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [breakpoint, setBreakpoint] = useState({
    xs: window.innerWidth >= 576,
    sm: window.innerWidth >= 640,
    md: window.innerWidth >= 768,
    lg: window.innerWidth >= 1024,
    xl: window.innerWidth >= 1280,
    xxl: window.innerWidth >= 1536
  });
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  const debouncedHandleResize = debounce(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    setBreakpoint({
      xs: window.innerWidth >= 576,
      sm: window.innerWidth >= 640,
      md: window.innerWidth >= 768,
      lg: window.innerWidth >= 1024,
      xl: window.innerWidth >= 1280,
      xxl: window.innerWidth >= 1536
    });
  }, 200);

  useEffect(() => {
    setIsTouchDevice(matchMedia('(pointer:coarse)').matches);
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  return (
    <ViewportContext.Provider
      value={{ width, height, breakpoint, isTouchDevice }}
    >
      {props.children}
    </ViewportContext.Provider>
  );
};
