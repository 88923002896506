import styled from 'styled-components/macro';
import { breakpointsRefresh as breakpoints } from 'common/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: var(--sideMargin) 0;

  ${breakpoints.xs} {
    flex-direction: row;
  }
  ${breakpoints.md} {
    justify-content: center;
  }

  ${breakpoints.lg} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 62%;
    max-width: 16rem;
    align-self: flex-start;
    position: sticky;
    top: var(--sideMargin);
  }
`;

export const EmployeeDetails = styled.div`
  margin: 1rem 0 0 0;
  width: 100%;
  max-width: 14rem;
  ${breakpoints.xs} {
    margin: 0 0 0 var(--sideMargin);
  }
  ${breakpoints.lg} {
    margin: var(--sideMargin) 0 0 0;
  }
`;

export const AvatarContainer = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  width: 14rem;
  height: 14rem;
  ${breakpoints.xs} {
    width: 12rem;
    height: 12rem;
  }
  ${breakpoints.lg} {
    margin: 4rem 0 0;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const Badge = styled.span`
  border-radius: var(--border-radius-full);
  background-color: var(--color-primary);
  font-size: var(--font-size-xs);
  color: var(--color-white);
  padding: 0.25rem 0.75rem;
  margin-right: auto;
  line-height: 1;
`;
export const Name = styled.span`
  color: var(--color-text-darker);
  font-size: var(--font-size-xl);
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 1.1;
  margin-bottom: 0.5rem;
`;

export const Label = styled.span`
  color: var(--color-text);
  width: 100%;
  display: block;
  display: grid;
  place-content: center start;
  font-size: var(--font-size-sm);
  margin-top: 0.5rem;
  ${breakpoints.sm} {
    margin-top: 0;
  }
  ${breakpoints.md} {
    margin-top: 0.5rem;
  }
`;

export const Text = styled.span`
  padding: 0.3rem 0;
  color: var(--color-text-darker);
`;
