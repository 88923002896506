import React from 'react';
import PropTypes from 'prop-types';
import {
  createIntl as _createIntl,
  createIntlCache,
  RawIntlProvider
} from 'react-intl';
import localeData from './locale-data';

export const createIntl = locale => {
  return _createIntl(localeData[locale], createIntlCache());
};

const intl = createIntl('fi'); // Setting fi as a default

const IntlContextProvider = props => (
  <RawIntlProvider value={intl}>{props.children}</RawIntlProvider>
);

IntlContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default IntlContextProvider;
