import { useContext } from 'react';
import { AuthContext } from '../../contexts/auth/auth-context';

import rules from './rules';

const check = (rulez, role, action, data) => {
  const permissions = rulez[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

const Can = props => {
  const authContext = useContext(AuthContext);
  const ok = check(rules, authContext.user.role, props.perform, props.data);
  return ok ? props.yes() : props.no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null
};

const canAccess = (role, action, data) => {
  return check(rules, role, action, data);
};

export default Can;
export { Can, canAccess };
