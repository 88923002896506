import React from 'react';
import styled from 'styled-components/macro';
import { FormattedNumber } from 'react-intl';

const handleColor = ({ $negative, $positive, $color }) => {
  switch (true) {
    case Boolean($color):
      return $color;
    case $negative:
      return 'var(--color-error-dark)';
    case $positive:
      return 'var(--color-success)';
    default:
      return 'currentColor';
  }
};

const NumberWrapper = styled.span`
  color: ${handleColor};
  font-weight: ${({ $bold }) => $bold && '700'};
`;

const NumberPrefix = styled.span`
  padding-right: 0.25rem;
`;

const NumberSuffix = styled.span`
  padding-left: 0.25rem;
`;

// TODO: Naming
const Number = ({
  value,
  positive,
  negative,
  both,
  bold,
  prefix = '',
  suffix = '',
  round = 1,
  color,
  ...rest
}) => {
  const val = parseFloat(value) || 0;

  return (
    <NumberWrapper
      $positive={(positive || both) && value > 0}
      $negative={(negative || both) && value < 0}
      $bold={bold}
      $color={color}
    >
      {prefix && <NumberPrefix>{prefix}</NumberPrefix>}
      <FormattedNumber value={val} maximumFractionDigits={round} {...rest} />
      {suffix && <NumberSuffix>{suffix}</NumberSuffix>}
    </NumberWrapper>
  );
};

export const NumberRenderer = opts => value => (
  <Number value={value} {...opts} />
);

export default Number;
