import React from 'react';
import PropTypes from 'prop-types';

import Permission from './permission';
import { rbac } from './rules';

const OfferDetailsCvReadPermission = props => {
  return (
    <Permission perform={rbac.OFFER_DETAILS_CV_READ} {...props}>
      {props.children}
    </Permission>
  );
};

OfferDetailsCvReadPermission.propTypes = {
  children: PropTypes.node.isRequired
};

export default OfferDetailsCvReadPermission;
