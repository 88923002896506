import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CheckboxContainer, Check, Label } from './checkbox-style';

const Checkbox = React.forwardRef(
  ({ checked, disabled, className, id, onChange, size, label }, ref) => {
    const [isChecked, setIsChecked] = useState(checked);
    const attrId = id !== '' ? { id } : {};

    useEffect(() => {
      setIsChecked(checked);
    }, [checked]);

    return (
      <CheckboxContainer
        onClick={() => {
          const value = !isChecked;
          setIsChecked(value);
          onChange(value);
        }}
        onKeyPress={() => {
          const value = !isChecked;
          setIsChecked(value);
          onChange(value);
        }}
        disabled={disabled}
      >
        <Check
          ref={ref}
          className={`${className} ${isChecked ? 'is-checked' : ''}`}
          role='checkbox'
          aria-checked='false'
          tabIndex='0'
          size={size}
        >
          <input {...attrId} type='checkbox' style={{ display: 'none' }} />
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
            <path d='M6,6 v8 h16' fill='none' transform='rotate(-45, 12, 12)' />
          </svg>
        </Check>
        <Label size={size}>
          {label}
          {checked}
        </Label>
      </CheckboxContainer>
    );
  }
);

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  delay: PropTypes.number,
  id: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  className: '',
  color: {},
  delay: 0,
  id: '',
  onChange: () => {},
  size: '1rem'
};

export default Checkbox;
