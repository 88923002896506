import { useEffect, useState } from 'react';
import { addMiddleware } from 'mobx-state-tree';

const separatelyLoadableModel = model => {
  const ready = model.isReady !== undefined;
  const load = model.load !== undefined;
  if (load && !ready) {
    // eslint-disable-next-line no-console, prettier/prettier
    console.warn(
      'Loadable model missing isReady function, but still used in auto-save-editor, behaviour will not be correct'
    );
  }
  return load && ready;
};

const useAutoSaveEditor = model => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 0) {
      setCount(count - 1);
      model.save();
    }
  }, [count]);

  useEffect(() => {
    if (separatelyLoadableModel(model) && !model.isReady()) {
      model.load();
    }
  }, [model.id]);

  useEffect(() => {
    const disposer = addMiddleware(model, (call, next) => {
      if (
        call.name !== '@APPLY_SNAPSHOT' &&
        call.name !== 'save' &&
        call.name !== 'load' &&
        call.name !== 'update' &&
        call.type === 'action'
      ) {
        if (count === 0) {
          setCount(count + 1);
        }
      }
      next(call);
    });
    return () => {
      disposer();
    };
  }, [model]);
  return count;
};

export default useAutoSaveEditor;
