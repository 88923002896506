import {
  types,
  flow,
  getSnapshot,
  destroy,
  detach,
  applySnapshot,
  resolveIdentifier
} from 'mobx-state-tree';
import moment from 'moment';
import { withValidations } from 'mst-validatejs';

import Decimal, { MomentType, LazyEmployee } from './types';
import { Employee } from './employee-model';
import { Tag } from './tag-list-model';
import Proposition from './proposition-model';
import axios from '../common/utils/axios';

export const sortByOrderNum = propositions => {
  return propositions.sort((a, b) => a.order_num - b.order_num);
};

export const Deal = types
  .model('Deal', {
    id: types.identifierNumber,
    name: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    value: types.optional(Decimal, 35000),
    hourly_price: types.optional(Decimal, 85),
    start: types.optional(MomentType, moment()),
    end: types.optional(MomentType, moment().add(3, 'months')),
    closed: types.optional(types.boolean, false),
    contact: types.union(types.maybeNull(LazyEmployee), Employee),
    propositions: types.array(Proposition),
    updated_at: types.optional(MomentType, moment()),
    collapsed: types.optional(types.boolean, true),
    tags: types.map(Tag),
    customer: types.maybeNull(types.string),
    state: types.maybeNull(
      types.enumeration(['pending', 'ready', 'error']),
      'pending'
    )
  })
  .extend(
    withValidations({
      name: {
        length: { minimum: 1, message: 'name is required' }
      },
      description: {
        length: { minimum: 1, message: 'description is required' }
      },
      start: {
        validDateRange: true
      },
      end: {
        validDateRange: true
      },
      expires: {
        validDateRange: true
      },
      contact: {
        presence: true
      },
      hourly_price: {
        numericality: true
      },
      value: {
        numericality: true
      }
    })
  )
  .postProcessSnapshot(snapshot => {
    // eslint-disable-next-line no-param-reassign
    snapshot.contact_id =
      typeof snapshot.contact === 'object'
        ? snapshot.contact?.id
        : snapshot.contact;
    delete snapshot.id; // eslint-disable-line no-param-reassign
    delete snapshot.state; // eslint-disable-line no-param-reassign
    delete snapshot.contact; // eslint-disable-line no-param-reassign
    delete snapshot.propositions; // eslint-disable-line no-param-reassign
    return snapshot;
  })
  .views(self => ({
    isReady() {
      return self.state === 'ready';
    },
    allPropositions() {
      return [...self.propositions];
    },
    propositionById(id) {
      const p = resolveIdentifier(Proposition, self, id);
      if (p) {
        return p;
      }
      return null;
    },
    propositionByEmployeeId(id) {
      const proposition = self.propositions.find(p => p.employee_id === id);
      if (proposition) {
        return proposition;
      }
      return null;
    },
    hasTag(tagId) {
      return self.tags.has(tagId);
    },
    allTags() {
      return [...self.tags.values()];
    }
  }))
  .actions(self => ({
    load: flow(function* load(id) {
      const { data } = yield axios.get(`/api/v1/deals/${id}`);
      self.id = data.id; // eslint-disable-line no-param-reassign
      self.name = data.name; // eslint-disable-line no-param-reassign
      self.description = data.description; // eslint-disable-line no-param-reassign
      self.contact = data.contact; // eslint-disable-line no-param-reassign
      self.start = data.start; // eslint-disable-line no-param-reassign
      self.end = data.end; // eslint-disable-line no-param-reassign
      self.closed = data.closed; // eslint-disable-line no-param-reassign
      self.updated_at = data.updated_at; // eslint-disable-line no-param-reassign
      self.value = data.value; // eslint-disable-line no-param-reassign
      self.hourly_price = data.hourly_price; // eslint-disable-line no-param-reassign
      self.collapsed = true; // eslint-disable-line no-param-reassign
      self.customer = data.customer; // eslint-disable-line no-param-reassign
      self.propositions = data.propositions; // eslint-disable-line no-param-reassign
    }),
    setName(name) {
      self.name = name; // eslint-disable-line no-param-reassign
    },
    setDescription(description) {
      self.description = description; // eslint-disable-line no-param-reassign
    },
    setContact(contact) {
      self.contact = contact; // eslint-disable-line no-param-reassign
    },
    setStartDate(date) {
      self.start = date; // eslint-disable-line no-param-reassign
    },
    setEndDate(date) {
      self.end = date; // eslint-disable-line no-param-reassign
    },
    setValue(value) {
      self.value = value; // eslint-disable-line no-param-reassign
    },
    setHourlyPrice(value) {
      self.hourly_price = value; // eslint-disable-line no-param-reassign
    },
    setCustomer(name) {
      self.customer = name; // eslint-disable-line no-param-reassign
    },
    setCollapse(flag) {
      self.collapsed = flag; // eslint-disable-line no-param-reassign
    },
    save: flow(function* save() {
      const deal = getSnapshot(self);
      if (self.id === -1) {
        const { data } = yield axios.post(
          '/api/v1/deals',
          JSON.stringify(deal)
        );
        self.id = data.id; // eslint-disable-line no-param-reassign
        self.url = data.url; // eslint-disable-line no-param-reassign
      } else {
        yield axios.put(`/api/v1/deals/${self.id}`, JSON.stringify(deal));
      }
      return self;
    }),
    loadTags: flow(function* loadTags() {
      try {
        const params = { model_type: 'deal', model_id: self.id };
        const { data } = yield axios.get('/api/v1/tags', { params });
        data.map(tag => self.tags.put(tag));
      } catch (error) {
        console.error('Failed to load deal tags', error); // eslint-disable-line no-console
      }
    }),
    toggleTag: flow(function* toggleTag(tagId) {
      try {
        const params = { model_type: 'deal', model_id: self.id };
        const { data } = yield axios.put(
          `/api/v1/tags/${tagId}/toggle`,
          params
        );
        if (self.hasTag(tagId)) {
          destroy(self.tags.get(tagId));
        } else {
          self.tags.put(data);
        }
      } catch (error) {
        console.error('Could not toggle tag', error); // eslint-disable-line no-console
      }
      return self;
    }),

    updateCustomer: flow(function* updateCustomer(id) {
      try {
        const { data } = yield axios.put(
          `/api/v1/deals/${self.id}`,
          JSON.stringify({ customer_id: id })
        );
        self.setCustomer(data.customer);
      } catch (err) {
        console.log('Error updating deal customer id', err); // eslint-disable-line no-console
      }
    }),

    addOrUpdatePropositions: flow(function* addOrUpdatePropositions(
      propositionsData
    ) {
      const currentIds = self.propositions.map(p => p.employee_id);
      const currentOrderNumbers = self.propositions.map(p => p.order_num);
      const nextNumber = Math.max(...currentOrderNumbers, 0) + 1;

      const propositions = [];

      propositionsData.forEach((p, index) => {
        const { cv, employee_id } = p;
        if (!currentIds.includes(employee_id)) {
          propositions.push({
            employee_id,
            order_num: nextNumber + index,
            cv
          });
        } else if (cv && currentIds.includes(employee_id)) {
          const targetProposition = self.propositionByEmployeeId(employee_id);
          const { id } = targetProposition;
          targetProposition.updateCv(cv);
          propositions.push({
            id,
            cv
          });
        }
      });

      const { tags } = self;

      const deal = {
        deal: {
          deal_propositions_attributes: [...propositions]
        }
      };

      const { data } = yield axios.put(
        `/api/v1/deals/${self.id}/`,
        JSON.stringify(deal)
      );

      applySnapshot(self, { ...data, tags });
    }),

    removeProposition: flow(function* removeProposition(proposition) {
      yield axios.delete(
        `/api/v1/deals/${self.id}/employees/${proposition.employee_id}`
      );
      destroy(proposition);
    }),
    reorderPropositions: flow(function* reorderPropositions(
      startIndex,
      endIndex
    ) {
      self.propositions.splice(
        endIndex,
        0,
        detach(self.propositions[startIndex])
      );

      self.propositions.forEach((p, index) => {
        p.setOrderNum(index);
      });

      const offer = {
        offer: { propositions_attributes: self.propositions }
      };

      yield axios.put(`/api/v1/offers/${self.id}`, JSON.stringify(offer));
      return self;
    })
  }));

export default Deal;
