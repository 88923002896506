import React, { useContext } from 'react';
import styled from 'styled-components/macro';

import { AuthContext } from 'contexts';
import { canAccess } from 'common/rbac';

import NavListItem from './nav-list-item';

export const Section = styled.div`
  margin-bottom: 1.25rem;
`;

export const SectionTitle = styled.div`
  color: var(--color-text-dark);
  padding: 0 0.75rem;
  font-size: var(--font-size-xs);
  line-height: 1rem;
`;

const NavSection = props => {
  const { section, currentPath } = props;
  const { user, settings } = useContext(AuthContext);
  const { disabled_features = [] } = settings;

  const allowedItems = section.items.filter(
    item =>
      canAccess(user.role, item.rbac) && !disabled_features.includes(item.text)
  );

  return (
    <Section key={section.title}>
      {section.showTitle && allowedItems.length > 1 ? (
        <SectionTitle>{section.title}</SectionTitle>
      ) : (
        ''
      )}

      {allowedItems.map(item => {
        // Use modified path as hacky solution to highlight hours in overview/refresh path
        const currentPathModified = currentPath
          .replace('overview', 'hours')
          .replace('refresh', 'hours');
        const selected =
          currentPathModified.includes(item.url) &&
          // Disable highlighting in third level of /employees/
          !(
            currentPath.split('/').filter(Boolean).length >= 3 &&
            currentPath.includes('employees')
          );
        return (
          <NavListItem
            onClick={props.onNavigation}
            icon={item.icon}
            // TODO: This needs work...
            text={
              allowedItems.length === 1 && section.showTitle
                ? section.title
                : item.text
            }
            link={item.url}
            selected={selected}
            key={item.url}
            tabIndex={-1}
          />
        );
      })}
    </Section>
  );
};

export default NavSection;
