import React from 'react';
import PropTypes from 'prop-types';

import Permission from './permission';
import { rbac } from './rules';

const OfferDetailsReadPermission = props => {
  return (
    <Permission perform={rbac.OFFER_DETAILS_READ} {...props}>
      {props.children}
    </Permission>
  );
};

OfferDetailsReadPermission.propTypes = {
  children: PropTypes.node.isRequired
};

export default OfferDetailsReadPermission;
