import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  ButtonContainer,
  CancelButton,
  ConfirmButton
} from './modal-confirm-dialog-style';

const ModalConfirmDialog = props => {
  const {
    cancelAction,
    confirmAction,
    heading,
    children,
    cancelLabel,
    confirmLabel,
    confirmDisabled
  } = props;

  return (
    <Container>
      <h4>{heading}</h4>
      <div style={{ width: '100%', textAlign: 'center' }}>{children}</div>
      <ButtonContainer>
        <CancelButton icon={null} onClick={() => cancelAction()}>
          {cancelLabel}
        </CancelButton>
        <ConfirmButton
          icon={null}
          onClick={() => confirmAction()}
          disabled={confirmDisabled}
        >
          {confirmLabel}
        </ConfirmButton>
      </ButtonContainer>
    </Container>
  );
};

ModalConfirmDialog.defaultProps = {
  heading: 'Are you sure?',
  children: null,
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  confirmDisabled: false
};

ModalConfirmDialog.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  confirmAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  confirmDisabled: PropTypes.bool
};

export default ModalConfirmDialog;
