import styled from 'styled-components/macro';

export const ColorPickerContainer = styled.div`
  display: flex;
  height: 7.5rem;
  overflow: hidden;
  border-radius: var(--border-radius-base);
  background-color: var(--color-modal);
  color: var(--color-text-dark);
  box-shadow: var(--shadow-sm);

  touch-action: none;
`;

export const SaturationContainer = styled.div`
  position: relative;
  flex-basis: 45%;
`;

export const SecondaryContainer = styled.div`
  position: relative;
  flex-basis: 55%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0.75rem 1rem;

  h4 {
    font-size: var(--font-size-sm);
    margin: 0;
  }
`;

export const ColorCode = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: var(--border-radius-xs);

  background-color: ${({ color }) => color};
`;

export const SliderContainer = styled.div`
  position: relative;
  height: 0.75rem;

  border-radius: 0.375rem;
  overflow: hidden;
`;

export const RoundPicker = styled.div`
  background-color: transparent;
  border: 2px solid var(--color-white);
  border-radius: 50%;

  height: 0.75rem;
  width: 0.75rem;
`;
