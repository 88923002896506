import React, { useEffect, useContext } from 'react';
import moment from 'moment';
import { DebounceInput } from 'react-debounce-input';
import { observer } from 'mobx-react';

import { Button, Icon, SyncStatus } from 'components/atoms';
import { SyncContext } from 'contexts';

import {
  Container,
  Title,
  Input,
  Textarea,
  Header,
  Backlink,
  DeleteButtonContainer,
  Time,
  MetaContainer,
  MetaItem,
  MetaTitle,
  MetaValue,
  Body
} from './log-main-style';

const LogMain = observer(
  ({
    item,
    allowDelete,
    onDeleteItem,
    onNavigateBack,
    editableFields,
    onEditItem
  }) => {
    const { sync } = useContext(SyncContext);

    useEffect(() => {
      if (item && item.status) {
        sync.set(`Last updated ${item.status}`);
      }
    }, [item]);

    const handleInputValue = (type, value) => {
      switch (type) {
        case 'date':
          return value ? moment(value).format('YYYY-MM-DD') : null;
        case 'number':
          return +value;
        default:
          return value;
      }
    };

    return (
      <Container>
        {item ? (
          <>
            <Backlink onClick={onNavigateBack}>
              <Icon name='arrowLeft' />
              <span>All entries</span>
            </Backlink>
            <Header>
              <Title>
                <Input
                  as={DebounceInput}
                  debounceTimeout={1000}
                  type='text'
                  value={item.title}
                  disabled={!editableFields.includes('title')}
                  autoFocus={!item.title}
                  placeholder={item.titlePlaceholder || ''}
                  onChange={e => {
                    onEditItem({
                      field: 'title',
                      value: e.target.value,
                      item
                    });
                  }}
                />
              </Title>
              {item.status && (
                <Time>
                  <SyncStatus />
                </Time>
              )}
            </Header>
            {allowDelete && (
              <DeleteButtonContainer>
                <Button onClick={() => onDeleteItem(item)}>
                  <Icon name='trash' />
                </Button>
              </DeleteButtonContainer>
            )}
            {item.meta && (
              <MetaContainer>
                {item.meta.map(({ title, value, type, placeholder }) => (
                  <MetaItem key={title}>
                    <MetaTitle>{title}</MetaTitle>
                    <MetaValue>
                      <Input
                        as={DebounceInput}
                        debounceTimeout={1000}
                        type={type}
                        value={handleInputValue(type, value)}
                        disabled={!editableFields.includes(title)}
                        placeholder={placeholder}
                        onChange={e => {
                          onEditItem({
                            field: title,
                            value: handleInputValue(type, e.target.value),
                            item
                          });
                        }}
                      />
                    </MetaValue>
                  </MetaItem>
                ))}
              </MetaContainer>
            )}

            <Body>
              {typeof item.body === 'string' ||
              (typeof item.body === 'object' && item.body === null) ? (
                <Textarea
                  as={DebounceInput}
                  element='textarea'
                  debounceTimeout={1000}
                  placeholder={item.bodyPlaceholder || ''}
                  value={item.body}
                  disabled={!editableFields.includes('body')}
                  onChange={e => {
                    onEditItem({
                      field: 'body',
                      value: e.target.value,
                      item
                    });
                  }}
                />
              ) : (
                item.body
              )}
            </Body>
          </>
        ) : (
          <>No items</>
        )}
      </Container>
    );
  }
);

export default LogMain;
