import styled, { keyframes } from 'styled-components/macro';
import Popup from 'reactjs-popup2';
import { breakpointsRefresh } from 'common/styles';

const breakpoints = breakpointsRefresh;

export const slideIn = keyframes`
  0% {
    transform:translateY(3%);
    opacity:0;
  }
  100% {
    transform:translateY(0);
    opacity:1;
  }
`;

export const StyledPopup = styled(Popup)`
  &-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 1rem;
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    overflow-y: auto;
    background-color: transparent;
    border-radius: var(--border-radius-lg);
    box-shadow: 1px 10px 40px rgba(0, 0, 0, 0.13);
    ${breakpoints.md} {
      position: absolute !important;
      z-index: 999999999999 !important;
      top: 0;
      left: 0;
      right: auto;
      bottom: auto;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    background-color: var(--color-background);
    margin: 0 !important;
    border-radius: var(--border-radius-lg);
    transform-origin: 50% 0%;
    animation: ${slideIn} 0.6s var(--ease-out-expo) both;
    width: 100%;
  }
`;

export const MegaModalTopbar = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 0;
  align-items: center;
  border-bottom: 1px solid var(--color-text-lightest);
  background-color: var(--color-background);
`;

export const MegaModalTitle = styled.h1`
  width: 100%;
  color: var(--color-text-darker);
  font-weight: 800;
  font-size: var(--font-size-lg);
  line-height: 1.05;
  margin: 0 0.75rem;
  ${breakpoints.lg} {
    font-size: var(--font-size-xl);
  }
  ${breakpoints.xl} {
    margin: 0 1.5rem;
  }
`;

export const MegaModalContent = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const MegaModalClose = styled.div`
  display: grid;
  place-content: center;
  padding: 0.75rem;
  cursor: pointer;
  color: var(--color-text-darker);
  ${breakpoints.xl} {
    padding: 1.5rem;
  }
`;
