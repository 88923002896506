import {
  types,
  flow,
  destroy,
  getSnapshot,
  applySnapshot
} from 'mobx-state-tree';
import axios from '../common/utils/axios';

import { NullStringType } from './types';

const categories = {
  skill: 'Skill',
  role: 'Role',
  industry: 'Industry',
  'service area': 'Service area'
};
const keys = Object.keys(categories);

export const Tag = types
  .model('Tag', {
    id: types.identifierNumber,
    name: types.optional(NullStringType),
    category: types.optional(types.enumeration(keys), keys[0]),
    category_plural: types.maybeNull(types.string),
    count: types.optional(types.integer, 0),
    deleted: types.optional(types.boolean, false)
  })
  .actions(self => ({
    setName(name) {
      self.name = name; // eslint-disable-line no-param-reassign
    },
    setCategory(category) {
      self.category = category; // eslint-disable-line no-param-reassign
    },
    removeOrRestore: flow(function* remove() {
      try {
        if (self.deleted) {
          // Trying to create with same name restores tag
          yield axios.post('/api/v1/tags', JSON.stringify(getSnapshot(self)));
          self.deleted = false; // eslint-disable-line no-param-reassign
        } else {
          yield axios.delete(`/api/v1/tags/${self.id}`);
          self.deleted = true; // eslint-disable-line no-param-reassign
        }
      } catch (error) {
        console.log('Error removing tag', self, error); // eslint-disable-line no-console
      }
      return self;
    }),
    update(data) {
      applySnapshot(self, { ...data });
    }
  }));

export const TagList = types
  .model('TagList', {
    tags: types.map(Tag)
  })
  .views(self => ({
    ofCategory(category) {
      return [...self.tags.values()].filter(t =>
        [].concat(category).includes(t.category)
      );
    },
    groupedByCategory(tags = false) {
      return (tags || [...self.tags.values()]).reduce((r, t) => {
        r[t.category_plural] = [...(r[t.category_plural] || []), t]; // eslint-disable-line no-param-reassign
        return r;
      }, {});
    },
    sortedForIds(ids) {
      return ids
        .map(id => self.tags.get(id))
        .filter(Boolean)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    tagCategories() {
      return categories;
    },
    get(id) {
      return self.tags.get(id);
    },
    all() {
      return [...self.tags.values()];
    },
    filteredAndGroupedByCategory(filter) {
      const filteredTags = [...self.tags.values()].filter(tag =>
        tag.name.toLowerCase().includes(filter.toLowerCase())
      );
      return self.groupedByCategory(filteredTags);
    },
    filteredByIdsAndGroupedByCategory(ids) {
      const filteredTags = [...self.tags.values()].filter(tag =>
        ids.includes(tag.id)
      );
      return self.groupedByCategory(filteredTags);
    }
  }))
  .actions(self => ({
    load: flow(function* load(usedOnly = false) {
      if (usedOnly) self.tags.clear();
      try {
        const params = { used_only: usedOnly };
        const { data } = yield axios.get('/api/v1/tags', { params });
        self.createTagObjects(data);
      } catch (error) {
        console.log('Error loading tag list', error); // eslint-disable-line no-console
      }
      return self;
    }),
    create: flow(function* create(tag) {
      const { data } = yield axios.post('/api/v1/tags', JSON.stringify(tag));
      self.tags.put(data);
      return data;
    }),
    initTag() {
      return Tag.create({ id: -1 });
    },
    cancel(assignment) {
      destroy(assignment);
    },
    delete: flow(function* _delete(assignment) {
      try {
        yield axios.delete(`/api/v1/assignments/${assignment.id}`);
        destroy(assignment);
      } catch (error) {
        console.log('Could not delete assignment', assignment, error); // eslint-disable-line no-console
      }
    }),
    createTagObjects(data) {
      data.forEach(tag => {
        const existing = self.tags.get(tag.id);
        if (existing) {
          existing.update(tag);
        } else {
          self.tags.put(tag);
        }
      });
    }
  }));

export default TagList;
