import React, { useState, useEffect } from 'react';

import { IconToggle as Toggle } from 'components/atoms';

const DarkModeSwitch = () => {
  const [theme, setTheme] = useState(false);
  const root = document.querySelector('html');
  useEffect(() => {
    if (
      localStorage.theme === 'dark' ||
      (!('theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      root.classList.add('dark');
      setTheme('dark');
    } else {
      root.classList.remove('dark');
      setTheme('light');
    }
  }, []);

  const switchTheme = () => {
    if (root.classList.contains('dark')) {
      localStorage.theme = 'light';
      setTheme('light');
    } else {
      localStorage.theme = 'dark';
      setTheme('dark');
    }
    root.classList.toggle('dark');
  };

  return (
    <div>
      <Toggle
        onClick={() => switchTheme()}
        condition={theme === 'dark'}
        iconTrue='sun'
        iconFalse='moon'
      />
    </div>
  );
};

export default DarkModeSwitch;
