import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react';

import { DerpContext } from 'contexts';

import { Button, Popup } from 'components/atoms';
import { NotificationList } from '../';

import {
  NotificationsWrapper,
  NotificationTrigger,
  NotificationIndicator,
  NotificationListWrapper,
  SettingsBar,
  NewCount
} from './notification-display-style';

const NotificationDisplay = observer(() => {
  const { notifications } = useContext(DerpContext);

  useEffect(() => {
    notifications.load();
  }, []);

  const unreadCount = notifications.unread().length;

  const markAllRead = () => {
    if (unreadCount) {
      notifications.markAllRead();
    }
  };

  return (
    <NotificationsWrapper>
      <Popup
        closeOnDocumentClick
        position='bottom left'
        trigger={
          <NotificationTrigger>
            <Button
              transparent
              icon={unreadCount > 0 ? 'bell2' : 'bell'}
              iconSize='var(--font-size-base)'
              color='var(--color-text-darker)'
            />
            {unreadCount > 0 && <NotificationIndicator />}
          </NotificationTrigger>
        }
      >
        <NotificationListWrapper>
          <SettingsBar>
            <div>
              Notifications
              {unreadCount > 0 ? <NewCount>{unreadCount} new</NewCount> : null}
            </div>
            <div>
              <Button
                transparent
                icon='checkCircled'
                iconSize='var(--font-size-base)'
                title='Mark all as read'
                onClick={markAllRead}
              />
              {/* <Button
                transparent
                icon='settings'
                iconSize='var(--font-size-base)'
              /> */}
            </div>
          </SettingsBar>
          <NotificationList />
        </NotificationListWrapper>
      </Popup>
    </NotificationsWrapper>
  );
});

export default NotificationDisplay;
