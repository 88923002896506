import React, { useContext, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { canAccess, rbac } from 'common/rbac';
import { useEmployee, useScrollPosition } from 'common/hooks';
import { DerplingContextProvider, ViewportContext } from 'contexts';

import { Draggable } from 'components/atoms';
import { DerplingPopupsContainer } from '../';

import DerplingButton from './derpling-button/derpling-button';
import DerplingMessage from './derpling-message/derpling-message';
import { DerplingContainer } from './derpling-style';

const MIN_THRESHOLD = 48;

const Derpling = () => {
  const { breakpoint } = useContext(ViewportContext);
  const { role } = useEmployee();
  const [hide, setHide] = useState(false);
  const derplingRef = useRef();
  const threshold = Math.max(window.innerWidth * 0.1, MIN_THRESHOLD);

  const onDragEnd = ({ x, y }) => {
    derplingRef.current.style.transform = `translate(${x * 3}px, ${y * 3}px)`;
    derplingRef.current.style.opacity = 0;
    derplingRef.current.style.transition = 'all 0.3s ease-out';
    derplingRef.current.style.pointerEvents = 'none';
  };

  useScrollPosition(
    ({ current }) => {
      setHide(
        !breakpoint.lg &&
          current.direction === 'down' &&
          current.distance > MIN_THRESHOLD
      );
    },
    [breakpoint.lg]
  );

  return (
    <DerplingContextProvider>
      <DerplingContainer
        className={hide ? 'is-hidden' : 'is-visible'}
        ref={derplingRef}
      >
        <Draggable onDragEnd={onDragEnd} threshold={threshold}>
          <DerplingButton />
          <DerplingMessage />
          {canAccess(role, rbac.FEEDBACK_READ) && <DerplingPopupsContainer />}
        </Draggable>
      </DerplingContainer>
    </DerplingContextProvider>
  );
};

export default observer(Derpling);
