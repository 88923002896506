import styled, { keyframes } from 'styled-components/macro';
import Popup from 'reactjs-popup2';

// TODO: Copied from workhistory-style.js -> refactor

const overlayIn = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;

const modalIn = keyframes`
  0% {
    transform:scale(0.92);
    opacity:0;
  }
  100% {
    transform:scale(1);
    opacity:0.9;
  }
`;

const Modal = styled(Popup)`
  &-overlay {
    position: relative;
    animation: ${overlayIn} 0.3s var(--ease-out-expo) both;
    z-index: 9999999999 !important;
    &:before {
      z-index: 9999999999 !important;
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: var(--color-background);
      opacity: 0.9;
    }
  }
  &-content {
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-xl);
    z-index: 9999999999 !important;
    border: none;
    font-size: var(--font-size-sm);
    background: var(--color-modal);
    border: 1px solid var(--color-text-lightest);
    margin-top: 0.25rem;
    overflow: hidden;
    transform-origin: 50% 0%;
    animation: ${modalIn} 0.3s var(--ease-out-expo) both;
  }
`;

export default Modal;
