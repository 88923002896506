import { useRef, useLayoutEffect } from 'react';

const getScroll = (ref, changePosition) => {
  const prev = ref.current || { position: 0, direction: 'down' };

  const position = window.scrollY;
  const direction = Math.abs(prev.position) > position ? 'up' : 'down';
  if (direction !== prev.direction) {
    changePosition.current = position; // eslint-disable-line no-param-reassign
  }
  const distance = Math.abs(position - changePosition.current);

  return { direction, distance, position };
};

const useScrollPosition = (effect, deps, delay) => {
  const changePosition = useRef(window.scrollY);
  const position = useRef(getScroll({}, changePosition));

  let throttleTimeout = null;

  const callBack = () => {
    const current = getScroll(position, changePosition);
    effect({ previous: position.current, current });
    position.current = current;
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (delay) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, delay);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, deps);
};

export default useScrollPosition;
