import {
  flow,
  types,
  applySnapshot,
  getParent,
  getSnapshot
} from 'mobx-state-tree';
import axios from '../common/utils/axios';
import Decimal, { MomentType } from './types';

const Checklist = types.model('Checklist', {
  title: types.string,
  deadline: types.maybeNull(MomentType),
  deadline_target: types.optional(types.string, ''),
  deadline_leeway: types.optional(types.string, ''),
  items: types.optional(
    types.array(
      types
        .model({
          name: types.string,
          type: types.string,
          description: types.maybeNull(types.string, ''),
          completed: types.maybeNull(types.boolean, false),
          comment: types.maybeNull(types.string, ''),
          boolean: types.maybeNull(types.boolean),
          link_field: types.maybeNull(types.string, ''),
          link_type: types.maybeNull(types.string, ''),
          link_mapping: types.maybeNull(types.string, ''),
          linked_items: types.optional(
            types.array(types.union(types.string, Decimal)),
            []
          )
        })
        .actions(self => ({
          set(key, value) {
            // eslint-disable-next-line no-prototype-builtins
            if (self.hasOwnProperty(key)) {
              self[key] = value; // eslint-disable-line no-param-reassign
            }
          }
        }))
    ),
    []
  )
});

export const EmployeeOnboarding = types
  .model('EmployeeOnboarding', {
    id: types.number,
    next_deadline: types.maybeNull(MomentType),
    checklist: types.optional(types.array(Checklist), []),
    update_available: types.optional(types.boolean, false)
  })
  .views(self => ({
    getChecklist() {
      return self.onboarding.checklist.groups();
    }
  }))
  .actions(self => ({
    load: flow(function* load() {
      if (self.id !== -1) {
        return self; // Already loaded
      }
      try {
        const employee = getParent(self);
        const { data } = yield axios.get(
          `/api/v1/employees/${employee.id}/onboardings`
        );

        // Test for presence
        if (data.id) {
          employee.setOnboarding(EmployeeOnboarding.create(data));
        }
      } catch (error) {
        console.error('Failed to fetch onboarding', error); // eslint-disable-line no-console
      }
      return self;
    }),
    create: flow(function* create() {
      try {
        const employee = getParent(self);
        const { data } = yield axios.post(
          `/api/v1/employees/${employee.id}/onboardings`
        );
        getParent(self).setOnboarding(EmployeeOnboarding.create(data));
      } catch (error) {
        console.error('Error creating onboarding for employee', error); // eslint-disable-line no-console
      }
      return self;
    }),
    reset: flow(function* reset() {
      try {
        const employee = getParent(self);
        const { data } = yield axios.post(
          `/api/v1/employees/${employee.id}/onboardings/${self.id}/reset`
        );
        applySnapshot(self, data);
      } catch (error) {
        console.error('Failed to reset onboarding', error); // eslint-disable-line no-console
      }
      return self;
    }),
    save: flow(function* save() {
      try {
        const employee = getParent(self);
        const onboarding = getSnapshot(self);
        const { data } = yield axios.put(
          `/api/v1/employees/${employee.id}/onboardings/${self.id}`,
          JSON.stringify({ onboarding })
        );
        applySnapshot(self, data);
      } catch (error) {
        console.error('Onboarding update failed', error); // eslint-disable-line no-console
      }
      return self;
    })
  }));

export default EmployeeOnboarding;
