import React from 'react';
import Permission from './permission';
import { rbac } from './rules';

const ProjectDetailsReadPermission = props => (
  <Permission perform={rbac.PROJECT_DETAILS_READ} data={props.data}>
    {props.children}
  </Permission>
);

export default ProjectDetailsReadPermission;
