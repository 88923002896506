import { types } from 'mobx-state-tree';
import { withValidations } from 'mst-validatejs';

const ContactPerson = types
  .model('ContactPerson', {
    name: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    phone: types.optional(types.string, ''),
    avatar: types.maybeNull(types.string, '')
  })
  .extend(
    withValidations({
      name: {
        length: { minimum: 1, message: 'name is required' }
      },
      email: {
        length: { minimum: 1, message: 'email is required' }
      },
      phone: {
        length: { minimum: 1, message: 'phone is required' }
      }
    })
  )
  .actions(self => ({
    setName(name) {
      self.name = name; // eslint-disable-line no-param-reassign
    },
    setEmail(email) {
      self.email = email; // eslint-disable-line no-param-reassign
    },
    setPhone(phone) {
      self.phone = phone; // eslint-disable-line no-param-reassign
    },
    setAvatar(avatar) {
      self.avatar = avatar; // eslint-disable-line no-param-reassign
    }
  }));

export default ContactPerson;
