import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Number from '../number/number';

const Currency = ({ currency, ...rest }) => {
  const { currency: local } = useIntl();

  return (
    // eslint-disable-next-line react/style-prop-object
    <Number style='currency' round={2} currency={currency || local} {...rest} />
  );
};

export const CurrencyRenderer = highlight => value => (
  <Currency value={value} {...(highlight && { [highlight]: true })} />
);

export default Currency;

Currency.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  round: PropTypes.number
};

Currency.defaultProps = {
  value: 0,
  round: 2
};
