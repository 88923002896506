import React from 'react';
import styled from 'styled-components/macro';
import { Link as RouterLink } from 'react-router-dom';

import { breakpointsRefresh as breakpoints } from 'common/styles';
import { Icon } from 'components/atoms';

const Link = styled(RouterLink)`
  display: flex;
  color: var(--color-text-darker);
  font-weight: 800;
  margin: 0 0.75rem;
  align-items: center;
  text-decoration: none;
  &:hover {
    color: var(--color-text-darkest);
    text-decoration: none;
  }
  font-size: var(--font-size-base);
  ${breakpoints.md} {
    font-size: var(--font-size-lg);
  }
  ${breakpoints.lg} {
    font-size: var(--font-size-xl);
    margin: 0 2rem;
  }
  ${breakpoints.xl} {
    font-size: var(--font-size-2xl);
  }
  & span {
    margin-left: 0.35em;
    line-height: 1;
    font-size: var(--font-size-base);
    ${breakpoints.md} {
      font-size: var(--font-size-lg);
    }
    ${breakpoints.lg} {
      font-size: var(--font-size-xl);
    }
  }
`;

const BackLink = ({ to, label }) => (
  <Link to={to}>
    <Icon name='arrowLeft' size='1em' />
    <span>{label}</span>
  </Link>
);

export default BackLink;
