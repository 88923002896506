import styled from 'styled-components/macro';
import { breakpointsRefresh } from 'common/styles';

const breakpoints = breakpointsRefresh;

export const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  flex-shrink: 0;
  ${breakpoints.md} {
    height: 4rem;
  }

  h1,
  h2,
  h3,
  h4 {
    font-size: var(--font-size-xl);
    color: var(--color-primary-darkest);
    text-transform: none;
    margin-bottom: 0;
  }
`;
export const HeaderSection = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  &:first-of-type {
    z-index: 150;
  }
  &:last-of-type {
    margin-top: 0.5rem;
  }
  ${breakpoints.md} {
    width: auto;
    border-top: none;
    justify-content: flex-end;
    &:last-of-type {
      margin-top: 0;
    }
  }
`;
