import React, { useContext } from 'react';
import { AuthContext } from 'contexts';

const Logout = () => {
  const authCtx = useContext(AuthContext);

  return <div onClick={() => authCtx.logout()}>Log out</div>;
};

export default Logout;
