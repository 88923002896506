import { types } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';
import { withValidations } from 'mst-validatejs';

const Language = types
  .model('Language', {
    id: types.maybeNull(types.string),
    name: types.optional(types.string, ''),
    level: types.optional(
      types.enumeration(['basic', 'fluent', 'native']),
      'basic'
    )
  })
  .preProcessSnapshot(snapshot => {
    return {
      id: uuidv4(),
      ...snapshot
    };
  })
  .postProcessSnapshot(snapshot => {
    delete snapshot.id; // eslint-disable-line no-param-reassign
    return snapshot;
  })
  .extend(
    withValidations({
      name: {
        length: { minimum: 1, message: 'Language is required' }
      }
    })
  )
  .actions(self => ({
    setName(name) {
      self.name = name; // eslint-disable-line no-param-reassign
    },
    setLevel(level) {
      self.level = level; // eslint-disable-line no-param-reassign
    }
  }))
  .views(self => ({
    proficiency() {
      return `${self.level.charAt(0).toUpperCase()}${self.level.slice(1)}`;
    }
  }));

export default Language;
