import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const UiContext = createContext({});

export const UiContextProvider = props => {
  const [modalType, setModalType] = useState(null);
  const [open, setOpen] = useState(false);
  const [collapseLeftPanel, setCollapseLeftPanel] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setCollapseLeftPanel(false);
  }, [location]);

  return (
    <UiContext.Provider
      value={{
        modalType,
        open,
        setOpen,
        setModalType,
        collapseLeftPanel,
        setCollapseLeftPanel
      }}
    >
      {props.children}
    </UiContext.Provider>
  );
};
