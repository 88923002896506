import React, { useState, useContext } from 'react';
import { observer } from 'mobx-react';

import { DerplingContext } from 'contexts';

import { Button } from 'components/atoms';

import DerplingPopup from '../derpling-popup/derpling-popup';

import DerplingHead from './faces/derpling.svg';
import Face1 from './faces/derp1.svg';
import Face2 from './faces/derp2.svg';
import Face3 from './faces/derp3.svg';
import Face4 from './faces/derp4.svg';
import Face5 from './faces/derp5.svg';
import Face6 from './faces/derp6.svg';

import {
  SentimentContainer,
  BottomHideable,
  Heading,
  GradeItem,
  GradeItemsContainer,
  Derpling,
  DerplingFace
} from './derpling-sentiment-style';

const MIN_GRADE = 1;
const MAX_GRADE = 6;
const faces = [Face1, Face2, Face3, Face4, Face5, Face6];
const randomizedDelays = Array.from({ length: MAX_GRADE + 1 - MIN_GRADE }, () =>
  Math.random()
);

const DerplingSentiment = () => {
  const derpling = useContext(DerplingContext);
  const { addSentimentFeedback, closeSentiment, sentimentOpen } = derpling;
  const [selectedGrade, setSelectedGrade] = useState(-1);
  const [comment, setComment] = useState('');
  const [expanded, setExpanded] = useState(false);

  const checkFormEnableConditions = () => {
    let condition = true;
    if (selectedGrade < MIN_GRADE || selectedGrade > MAX_GRADE) {
      condition = false;
    }
    return condition;
  };

  const clearForm = () => {
    setSelectedGrade(-1);
    setComment('');
    setExpanded(false);
  };

  const submitFeedback = () => {
    addSentimentFeedback(selectedGrade, comment);
    clearForm();
  };

  const onGradeChange = e => {
    const inputValue = parseInt(e.target.value, 10);
    const returnValue =
      inputValue < MIN_GRADE || inputValue > MAX_GRADE ? -1 : inputValue;
    setSelectedGrade(returnValue);
  };

  const isFormEnabled = checkFormEnableConditions();

  return (
    <DerplingPopup onClose={closeSentiment} visible={sentimentOpen}>
      <SentimentContainer>
        <Heading>How are you feeling?</Heading>
        <form>
          <GradeItemsContainer onClick={() => setExpanded(true)}>
            {Array.from(
              { length: MAX_GRADE - MIN_GRADE + 1 },
              (_, i) => i + MIN_GRADE
            ).map(i => {
              const Face = faces[i - 1];
              return (
                <GradeItem
                  htmlFor={`sentiment-grade-input-${i}`}
                  key={`sentiment-grade-button-${i}`}
                  value={i}
                  className={selectedGrade === i && 'is-selected'}
                >
                  <Derpling>
                    <DerplingHead />
                    <DerplingFace delay={randomizedDelays[i] * i} value={i}>
                      <Face />
                    </DerplingFace>
                  </Derpling>
                  <input
                    id={`sentiment-grade-input-${i}`}
                    name='grade[]'
                    value={i}
                    checked={i === selectedGrade}
                    type='radio'
                    onChange={e => onGradeChange(e.nativeEvent)}
                  />
                  <span>{i}</span>
                </GradeItem>
              );
            })}
          </GradeItemsContainer>

          <BottomHideable visible={expanded}>
            <span>Additional comments?</span>
            <label htmlFor='sentiment-text-input'>
              <textarea
                name='sentiment-text-input'
                id='sentiment-text-input'
                onChange={e => setComment(e.target.value)}
                defaultValue=''
                placeholder="I'm feeling like this because…"
              />
            </label>
            <Button disabled={!isFormEnabled} onClick={() => submitFeedback()}>
              Submit
            </Button>
          </BottomHideable>
        </form>
      </SentimentContainer>
    </DerplingPopup>
  );
};

export default observer(DerplingSentiment);
