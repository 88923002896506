import styled from 'styled-components/macro';
import { breakpointsRefresh as breakpoints } from 'common/styles';

export const LogViewContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${breakpoints.md} {
    overflow: unset;
  }
`;

export const LogViewContainerInner = styled.div`
  width: 200vw;
  height: 100%;
  display: grid;
  grid-template-columns: 100vw 100vw;
  transition: transform 0.5s var(--ease-out-expo);
  transform: ${({ hasActiveItem }) =>
    hasActiveItem ? 'translateX(-50%)' : 'translateX(0)'};
  ${breakpoints.md} {
    width: 100%;
    grid-template-columns: 16rem 1fr;
    transform: none;
  }
`;
