import { types } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';
import Decimal from '../types';

export const _maxLevel = 8;

const Trait = types
  .model('Trait', {
    maxLevel: types.optional(types.frozen(_maxLevel)),
    name: types.optional(types.string, ''),
    level: types.optional(Decimal, _maxLevel / 2),
    id: types.maybeNull(types.string)
  })
  .preProcessSnapshot(snapshot => {
    return {
      id: uuidv4(),
      ...snapshot
    };
  })
  .postProcessSnapshot(snapshot => {
    delete snapshot.maxLevel; // eslint-disable-line no-param-reassign
    delete snapshot.id; // eslint-disable-line no-param-reassign
    return snapshot;
  })
  .views(self => ({
    levelName(levelNames) {
      if (self.level < _maxLevel / 2) {
        return levelNames[0];
      }
      if (self.level === _maxLevel) {
        return levelNames[2];
      }
      return levelNames[1];
    }
  }))
  .actions(self => ({
    setName(name) {
      self.name = name; // eslint-disable-line no-param-reassign
    },
    setLevel(level) {
      self.level = level; // eslint-disable-line no-param-reassign
    }
  }));

export default Trait;
