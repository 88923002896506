import styled from 'styled-components/macro';
import { keyframes } from 'styled-components';

const gradeColors = [
  '#CE3636',
  '#E26949',
  '#E2AB49',
  '#90A85D',
  '#97B748',
  '#A8CE32'
];

const lookNo = keyframes`
   0% {
     transform: translate3d(-6%, 0, 0);
   }
   100% {
    transform: translate3d(6%, 0, 0);
   }
 `;

const lookYes = keyframes`
   0% {
     transform: translate3d(0, -2%, 0);
   }
   100% {
    transform: translate3d(0, 10%, 0);

   }
 `;

const jump = keyframes`
  0% {
    transform: scale(1.2, 0.7)translateY(0);
  }
  10% {
    transform: scale(1.1,.9) translateY(0);
  }
  30% {
    transform: scale(.9,1.1) translateY(-30%);
  }
  50% {
    transform: scale(1.05,.95) translateY(0);
  }
  57% {
    transform: scale(1,1) translateY(-3%);
  }
  100% {
    transform: scale(1,1) translateY(0);
  }
 `;

export const SentimentContainer = styled.div`
  width: 100%;
  max-width: 16rem;
`;
export const Heading = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-primary-dark);
  font-size: var(--font-size-xs);
  padding-right: 1rem;
`;

export const BottomHideable = styled.div`
  max-height: ${props => (props.visible ? '11rem' : '0')};
  transition: max-height 1s var(--ease-out-expo);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  span {
    color: var(--color-text-darker);
    font-size: var(--font-size-sm);
    font-weight: 600;
  }
  label {
    width: 100%;
    margin: 0;
  }
  textarea {
    margin: 0.5rem 0 0.25rem;
    padding: 0.5rem;
    background-color: var(--color-background-hint);
    color: var(--color-text-darker);
    font-size: var(--font-size-sm);
    border-radius: var(--border-radius-sm);
    width: 100%;
    height: 5rem;
    border: none;
    outline: none;
    overflow-y: auto;
    resize: none;
    &::placeholder {
      color: var(--color-text-dark);
      opacity: 1;
    }
  }
  button {
    margin-left: auto;
  }
`;

export const GradeItemsContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 1rem 0;
  justify-content: space-between;
  user-select: none;
`;
export const Derpling = styled.div`
  width: 1.8rem;
  height: 1.8rem;
  position: relative;
  transform-origin: 50% 100%;
  opacity: 0.6;
  will-change: transform;
`;

export const DerplingFace = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  animation: 1.7s infinite ease-in-out both alternate-reverse;
  animation-name: ${({ value }) => (value >= 4 ? lookYes : lookNo)};
  animation-delay: ${({ delay }) => `${-1 * delay}s`};
  will-change: transform;
`;

export const GradeItem = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  border-radius: var(--border-radius-sm);
  transition: background-color 0.4s ease-in-out;
  cursor: pointer;

  &:active:not(.is-selected) {
    ${Derpling} {
      transform: scale(1.2, 0.7);
      opacity: 1;
      transition: all 0.1s;
    }
  }
  &.is-selected {
    background-color: var(--color-text-lightest);
    ${Derpling} {
      animation: ${jump} 0.8s cubic-bezier(0.28, 0.84, 0.42, 1);
      opacity: 1;
    }
    span {
      color: var(--color-text-dark);
    }
  }

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }
  span {
    margin-top: 0.75rem;
    color: var(--color-text-light);
    font-size: var(--font-size-xs);
  }

  ${Derpling} {
    svg path {
      fill: ${({ value }) => gradeColors[value - 1]};
    }
  }
  ${DerplingFace} {
    svg {
      & g,
      path {
        fill: var(--color-background);
      }
    }
  }
`;
