import { observable, action } from 'mobx';

class NotificationStore {
  @observable duration = 4000;
  @observable message = '';
  @observable open = false;

  @observable messages = observable.array();

  @action queue(message = '') {
    if (message === '') return false;

    if (!this.open) {
      this.message = message;
      this.open = true;
      setTimeout(() => this.done(), this.duration);
    } else {
      this.messages.push(message);
    }

    return true;
  }

  @action done() {
    if (this.messages.length > 0) {
      const next = this.messages.splice(0, 1)[0];
      if (next === this.message) {
        this.open = false;
      } else {
        this.message = next;
        setTimeout(() => this.done(), this.duration);
      }
    } else {
      this.open = false;
    }
  }
}

export default new NotificationStore();
