import { types } from 'mobx-state-tree';
import { EmployeeList as Employees } from './employee-list-model';
import { ProjectsList } from './projects-list-model';
import { AssignmentsList } from './assignment-list-model';
import { TimeEntriesList } from './time-entries-list-model';
import { OffersList } from './offer-list-model';
import { SurveyList } from './survey-list-model';
import { ProjectEstimates } from './project-estimates-model';
import { TagList } from './tag-list-model';
import { DealList } from './deal-list-model';
import { initializeModelUtils } from './initialize-model-utils';
import { ResourceList } from './resourcing-list-model';
import { NotificationList } from './notification-list-model';
import { CustomerList } from './customer-list-model';

initializeModelUtils();

export const Derp = types.model('Derp', {
  projects: ProjectsList,
  timeentries: TimeEntriesList,
  assignments: AssignmentsList,
  employees: Employees,
  offers: OffersList,
  surveys: SurveyList,
  estimates: ProjectEstimates,
  tags: TagList,
  resources: ResourceList,
  deals: DealList,
  notifications: NotificationList,
  customers: CustomerList
});

export default Derp;
