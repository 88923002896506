import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

const shake = keyframes`
  0% {
    transform: translateX(0);
  }
  6.5% {
    transform: translateX(-3%) rotateY(-9deg);
  }
  18.5% {
    transform: translateX(2.5%) rotateY(7deg);
  }
  31.5% {
    transform: translateX(-1.5%) rotateY(-5deg);
  }
  43.5% {
    transform: translateX(1%) rotateY(3deg);
  }
  50% {
    transform: translateX(0);
  }
`;

const pulse = keyframes`
  0% {
  filter: drop-shadow(0px 0px 0px var(--pulseColorStart));
  }
  100%{
  filter: drop-shadow(0px 0px 10rem var(--pulseColorEnd));
  }
`;

export const IllustrationContainer = styled.div`
  position: 'relative';
  color: var(--background-color);
  margin: 0 auto;
  width: 100%;
  max-width: 12rem;
  // Custom properties with fallback values to support old derp
  color: var(--color-text-is-active, #f8485e);
  --pulseColorStart: rgba(var(--color-primary-rgb, 248, 72, 94), 0.9);
  --pulseColorEnd: rgba(var(--color-primary-rgb, 248, 72, 94), 0);
  &:hover {
    animation: ${shake} 1s 0s ease-in-out;
    cursor: not-allowed;
    svg {
      overflow: visible;
      animation: ${pulse} 1s 0s ease-out forwards;
      #scanlines {
        opacity: 0.1;
      }
    }
  }
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  position: absolute;
  left: 0;
  padding: var(--sideMargin, 1rem);
`;

const Illustration = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 525 754.026'
      width='100%'
    >
      <defs>
        <pattern
          id='scanlinesPattern'
          width='1'
          height='8'
          x='0'
          y='0'
          patternUnits='userSpaceOnUse'
          viewBox='0 0 1 8'
          patternTransform='translate(0 0)'
        >
          <animateTransform
            attributeType='xml'
            attributeName='patternTransform'
            type='translate'
            from='0,0'
            to='0,8'
            begin='0'
            dur='0.25s'
            repeatCount='indefinite'
          />

          <path
            fill='var(--color-background, #fff)'
            d='M0 0h1v4H0z'
            opacity={0.3}
          />
        </pattern>
      </defs>
      <path
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='10'
        d='M372.8 283.866a135.925 135.925 0 11-162.043-133.419l26.126-55.494L263 150.447a135.964 135.964 0 01109.8 133.419z'
      />
      <ellipse
        cx='236.879'
        cy='658.112'
        fill='#000000'
        opacity='0.1'
        rx='183.549'
        ry='18.826'
      />
      <path
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='10'
        d='M236.879 547.176v33M236.879 291.176v14m-51-45h102v74h-102zm81 0v-19a30 30 0 00-60 0v19'
      />
      <g
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='10'
      >
        <g fill='none'>
          <path d='M236.879 5L5 83.158v255.9c0 150.715 231.879 241.158 231.879 241.158s231.879-90.443 231.879-241.156V83.158z' />
          <path d='M236.879 547.63c-18.9-8.376-59.5-27.851-99.431-56.552-30.817-22.151-55.157-45.344-72.344-68.934C45.129 394.728 35 366.774 35 339.06V104.705l201.879-68.047 201.879 68.047V339.06c0 27.714-10.128 55.668-30.1 83.084-17.188 23.59-41.528 46.783-72.345 68.934-39.934 28.701-80.537 48.176-99.434 56.552z' />
        </g>
        <path fill='none' d='M236.758 5.176v31' />
        <path fill='#fff' d='M438.758 104.705l30-21.547M5 83.158l30 21.547' />
      </g>
      <path id='scanlines' fill='url(#scanlinesPattern)' d='M0 0h525v754H0z' />
    </svg>
  );
};

const Denied = () => {
  return (
    <Container>
      <IllustrationContainer>
        <Illustration />
      </IllustrationContainer>
    </Container>
  );
};

export default Denied;
