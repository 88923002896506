import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  // TODO: Hack, refactor ListAvatar to work without links
  a {
    pointer-events: none;
  }
`;

export const CustomerContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomerAction = styled.div`
  opacity: 0;
  transition: opacity 0.2s linear;
  @media (pointer: coarse) {
    opacity: 1;
  }
  ${CustomerContainer}:hover & {
    opacity: 1;
  }
`;

export const SearchContainer = styled.div`
  position: relative;
`;

export const CustomerList = styled.div`
  position: absolute;
  width: max-content;
  min-width: 100%;
  max-width: 150%;
  padding: 0.5rem;
  margin-left: -0.5rem;
  margin-top: 0.5rem;
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--color-grey-lightest);
  border-radius: var(--border-radius-xs);
  background-color: var(--color-modal);

  z-index: 160;
`;

export const CustomerWrapper = styled.div`
  padding: 0.5rem;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--color-input-hover);
  }

  &:focus {
    outline: none;
    border-color: var(--color-input-focus);
    border-radius: var(--border-radius-xs);
  }
`;

export const NewCustomer = styled.div`
  color: var(--color-primary-dark);
`;

export const SourcedCustomer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > img {
    height: 1.25rem;
    width: 1.25rem;
    margin-left: 0.5rem;
  }
`;
