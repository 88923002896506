import { destroy, flow, types } from 'mobx-state-tree';
import { observable } from 'mobx';
import { chunkProcessor } from 'mobx-utils';

import moment from 'moment';
import { Employee } from './employee-model';

import axios from '../common/utils/axios';
import { calculateDateRangeQuery } from '../common/utils/time';

import notificationStore from '../stores/notificationStore';

const batchQueue = observable.array();

export const EmployeeList = types
  .model('EmployeeList', {
    employees: types.map(Employee)
  })
  .views(self => ({
    // Default to active internal employees
    active(month, exactDate = false) {
      return self.activeByRoles(month, ['external', 'freelance'], {
        exclude: true,
        exactDate
      });
    },
    payrollActive(month) {
      return [...self.employees.values()].filter(e => e.isPayrollActive(month));
    },
    get ids() {
      return [...self.employees.keys()].map(Number);
    },
    suspended(opts = {}) {
      return [...self.employees.values()]
        .filter(e => moment(e.suspended).isBefore(moment(), 'day'))
        .filter(
          e => !opts.externals ^ ['external', 'freelance'].includes(e.role) // eslint-disable-line no-bitwise
        );
    },
    activeByRoles(month, roles, opts = {}) {
      const roleArray = [].concat(roles); // Ensure array, allow array | string
      return [...self.employees.values()]
        .filter(e => e.isActive(month, opts.exactDate))
        .filter(e => opts.exclude ^ roleArray.includes(e.role)); // eslint-disable-line no-bitwise
    },
    getById(id) {
      return self.employees.get(id);
    }
  }))
  .actions(self => ({
    load: flow(function* load(opts = {}) {
      const params = new URLSearchParams(opts).toString();
      const { data } = yield axios.get(`/api/v1/employees?${params}`);
      self.createEmployeeObjects(data);
      return self;
    }),
    loadExternal: flow(function* loadExternal() {
      const { data } = yield axios.get('/api/v1/employees/external');
      self.createEmployeeObjects(data);
      return self;
    }),
    loadReport: flow(function* loadReport(month = moment()) {
      const { start, end } = calculateDateRangeQuery(month);
      const e = JSON.stringify(self.ids);
      const { data } = yield axios.get(
        `/api/v1/employees/report?start=${start}&end=${end}&employees=${e}`
      );
      data.forEach(d => {
        const emp = self.employees.get(d.employee);
        if (emp) {
          emp.addSummary(d, month);
        }
      });
      return self;
    }),
    delete: flow(function* deleteEmployee(employee) {
      try {
        yield axios.delete(`/api/v1/employees/${employee.id}`);
        destroy(self.employees.get(employee.id));
      } catch (err) {
        notificationStore.queue('Could not remove employee!');
      }
    }),
    convert: flow(function* convert(employee, email) {
      try {
        yield axios.put(
          `/api/v1/employees/${employee.id}/convert`,
          JSON.stringify({ email })
        );
        // A bit of a cop-out, easier to remove :)
        destroy(self.employees.get(employee.id));
      } catch (err) {
        notificationStore.queue('Could not convert employee!');
      }
    }),
    invite: flow(function* invite(employee) {
      try {
        yield axios.post(`/api/v1/employees/${employee.id}/invite`);
        notificationStore.queue('Invitation sent!');
      } catch (err) {
        notificationStore.queue('Could not send invite :(');
      }
      return employee;
    }),
    batchLoad: flow(function* batchLoad(ids) {
      const { data } = yield axios.get(
        `/api/v1/employees?employees=${JSON.stringify(ids)}`
      );
      self.createEmployeeObjects(data);
    }),
    createEmployeeObjects(data) {
      data.forEach(e => {
        const existing = self.employees.get(e.id);
        if (existing) {
          existing.update(e);
        } else {
          self.employees.put(e);
        }
      });
    },
    getOrCreate(data, batch = false) {
      let e = self.employees.get(data.id);
      if (!e) {
        e = Employee.create(data);
        if (batch) {
          batchQueue.push(data.id);
        } else {
          e.load();
        }
        self.employees.put(e);
      }
      return e;
    },
    createNewEmployee: flow(function* create(employee, invite) {
      const { data } = yield axios.post(
        '/api/v1/employees',
        JSON.stringify({ ...employee, send_invitation: invite && '1' })
      );
      return self.employees.put(data);
    }),
    afterCreate() {
      chunkProcessor(batchQueue, data => self.batchLoad(data), 50, 0);
    }
  }));

export const employeeIds = employees => {
  return employees.map(e => e.id);
};

export const costCenters = employees => {
  const c = employees.map(e => e.cost_center);
  return [...new Set(c.filter(Boolean))];
};

export const locations = employees => {
  const l = employees.map(e => e.location);
  return [...new Set(l.filter(Boolean))];
};

export const sortByFullName = employees =>
  employees.sort((a, b) =>
    `${a.last_name} ${a.first_name}`.localeCompare(
      `${b.last_name} ${b.first_name}`,
      'fi'
    )
  );

export const sortByMissingDays = (employees, month) => {
  const hasMissingDays = e => {
    const summary = e.summary(month);
    if (summary.missing_days > 0) {
      return true;
    }
    return false;
  };
  employees.sort((a, b) => {
    return hasMissingDays(b) - hasMissingDays(a);
  });
};

export const sortByUnpaid = (employees, month) => {
  employees.sort((a, b) => {
    const summaryA = a.summary(month);
    const summaryB = b.summary(month);
    return summaryB.unpaid - summaryA.unpaid;
  });
};

export const sortByBalance = (employees, month) => {
  employees.sort((a, b) => {
    const summaryA = a.summary(month);
    const summaryB = b.summary(month);
    return summaryA.balance - summaryB.balance;
  });
};
export const sortByVacation = (employees, month) => {
  employees.sort((a, b) => {
    const summaryA = a.summary(month);
    const summaryB = b.summary(month);
    return summaryB.holiday - summaryA.holiday;
  });
};

export const filterEmployees = (names, employees) => {
  return employees.filter(e => {
    return names.includes(e.fullName());
  });
};

export default EmployeeList;
