export const colors = {
  bifaRed: '#f8485e',
  bifaDark: '#222222',
  bifaPink: '#bc309a',
  bifaGreen: '#9bc66c',
  bifaLightGreen: '#49e2c5',
  disabled: '#d2d2d2',
  bifaYellow: '#e8db2e',
  bifaDarkBlue: '#27156a',
  bifaPurple: '#7149f5',
  bifaLightBlue: '#69b9fa',
  bifaLightPurple: '#d3c4e7',
  hover: 'lighten(#bc309a, 40 %)',
  holiday: 'lighten(#f8485e, 30 %)',
  lineLight: '#e9e9e9',
  lineMedium: '#d9d9d9',
  headingGray: '#6f6f6f',
  mediumGray: '#8a8a8a',
  overlayBackground: 'rgba(34, 34, 34, 0.3)',
  darkGray: 'rgba(34, 34, 34, 0.6)',
  lightGray: 'rgba(34, 34, 34, 0.1)',
  white: '#ffffff',
  lightgrayLine: '2px solid #e9e9e9'
};

export default colors;
