import React from 'react';

import Button from '../button/button';

import { RowAction } from './row-buttons-style';

const RowDelete = ({ onDelete }) => (
  <RowAction>
    <Button transparent icon='trash' iconSize='1.25rem' onClick={onDelete} />
  </RowAction>
);

export const RowDeleteRenderer = onDelete => (field, model) => (
  <RowDelete onDelete={() => onDelete(field, model)} />
);

export default RowDelete;
