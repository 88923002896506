import {
  types,
  flow,
  getSnapshot,
  destroy,
  detach,
  applySnapshot,
  resolveIdentifier
} from 'mobx-state-tree';
import moment from 'moment';
import { withValidations } from 'mst-validatejs';
import { v4 as uuidv4 } from 'uuid';

import { MomentType } from './types';
import Proposition from './proposition-model';
import axios from '../common/utils/axios';
import ContactPerson from './offer/contact-person-model';
import { formatText } from '../contexts/intl/formatted-text';
import { Tag } from './tag-list-model';

// TODO: Refactor? Kinda clunky, maybe change the default description
const format = text => {
  try {
    const companyName = JSON.parse(
      localStorage.getItem('settings')
    ).company.replace(/oy$/i, '');
    return text.replace(/bitfactor/gim, companyName);
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
    return text;
  }
};

const OfferLink = types
  .model('OfferLink', {
    uuid: types.identifier,
    email: types.optional(types.string, ''),
    path: types.optional(types.string, ''),
    views: types.optional(types.string, ''),
    last_visit: types.maybeNull(MomentType)
  })
  .actions(self => ({
    setEmail: e => {
      self.email = e; // eslint-disable-line no-param-reassign
    },
    setViews: e => {
      self.views = e; // eslint-disable-line no-param-reassign
    },
    setLastVisit: e => {
      self.last_visit = e; // eslint-disable-line no-param-reassign
    }
  }))
  .preProcessSnapshot(snapshot => {
    return {
      uuid: uuidv4(),
      ...snapshot
    };
  })
  .postProcessSnapshot(snapshot => {
    const next = Object.assign({}, snapshot);
    delete next.uuid;
    delete next.views;
    delete next.last_visit;
    return next;
  });

export const sortByOrderNum = propositions => {
  return propositions.sort((a, b) => a.order_num - b.order_num);
};

export const Offer = types
  .model('Offer', {
    id: types.identifierNumber,
    name: types.optional(types.string, ''),
    description: types.string,
    start: types.optional(MomentType, moment()),
    expires: types.optional(MomentType, moment().add(1, 'months')),
    contact: types.optional(ContactPerson, () => ContactPerson.create()),
    url: types.optional(types.string, ''),
    links: types.array(OfferLink),
    tags: types.map(Tag),
    propositions: types.array(Proposition),
    customer: types.maybeNull(types.string),
    anonymous_cvs: types.optional(types.boolean, false),
    state: types.maybeNull(
      types.enumeration(['pending', 'ready', 'error']),
      'pending'
    )
  })
  .extend(
    withValidations({
      name: {
        length: { minimum: 1, message: 'name is required' }
      },
      description: {
        length: { minimum: 1, message: 'description is required' }
      },
      start: {
        validDateRange: true
      },
      expires: {
        validDateRange: true
      }
    })
  )
  .preProcessSnapshot(snapshot => {
    if (!snapshot.description) {
      const description = formatText('en', 'offerDescription', format);
      return {
        description,
        ...snapshot
      };
    }
    return snapshot;
  })
  .postProcessSnapshot(snapshot => {
    delete snapshot.id; // eslint-disable-line no-param-reassign
    delete snapshot.url; // eslint-disable-line no-param-reassign
    delete snapshot.state; // eslint-disable-line no-param-reassign
    delete snapshot.propositions; // eslint-disable-line no-param-reassign
    return snapshot;
  })
  .views(self => ({
    isReady() {
      return self.state === 'ready';
    },
    allPropositions() {
      return [...self.propositions];
    },
    propositionById(id) {
      const p = resolveIdentifier(Proposition, self, id);
      if (p) {
        return p;
      }
      return null;
    },
    propositionByEmployeeId(id) {
      const proposition = self.propositions.find(p => p.employee_id === id);
      if (proposition) {
        return proposition;
      }
      return null;
    },
    hasTag(tagId) {
      return self.tags.has(tagId);
    },
    allTags() {
      return [...self.tags.values()];
    }
  }))
  .actions(self => ({
    load: flow(function* load(id) {
      const { data } = yield axios.get(`/api/v1/offers/${id}`);
      self.id = data.id; // eslint-disable-line no-param-reassign
      self.name = data.name; // eslint-disable-line no-param-reassign
      self.description = data.description; // eslint-disable-line no-param-reassign
      self.contact = data.contact; // eslint-disable-line no-param-reassign
      self.start = data.start; // eslint-disable-line no-param-reassign
      self.expires = data.expires; // eslint-disable-line no-param-reassign
      self.url = data.url; // eslint-disable-line no-param-reassign
      self.links = data.links; // eslint-disable-line no-param-reassign
      self.customer = data.customer; // eslint-disable-line no-param-reassign
      self.propositions = sortByOrderNum(data.propositions); // eslint-disable-line no-param-reassign
      self.anonymous_cvs = data.anonymous_cvs; // eslint-disable-line no-param-reassign
      self.updateAnalytics();
    }),
    setName(name) {
      self.name = name; // eslint-disable-line no-param-reassign
    },
    setDescription(description) {
      self.description = description; // eslint-disable-line no-param-reassign
    },
    setContact(contact) {
      self.contact = contact; // eslint-disable-line no-param-reassign
    },
    setStartDate(date) {
      self.start = date; // eslint-disable-line no-param-reassign
    },
    setExpireDate(date) {
      self.expires = date; // eslint-disable-line no-param-reassign
    },
    addNewCustomerLink() {
      self.links.push(OfferLink.create());
    },
    setCustomer(name) {
      self.customer = name; // eslint-disable-line no-param-reassign
    },
    setAnonymousCvs(anonymous) {
      self.anonymous_cvs = anonymous; // eslint-disable-line no-param-reassign
    },
    deleteLink(link) {
      destroy(link);
    },
    saveLinks: flow(function* saveLinks() {
      const offer = {
        links: self.links.map(l => ({ email: l.email, path: l.path }))
      };
      const { data } = yield axios.put(
        `/api/v1/offers/${self.id}`,
        JSON.stringify(offer)
      );
      self.links = data.links; // eslint-disable-line no-param-reassign
      return self;
    }),
    save: flow(function* save() {
      const offer = getSnapshot(self);
      if (self.id === -1) {
        const { data } = yield axios.post(
          '/api/v1/offers',
          JSON.stringify(offer)
        );
        self.id = data.id; // eslint-disable-line no-param-reassign
        self.url = data.url; // eslint-disable-line no-param-reassign
      } else {
        yield axios.put(`/api/v1/offers/${self.id}`, JSON.stringify(offer));
      }
      return self;
    }),

    loadTags: flow(function* loadTags() {
      try {
        const params = { model_type: 'offer', model_id: self.id };
        const { data } = yield axios.get('/api/v1/tags', { params });
        data.map(tag => self.tags.put(tag));
      } catch (error) {
        console.error('Failed to load offer tags', error); // eslint-disable-line no-console
      }
    }),
    toggleTag: flow(function* toggleTag(tagId) {
      try {
        const params = { model_type: 'offer', model_id: self.id };
        const { data } = yield axios.put(
          `/api/v1/tags/${tagId}/toggle`,
          params
        );
        if (self.hasTag(tagId)) {
          destroy(self.tags.get(tagId));
        } else {
          self.tags.put(data);
        }
      } catch (error) {
        console.error('Could not toggle tag', error); // eslint-disable-line no-console
      }
      return self;
    }),

    addOrUpdatePropositions: flow(function* addOrUpdatePropositions(
      propositionsData
    ) {
      const currentIds = self.propositions.map(p => p.employee_id);
      const currentOrderNumbers = self.propositions.map(p => p.order_num);
      const nextNumber = Math.max(...currentOrderNumbers, 0) + 1;

      const propositions = [];

      propositionsData.forEach((p, index) => {
        const { cv, employee_id } = p;
        if (!currentIds.includes(employee_id)) {
          propositions.push({
            employee_id,
            order_num: nextNumber + index,
            cv
          });
        } else if (cv && currentIds.includes(employee_id)) {
          const targetProposition = self.propositionByEmployeeId(employee_id);
          const { id } = targetProposition;
          targetProposition.updateCv(cv);
          propositions.push({
            id,
            cv
          });
        }
      });

      const offer = {
        offer: {
          propositions_attributes: [...propositions]
        }
      };

      const { data } = yield axios.put(
        `/api/v1/offers/${self.id}/`,
        JSON.stringify(offer)
      );

      applySnapshot(self, data);
    }),

    removeProposition: flow(function* removeProposition(proposition) {
      yield axios.delete(
        `/api/v1/offers/${self.id}/employees/${proposition.employee_id}`
      );
      destroy(proposition);
    }),
    reorderPropositions: flow(function* reorderPropositions(
      startIndex,
      endIndex
    ) {
      self.propositions.splice(
        endIndex,
        0,
        detach(self.propositions[startIndex])
      );

      self.propositions.forEach((p, index) => {
        p.setOrderNum(index);
      });

      const offer = {
        offer: { propositions_attributes: self.propositions }
      };

      yield axios.put(`/api/v1/offers/${self.id}`, JSON.stringify(offer));
      return self;
    }),

    updateCustomer: flow(function* updateCustomer(id) {
      try {
        const { data } = yield axios.put(
          `/api/v1/offers/${self.id}`,
          JSON.stringify({ customer_id: id })
        );
        self.setCustomer(data.customer);
      } catch (err) {
        console.log('Error updating offer customer id', err); // eslint-disable-line no-console
      }
    }),

    updateAnalytics: flow(function* updateAnalytics() {
      if (self.links.length > 0) {
        const { data } = yield axios.get(`/api/v1/offers/${self.id}/analytics`);
        data.forEach(d => {
          const link = self.links.find(l => l.path === d.path);
          if (link) {
            link.setViews(d.views);
            link.setLastVisit(moment(d.last_visit));
          }
        });
      }
    })
  }));

export default Offer;
