import React from 'react';

import { HeaderContainer, HeaderSection } from './header-style';

const Header = ({ left, right }) => (
  <HeaderContainer>
    <HeaderSection>{left}</HeaderSection>
    {right ? <HeaderSection>{right}</HeaderSection> : null}
  </HeaderContainer>
);

export default Header;
