import styled from 'styled-components/macro';

export const Container = styled.div`
  padding: 2rem 0;
`;

export const RangeSlider = styled.input`
  -webkit-appearance: none;
  position: absolute;
  bottom: -2rem;
  width: 100%;
  height: 4px;
  border-radius: var(--border-radius-full);
  background: var(--color-text-lightest);
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background: var(--color-primary);
    cursor: pointer;
    transition: all 0.15s ease-in-out;
  }
  &::-moz-range-thumb {
    width: 1.25rem;
    height: 1.25rem;
    border: 0;
    border-radius: 50%;
    background: var(--color-primary);
    cursor: pointer;
    transition: background 0.15s ease-in-out;
  }
`;

export const EditorContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  canvas {
    max-width: min(300px, 50vmin);
    max-height: min(300px, 50vmin);
  }
`;
