import { useContext, useState } from 'react';

import { AuthContext } from '../../contexts';

const useSettings = () => {
  try {
    const { settings: authSettings } = useContext(AuthContext);

    const [settings] = useState(authSettings || {});

    return settings;
  } catch (error) {
    console.warn('Cannot access settings, not authenticated'); // eslint-disable-line no-console
    return {};
  }
};

export default useSettings;
