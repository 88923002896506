import React, { useState, useEffect, useRef } from 'react';
import { Icon } from 'components/atoms';
import {
  TooltipContainer,
  HoverableLabel,
  HoverWindow,
  Header,
  Content
} from './tooltip-style';

const Tooltip = ({
  header,
  content,
  hoverableLabel,
  icon = 'guide',
  noIcon = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const tooltipRef = useRef(null);

  const handleToggleClick = () => {
    setIsClicked(!isClicked);
  };

  const handleMouseEnter = () => {
    if (!isClicked) {
      setIsOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isClicked) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        isOpen &&
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target)
      ) {
        setIsOpen(false);
        setIsClicked(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const renderContent = () => {
    if (Array.isArray(content)) {
      return content.map(item => <Content key={item}>{item}</Content>);
    }
    return <Content>{content}</Content>;
  };

  return (
    <TooltipContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleToggleClick}
      ref={tooltipRef}
    >
      {noIcon ? (
        <HoverableLabel>{hoverableLabel}</HoverableLabel>
      ) : (
        <>
          <Icon name={icon} />
          {hoverableLabel && (
            <HoverableLabel style={{ marginLeft: '0.25rem' }}>
              {hoverableLabel}
            </HoverableLabel>
          )}
        </>
      )}
      {isOpen && (
        <HoverWindow>
          {header && <Header>{header}</Header>}
          {renderContent()}
        </HoverWindow>
      )}
    </TooltipContainer>
  );
};

export default Tooltip;
