import React from 'react';
import styled from 'styled-components/macro';
import { breakpointsRefresh } from 'common/styles';
import { Icon } from 'components/atoms';

const breakpoints = breakpointsRefresh;

export const Toggle = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.75rem;
  cursor: pointer;
  color: var(--color-text-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 90;
  &:hover {
    color: var(--color-text-darker);
  }
  ${breakpoints.md} {
    width: 2rem;
    height: 2rem;
  }
  ${breakpoints.xl} {
    display: none;
    margin-left: 1.25rem;
  }
`;

const MenuToggle = props => {
  const { onClick, icon } = props;
  return (
    <Toggle onClick={onClick}>
      <Icon name={icon} />
    </Toggle>
  );
};

export default MenuToggle;
