import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { breakpointsRefresh } from 'common/styles';

const breakpoints = breakpointsRefresh;

export const activeClassName = 'is-active';

export const Tab = styled.div`
  display: flex;
  padding: 0.2rem 0.75rem 0.25rem;
  cursor: pointer;
  text-decoration: none;
  color: currentColor;
  margin-bottom: -1px;
  &:hover {
    background-color: var(--color-background-is-active);
  }
  border-radius: var(--border-radius-md);
  ${breakpoints.lg} {
    padding: 0.25rem 0.75rem;
    border-radius: var(--border-radius-md);
  }
`;
export const TabLink = styled(NavLink).attrs({
  activeClassName
})`
  color: var(--color-text-light);
  text-align: center;
  white-space: nowrap;
  &:hover {
    color: var(--color-text-darker);
    text-decoration: none;
  }

  ${breakpoints.lg} {
    margin: auto;
    text-align: left;
  }

  &.${'is-active'} {
    ${Tab} {
      color: var(--color-background);
      background-color: var(--color-text-darker);
      color: var(--color-text-is-active);
      background-color: var(--color-background-is-active);

      ${breakpoints.lg} {
        color: var(--color-text-is-active);
        background-color: var(--color-background-is-active);
        border-bottom: 0;
      }
    }
  }
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: var(--font-size-xs);
  padding: 0.5rem 1rem 0.75rem;
  border-radius: var(--border-radius-md);
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
  display: none;
}

  ${TabLink} + ${TabLink} {
    margin-left: 0;
    ${breakpoints.lg} {
      margin-left: .75rem;
    }
  }

  ${breakpoints.lg} {
    font-size: var(--font-size-sm);
    background-color: transparent;
    padding: 0 1rem 0;
  }
`;
