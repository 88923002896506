import styled from 'styled-components/macro';
import { breakpointsRefresh as breakpoints } from 'common/styles';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  right: 0.75rem;
  bottom: 5.2rem;
  position: fixed;
  z-index: 25000;
  /* border: 1px solid var(--color-text-light);
  box-shadow: var(--shadow-md);
  border-radius: var(--border-radius-md);
  background-color: var(--color-background);
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: calc(-0.5rem - 2px);
    right: calc(1rem - 2px);
    border: calc(0.5rem + 2px) solid transparent;
    border-bottom: none;
    border-top-color: var(--color-text-light);
    filter: drop-shadow(0px 4px 4px rgba(0 0 0, 0.13));
  }
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: -0.5rem;
    right: 1rem;
    border: 0.5rem solid transparent;
    border-bottom: none;
    border-top-color: var(--color-background);
  } */

  ${breakpoints.md} {
    --font-size-2xl: 1.5rem;
    --font-size-3xl: 1.875rem;
  }

  transition: opacity 0.2s ease-out;
  .is-hidden & {
    opacity: 0;
  }
`;
