import { types, getParent, flow, getSnapshot } from 'mobx-state-tree';
import { withValidations } from 'mst-validatejs';
import axios from '../common/utils/axios';

const cityByPostalCode = require('datasets-fi-postalcodes');

const phonePattern = /^$|^[0-9-+() ]+$/;
const namePattern = /^[\p{L} '-]*$/u;
const alphaNumericPattern = /^[\d\p{L} '-]*$/u;
const zipCodePattern = /^$|^[a-z0-9 -]{2,12}$/u; // Zip codes are of lenght 2-12 characters, may have letters a-z, numbers, white spaces and lines
const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const IceContact = types
  .model('IceContact', {
    name: types.optional(types.string, ''),
    phone_number: types.optional(types.string, '')
  })
  .extend(
    withValidations({
      name: {
        format: {
          pattern: namePattern,
          message: 'invalid name'
        }
      },
      phone_number: {
        format: {
          pattern: phonePattern,
          flags: 'i',
          message: 'invalid phone number'
        }
      }
    })
  )
  .actions(self => ({
    setName(name) {
      self.name = name; // eslint-disable-line no-param-reassign
    },
    setPhone(phone) {
      self.phone_number = phone; // eslint-disable-line no-param-reassign
    }
  }));

const Address = types
  .model('Address', {
    street_address: types.optional(types.string, ''),
    postal_code: types.optional(types.string, ''),
    city: types.optional(types.string, '')
  })
  .extend(
    withValidations({
      street_address: {
        format: {
          pattern: alphaNumericPattern,
          message: 'invalid city name'
        }
      },
      city: {
        format: {
          pattern: namePattern,
          message: 'invalid city name'
        }
      },
      postal_code: {
        format: {
          pattern: zipCodePattern,
          message: 'invalid postal code'
        }
      }
    })
  )
  .actions(self => ({
    setStreetAddress(street_address) {
      self.street_address = street_address; // eslint-disable-line no-param-reassign
    },
    setPostalCode(postal_code) {
      self.postal_code = postal_code; // eslint-disable-line no-param-reassign
      const city = cityByPostalCode[postal_code];
      if (city) {
        self.city = city; // eslint-disable-line no-param-reassign
      }
    },
    setCity(city) {
      self.city = city; // eslint-disable-line no-param-reassign
    }
  }));

export const Profile = types
  .model('Profile', {
    ssn: types.optional(types.string, ''),
    phone_number: types.optional(types.string, ''),
    bank_account: types.optional(types.string, ''),
    address: types.optional(Address, () => Address.create()),
    ice_contact: types.optional(IceContact, () => IceContact.create()),
    contact_person: types.optional(types.string, ''),
    business_id: types.optional(types.string, ''),
    personal_email: types.optional(types.string, ''),
    allergies: types.optional(types.string, ''),
    external_system_id: types.optional(types.string, '')
  })
  .extend(
    withValidations({
      phone_number: {
        format: {
          pattern: phonePattern,
          flags: 'i',
          message: 'invalid phone number'
        }
      },
      bank_account: {
        validIban: true
      },
      personal_email: {
        format: {
          pattern: emailPattern,
          message: 'invalid email address'
        }
      }
    })
  )
  .actions(self => ({
    setSsn(ssn) {
      self.ssn = ssn; // eslint-disable-line no-param-reassign
    },
    setPhone(phone) {
      self.phone_number = phone; // eslint-disable-line no-param-reassign
    },
    setBankAccount(bank_account) {
      self.bank_account = bank_account; // eslint-disable-line no-param-reassign
    },
    setContactPerson(value) {
      self.contact_person = value; // eslint-disable-line no-param-reassign
    },
    setBusinessID(value) {
      self.business_id = value; // eslint-disable-line no-param-reassign
    },
    setPersonalEmail(value) {
      self.personal_email = value; // eslint-disable-line no-param-reassign
    },
    setAllergies(value) {
      self.allergies = value; // eslint-disable-line no-param-reassign
    },
    setExternalSystemId(value) {
      self.external_system_id = value; // eslint-disable-line no-param-reassign
    },
    save: flow(function* save() {
      const e = getParent(self);
      const employee = {
        employee: { profile: getSnapshot(self) }
      };
      const { status } = yield axios.put(
        `/api/v1/employees/${e.id}`,
        JSON.stringify(employee)
      );
      if (status === 200) {
        e.setState('ready');
      } else {
        e.setState('error');
      }
    })
  }));

export default Profile;
