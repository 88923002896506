import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ProtectedRoute } from '../../common/routes';
import { AuthContext } from '../../contexts/auth/auth-context';

const AdminRoute = ({ redirect = '/my/hours', ...props }) => {
  const authCtx = useContext(AuthContext);

  if (authCtx.user.isAdmin()) {
    return <ProtectedRoute {...props} />;
  }
  return (
    <Redirect to={{ pathname: redirect, state: { from: props.location } }} />
  );
};

AdminRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  location: PropTypes.object
};

AdminRoute.defaultProps = {
  component: undefined,
  location: undefined
};

export default AdminRoute;
