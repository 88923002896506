import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { ViewportContext } from 'contexts';

import LogSidebar from './log-sidebar';
import LogMain from './log-main';

import { LogViewContainer, LogViewContainerInner } from './log-view-style';

const LogView = observer(
  ({
    items,
    allowAdd,
    allowDelete,
    onAddItem,
    onDeleteItem,
    onSelectItem,
    editableFields,
    onEditItem
  }) => {
    const [activeItem, setActiveItem] = useState(null);
    const { breakpoint } = useContext(ViewportContext);
    useEffect(() => {
      const activeItemExists = items.some(item => item.id === activeItem);

      if (
        breakpoint.md &&
        items.length > 0 &&
        (!activeItem || !activeItemExists)
      ) {
        setActiveItem(items[0].id);
        if (onSelectItem) {
          onSelectItem(items[0]);
        }
      }
    }, [items]);

    return (
      <LogViewContainer>
        <LogViewContainerInner hasActiveItem={activeItem}>
          <LogSidebar
            items={items}
            activeItem={activeItem}
            setActiveItem={item => {
              setActiveItem(item.id);

              if (onSelectItem) {
                onSelectItem(item);
              }
            }}
            allowAdd={allowAdd}
            onAddItem={() => {
              onAddItem();
              setActiveItem(null);
            }}
          />
          <LogMain
            item={items.find(({ id }) => id === activeItem)}
            allowDelete={allowDelete}
            onNavigateBack={() => setActiveItem(null)}
            onDeleteItem={item => onDeleteItem(item)}
            editableFields={editableFields}
            onEditItem={value => onEditItem(value)}
          />
        </LogViewContainerInner>
      </LogViewContainer>
    );
  }
);

LogView.defaultProps = {
  allowAdd: false,
  allowDelete: false,
  onAddItem: () => null,
  onDeleteItem: () => null,
  onSelectItem: () => null,
  onEditItem: () => null,
  editableFields: []
};

LogView.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  allowAdd: PropTypes.bool,
  allowDelete: PropTypes.bool,
  onAddItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onSelectItem: PropTypes.func,
  onEditItem: PropTypes.func,
  editableFields: PropTypes.arrayOf(PropTypes.string)
};

export default LogView;
