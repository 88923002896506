import styled from 'styled-components/macro';

import { breakpointsRefresh } from 'common/styles';

import { Label } from 'common/styles/refresh';

const breakpoints = breakpointsRefresh;

export const MainHeading = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-text-dark);
  letter-spacing: -0.005em;
  text-transform: uppercase;
  font-size: var(--font-size-base);
  margin-bottom: 1rem;
  font-weight: 400;
  margin-top: 2.5rem;
  ${breakpoints.md} {
    margin-top: 5rem;
  }

  span {
    margin-left: 0.5rem;
    font-size: var(--font-size-xxs);
    vertical-align: middle;
    color: var(--color-grey-light);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 50rem;
`;

export const LevelHeadings = styled.div`
  display: flex;
  justify-content: space-between;
  ${Label}:nth-of-type(2) {
    transform: translateX(-25%);
  }
`;

export const AddRowButton = styled.button`
  font-size: var(--font-size-xs);
  color: var(--color-text-dark);
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  opacity: 0.5;
  span {
    margin-left: 0.25rem;
    opacity: 0;
  }
  &:hover {
    opacity: 1;
    span {
      opacity: 1;
    }
  }
  &:focus {
    outline: 0;
    opacity: 1;
  }
`;
export const RemoveButton = styled.button`
  position: absolute;
  width: 2rem;
  right: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--color-text-dark);
  opacity: 0.5;
  ${breakpoints.lg} {
    opacity: 0;
  }
`;

export const DragIcon = styled.div`
  color: currentColor;
  position: absolute;
  left: -1.5rem;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  opacity: 0;
  color: var(--color-text-dark);
`;

export const CertificateHeadings = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--color-text-lightest);
  > div:last-of-type {
    width: 6rem;
  }
`;

export const Wrapper = styled.div`
  margin-right: 2rem;
  ${AddRowButton} {
    margin-top: 0.5rem;
  }
  &:first-of-type {
    ${MainHeading} {
      margin-top: 0;
    }
  }
`;

export const TitleContainer = styled.div`
  position: relative;

  input:not(:focus) {
    color: transparent;
  }
`;

export const Placeholder = styled.span`
  display: ${({ $hidden }) => ($hidden ? 'none' : 'block')};
  position: absolute;
  transform: translateY(-50%);
  top: 50%;

  white-space: nowrap;
  max-width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;

  pointer-events: none;
  color: var(--color-text-darker);
`;

// TODO: Make into a div, using first-of-type for MainHeading is bork
export const CvMetaContainer = styled.span`
  display: flex;
  position: absolute;
  top: var(--sideMargin);
  left: var(--sideMargin);

  > button + button {
    margin-left: 1rem;
  }
`;

export const CvMetaLabel = styled.span`
  text-transform: uppercase;
  padding-left: 0.5ch;
  font-size: var(--font-size-xs);
`;
