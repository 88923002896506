import { types, flow } from 'mobx-state-tree';
import moment from 'moment';
import axios from '../common/utils/axios';
import { Survey } from './survey-model';

export const SurveyList = types
  .model('SurveyList', {
    surveys: types.map(Survey),
    state: types.maybeNull(
      types.enumeration(['pending', 'ready', 'error']),
      'pending'
    )
  })
  .views(self => ({
    isReady() {
      return self.state === 'ready';
    },
    newest() {
      // The survey is added to active surveys in SurveyJS immediately when it is created.
      // Even if it's unfinished. So let's give some time for the person who is creating the survey
      // so they can modify the survey and - if they don't want to publish it yet - change its status
      // to not active. So ignore the survey that is created today.
      // Instead, let's pick a newest survey of the surveys that are created yesterday or before it.
      const filteredSurveys = [...self.surveys.values()].filter(s => {
        return !moment().isSame(s.createdAt, 'day');
      });
      if (filteredSurveys.length > 0) {
        return filteredSurveys.reduce((a, b) => {
          return a.createdAt.isAfter(b.createdAt) ? a : b;
        });
      }
      return 'no active survey';
    },
    all() {
      return [...self.surveys.values()];
    }
  }))
  .actions(self => ({
    createSurvey(s) {
      const survey = Survey.create({ ...s });
      return self.surveys.put(survey);
    },
    load: flow(function* load() {
      try {
        const { data } = yield axios.get('/api/v1/surveys');
        data.map(survey => self.createSurvey(survey));
        self.state === 'ready'; // eslint-disable-line no-unused-expressions
      } catch (error) {
        console.log('Error loading surveys from SurveyJS', error); // eslint-disable-line no-console
        self.state = 'error'; // eslint-disable-line no-param-reassign
      }
      return self;
    })
  }));

export default SurveyList;
