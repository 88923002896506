import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { MonthSelector } from '../';

import {
  WarningMessage,
  MonthRangeContainer,
  DateContainer
} from './month-range-picker-style';

const MonthRangePicker = props => {
  const {
    startDate,
    endDate,
    todayButton,
    maxDate,
    minDate,
    hideStart,
    hideEnd,
    onStartChange,
    onEndChange,
    warnings,
    disabled
  } = props;

  const formattedStartDate = startDate.format('MMM YYYY');
  const formattedEndDate = endDate.format('MMM YYYY');

  const onDateStartChange = date => {
    onStartChange(date);
  };

  const onDateEndChange = date => {
    onEndChange(date);
  };

  const bothDatesVisible = !hideStart && !hideEnd;

  const showWarnings = warnings && bothDatesVisible;

  const renderMonthSelector = (date, controller, onChange) => {
    return (
      <React.Fragment>
        {disabled ? (
          <span>{controller}</span>
        ) : (
          <MonthSelector
            todayButton={todayButton}
            date={date}
            start={minDate}
            end={maxDate}
            onChange={onChange}
          >
            <DateContainer>{controller}</DateContainer>
          </MonthSelector>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <MonthRangeContainer>
        {hideStart
          ? null
          : renderMonthSelector(
              startDate,
              formattedStartDate,
              onDateStartChange
            )}
        <span style={{ margin: '0 0.1em' }}>&ndash;</span>
        {hideEnd
          ? null
          : renderMonthSelector(endDate, formattedEndDate, onDateEndChange)}
      </MonthRangeContainer>
      {showWarnings && (
        <WarningMessage>End date must be after start date</WarningMessage>
      )}
    </React.Fragment>
  );
};

MonthRangePicker.propTypes = {
  todayButton: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  hideStart: PropTypes.bool,
  hideEnd: PropTypes.bool,
  warnings: PropTypes.bool,
  disabled: PropTypes.bool,
  onStartChange: PropTypes.func,
  onEndChange: PropTypes.func
};

MonthRangePicker.defaultProps = {
  todayButton: false,
  startDate: moment(),
  endDate: moment(),
  maxDate: moment(),
  minDate: moment(0),
  hideStart: false,
  hideEnd: false,
  warnings: false,
  disabled: false,
  onStartChange: () => {},
  onEndChange: () => {}
};

export default MonthRangePicker;
