import styled from 'styled-components/macro';

export const Container = styled.div`
  padding: 1rem 0.5rem 0 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-background);
  overflow-y: auto;
`;
export const LogoutWrapper = styled.div`
  font-size: var(--font-size-xs);
  padding: 0.75rem;
  cursor: pointer;
  color: var(--color-text);
  display: flex;
  align-items: center;
  justify-content: space-between;
  div:hover {
    color: var(--color-text-darker);
  }
`;
export const Name = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 0.75rem;
  margin-bottom: 1.25rem;
  font-size: var(--font-size-sm);
  line-height: 1;
  letter-spacing: -0.025em;
  color: var(--color-text-darker);
`;
export const ProfilePicture = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  padding: 0 0.75rem;
`;

export const Hours = styled.div`
  font-weight: 700;
  letter-spacing: -0.05em;
  font-size: var(--font-size-xs);
`;
