import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { AuthConsumer } from '../../contexts/auth/auth-context';

const ProtectedRoute = observer(
  ({ component: Component, history, ...rest }) => (
    <AuthConsumer>
      {({ isAuth }) => (
        <Route
          {...rest}
          render={props => {
            if (isAuth) {
              return <Component {...props} />;
            }
            return props.location.pathname !== '/login' ? (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: props.location.pathname }
                }}
              />
            ) : null;
          }}
        />
      )}
    </AuthConsumer>
  )
);

export default withRouter(ProtectedRoute);
