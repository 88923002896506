import React from 'react';
import { Icon } from 'components/atoms';
import { SearchContainer } from './table-style';

const Search = props => {
  const { searchValue, onChange } = props;
  return (
    <SearchContainer>
      <Icon name='search' size='1rem' />
      <input value={searchValue} onChange={onChange} placeholder='Search' />
    </SearchContainer>
  );
};

export default Search;
