import styled from 'styled-components/macro';

const getColor = props => {
  if (props.isDragAccept) {
    return 'var(--color-success)';
  }
  if (props.isDragReject) {
    return 'var(--color-error)';
  }
  if (props.isDragActive) {
    return 'var(--color-primary)';
  }
  return 'var(--color-text-light)';
};

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-width: 2px;
  border-radius: var(--border-radius-md);
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: var(--color-background);
  color: var(--color-text);
  outline: none;
  transition: border 0.2s ease-in-out;
  cursor: pointer;
  p {
    margin: 0;
    text-align: center;
  }
`;

export default Container;
