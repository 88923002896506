import styled from 'styled-components/macro';
import { breakpointsRefresh as breakpoints } from 'common/styles';

export const NotificationsWrapper = styled.div`
  display: flex;
  margin-right: 2rem;

  ${breakpoints.md} {
    margin-right: 0;
  }

  button {
    border-radius: var(--border-radius-full);
    height: 2rem;
    width: 2rem;
    padding: 0;
  }
`;

export const NotificationTrigger = styled.div`
  display: grid;
  position: relative;
`;

export const NotificationIndicator = styled.div`
  position: absolute;
  width: 0.7rem;
  height: 0.7rem;
  border: 1px solid var(--color-background);
  background: var(--color-error);
  border-radius: var(--border-radius-full);
  left: 50%;
  top: 20%;
`;

export const NotificationListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: min(45ch, 100vw);
  height: 50vh;
  overflow: auto;
`;

export const SettingsBar = styled.div`
  display: flex;
  padding: 0.25rem 0.75rem;
  border-bottom: 1px solid var(--color-text-lighter);
  color: var(--color-text-dark);
  justify-content: space-between;
  align-items: center;

  > div:first-child {
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
  }
`;

export const NewCount = styled.div`
  color: var(--color-error);
`;
