import { types, getRoot } from 'mobx-state-tree';
import moment from 'moment';

const Decimal = types.custom({
  name: 'Decimal',

  fromSnapshot(value) {
    return parseFloat(value);
  },
  toSnapshot(value) {
    return value.toString();
  },
  isTargetType(value) {
    return value instanceof String;
  },
  getValidationMessage(value) {
    if (
      typeof value === 'number' ||
      value === '' ||
      /^-?\d+(\.\d*)?$/.test(value) ||
      isNaN(Number(value)) // eslint-disable-line no-restricted-globals
    ) {
      return '';
    }
    return `'${value}' doesn't look like a valid decimal number`;
  }
});

export const MomentType = types.custom({
  name: 'Moment',

  fromSnapshot(value) {
    return moment(value);
  },
  toSnapshot(value) {
    return value.format();
  },
  isTargetType(value) {
    return value instanceof moment;
  },
  getValidationMessage(value) {
    // Accept also 'Invalid date' - a string created by moment.invalid(), used in DatePicker component
    if (/\d{4}-\d{2}-\d{2}T{0,1}.*$/.test(value) || value === 'Invalid date') {
      return '';
    }
    return `'${value}' doesn't look like a valid date value`;
  }
});

export const NullStringType = types.custom({
  name: 'NullString',

  fromSnapshot(value) {
    if (!value) {
      return '';
    }
    return value;
  },
  toSnapshot(value) {
    return value;
  },
  isTargetType(value) {
    return value instanceof String;
  },
  getValidationMessage() {
    return '';
  }
});

export const PercentType = types.custom({
  name: 'Percent',

  fromSnapshot(value) {
    if (!value) {
      return 0;
    }
    // Get rid of stupid JS float issues
    return Number((parseFloat(value) * 100).toFixed(12));
  },
  toSnapshot(value) {
    return (value / 100.0).toString();
  },
  isTargetType(value) {
    return typeof value === 'number' || value === '';
  },
  getValidationMessage(value) {
    if (/^-?\d+(\.\d+)?$/.test(value) || value === '') {
      return '';
    }
    return `'${value}' doesn't look like a valid decimal number`;
  }
});

export const LazyEmployee = types.reference(
  // eslint-disable-next-line no-undef
  types.late(() => Employee),
  {
    get(identifier, parent) {
      const list = getRoot(parent).employees;
      const e = list.getOrCreate({ id: identifier }, true);
      return e;
    },
    set(value) {
      return value.id;
    }
  }
);

export const LazyProject = types.reference(
  // eslint-disable-next-line no-undef
  types.late(() => Project),
  {
    get(identifier, parent) {
      // console.trace();
      const list = getRoot(parent).projects;
      const p = list?.loadProject(identifier, true);
      return p;
    },
    set(value) {
      return value.id;
    }
  }
);

export default Decimal;
