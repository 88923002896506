import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Container } from './drag-and-drop-style';
import { Icon } from '../';

const DragAndDrop = props => {
  const { text, icon, dropCb, ...rest } = props;

  const onDrop = useCallback(acceptedFiles => {
    dropCb(acceptedFiles);
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    ...rest
  });
  return (
    <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
      <input {...getInputProps()} />
      <Icon name={icon} style={{ marginBottom: '0.5rem' }} size='1.5rem' />
      <p>{text}</p>
    </Container>
  );
};

export default DragAndDrop;
