import React from 'react';
import Permission from './permission';
import { rbac } from './rules';

const EmployeesSummaryReadPermission = props => (
  <Permission perform={rbac.EMPLOYEES_SUMMARY_READ}>
    {props.children}
  </Permission>
);

export default EmployeesSummaryReadPermission;
