import {
  types,
  flow,
  getParentOfType,
  applySnapshot,
  destroy,
  detach,
  getSnapshot
} from 'mobx-state-tree';
import moment from 'moment';
import axios from '../../common/utils/axios';
import Traits from './traits-model';
import WorkHistory from './workhistory-model';
import Certificate from './certificate-model';
import Language from './language-model';
import { Employee } from '../employee-model';
import { MomentType } from '../types';
import notificationStore from '../../stores/notificationStore';

const seniority = {
  future_talent: 'Future talent',
  intermediate: 'Intermediate',
  senior: 'Senior'
};

export const EmployeeCv = types
  .model('EmployeeCv', {
    id: types.number,
    updated_at: types.maybeNull(MomentType),
    title: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    experiences: types.optional(Traits, () => Traits.create([])),
    skills: types.optional(Traits, () => Traits.create([])),
    work_history: types.optional(WorkHistory, () => WorkHistory.create([])),
    certificates: types.optional(types.array(Certificate), []),
    education: types.optional(types.array(Certificate), []), // TODO: these are identical for now, maybe create separate model
    locale: types.optional(types.enumeration(['en', 'fi', 'sv']), 'en'),
    seniority: types.optional(
      types.enumeration(Object.keys(seniority)),
      'future_talent'
    ),
    languages: types.array(Language),
    state: types.optional(
      types.enumeration(['pending', 'ready', 'error']),
      'pending'
    )
  })
  .postProcessSnapshot(sn => {
    const { id, state, ...others } = sn;
    return others;
  })
  .views(self => ({
    lastUpdate() {
      const difference = moment.duration(
        moment(self.updated_at).diff(moment())
      );
      return difference.humanize(true);
    },
    isReady() {
      return self.state === 'ready';
    },
    get seniorityLabel() {
      return seniority[self.seniority];
    }
  }))
  .actions(self => ({
    load: flow(function* load() {
      if (self.id === -1 || self.isReady()) {
        return;
      }
      const employee = getParentOfType(self, Employee);
      try {
        const { data } = yield axios.get(
          `/api/v1/employees/${employee.id}/cvs/${self.id}`
        );
        applySnapshot(self, data);
        self.state = 'ready'; // eslint-disable-line no-param-reassign
      } catch (error) {
        console.error('Failed to fetch cv', error); // eslint-disable-line no-console
        self.state = 'error'; // eslint-disable-line no-param-reassign
      }
    }),
    update(data) {
      Object.keys(data).forEach(key => {
        // eslint-disable-next-line no-prototype-builtins
        if (self.hasOwnProperty(key)) {
          self[key] = data[key]; // eslint-disable-line no-param-reassign
        }
      });
    },
    setTitle(title) {
      self.title = title; // eslint-disable-line no-param-reassign
    },
    setDescription(description) {
      self.description = description; // eslint-disable-line no-param-reassign
    },
    setSeniority(value) {
      self.seniority = value; // eslint-disable-line no-param-reassign
    },
    addCertificate(cert) {
      self.certificates.push(cert);
    },
    removeCertificate(cert) {
      destroy(cert);
    },
    reorderCertificates(startIndex, endIndex) {
      self.certificates.splice(
        endIndex,
        0,
        detach(self.certificates[startIndex])
      );
    },
    addEducation(cert) {
      self.education.push(cert);
    },
    removeEducation(cert) {
      destroy(cert);
    },
    reorderEducation(startIndex, endIndex) {
      self.education.splice(endIndex, 0, detach(self.education[startIndex]));
    },
    addLanguage(lang) {
      self.languages.push(lang);
    },
    removeLanguage(lang) {
      destroy(lang);
    },
    reorderLanguages(startIndex, endIndex) {
      self.languages.splice(endIndex, 0, detach(self.languages[startIndex]));
    },
    save: flow(function* save() {
      try {
        const employee = getParentOfType(self, Employee);
        const params = {
          data: getSnapshot(self),
          locale: self.locale,
          seniority: self.seniority
        };

        // TODO: make more concise
        if (self.id === -1) {
          const { data } = yield axios.post(
            `/api/v1/employees/${employee.id}/cvs`,
            params
          );
          applySnapshot(self, { ...data, state: 'ready' });
          return self;
        }

        const { data } = yield axios.put(
          `/api/v1/employees/${employee.id}/cvs/${self.id}`,
          params
        );
        self.updated_at = data.updated_at; // eslint-disable-line no-param-reassign

        return data;
      } catch ({ response }) {
        return notificationStore.queue(response.data);
      }
    })
  }));

export default EmployeeCv;
