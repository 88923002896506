import styled from 'styled-components/macro';
import { breakpointsRefresh as breakpoints } from 'common/styles';

import derpling from '../../../../assets/images/derpling.svg';

export const Notifications = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${breakpoints.md} {
    margin-right: 0;
  }

  button {
    border-radius: var(--border-radius-full);
    height: 2rem;
    width: 2rem;
    padding: 0;
  }
`;

export const EmptyListIndicator = styled.div`
  display: grid;
  place-content: center;
  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    font-size: var(--font-size-lg);
    font-weight: 100;
    color: var(--color-text-light);
  }
`;

export const NotificationItem = styled.div`
  display: grid;
  position: relative;
  padding: 0.75rem;

  &:hover {
    background-color: var(--color-background-hint);
  }
`;

export const NewDot = styled.div`
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: var(--color-error);
`;

export const NotificationTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 0.2rem;
  font-weight: ${({ read }) => !read && 'bold'};
  color: var(--color-text-darker);

  > div {
    display: flex;
    align-items: center;
    column-gap: 0.35rem;
  }

  > span {
    font-weight: 500;
    color: var(--color-text-light);
  }

  ${NewDot} {
    display: ${({ read }) => read && 'none'};
  }
`;

export const NotificationBody = styled.div`
  white-space: pre-line;
  font-weight: 400;
  color: var(--color-text-dark);
`;

export const Derpling = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  background: url(${derpling});
  background-repeat: no-repeat;
  background-position: 50% 38%;
  background-size: 55%;
`;

export const NotificationFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;

  > div:last-child {
    opacity: 0;
    transition: opacity: 100ms var(--ease-in-out-expo);

    ${NotificationItem}:hover > & {
      opacity: 1;
    }

    @media (pointer: coarse) {
      opacity: 1;
    }
  }
`;

export const NotificationMetadata = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: var(--font-size-xxs);
  background: var(--color-primary-light);
  color: var(--color-white);
  border-radius: var(--border-radius-full);
  padding-right: 0.7rem;
`;
