import { Component } from 'react';

class ServerReload extends Component {
  componentDidMount() {
    window.location.reload(false);
  }

  render() {
    return null;
  }
}

export default ServerReload;
