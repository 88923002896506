import styled from 'styled-components/macro';
import { breakpointsRefresh as breakpoints } from 'common/styles';

const getColor = props => {
  if (props.enableEdit) {
    return 'var(--color-text-dark)';
  }
  if (props.enableDelete) {
    return 'var(--color-error)';
  }
  return 'transparent';
};

export const Container = styled.div`
  width: 100%;
  color: var(--color-background);
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 0;
  background: transparent;
  left: 0;
  top: 0;
  border-radius: 100%;
  border: none;
  transition: all 150ms ease-in-out;
  cursor: pointer;

  ${Container}:hover & {
    opacity: 1;
    outline: none;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 100%;
  opacity: 0;
  transition: all 150ms ease-in-out;
  display: ${({ enableDelete, enableEdit }) =>
    !enableDelete && !enableEdit && 'none'};

  ${Container}:hover & {
    background-color: ${props => getColor(props)};
    opacity: 0.2;
  }
`;

const avatarListSize = ({ $large }) => {
  switch (true) {
    case $large:
      return '2.75rem';
    default:
      return '2rem';
  }
};

const avatarMobileListSize = ({ $large }) => {
  switch (true) {
    case $large:
      return '2.25rem';
    default:
      return '1.5rem';
  }
};

const handleAvatarSize = ({ size, $list, ...rest }) =>
  $list ? avatarListSize(rest) : size || '100%';

const handleAvatarMobileSize = ({ size, $list, ...rest }) =>
  $list ? avatarMobileListSize(rest) : size || '100%';

export const AvatarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${handleAvatarMobileSize};
  height: ${handleAvatarMobileSize};
  aspect-ratio: 1;

  border-radius: 100%;
  border: 1px solid var(--color-background);
  background-color: var(--color-text-lightest);
  color: var(--color-text-lighter);

  ${breakpoints.md} {
    width: ${handleAvatarSize};
    height: ${handleAvatarSize};
  }

  margin: ${({ $list }) => $list && '-0.5rem 0'};

  & + & {
    margin-left: -0.5rem;
  }

  img {
    display: ${({ error }) => error && 'none'};
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;

    &:not([src]) {
      display: none;
    }
  }
`;
