import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Icon } from 'components/atoms';

const Item = styled.div`
  color: var(--color-text-darker);
  font-size: var(--font-size-sm);
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--color-background);
  border-radius: var(--border-radius-md);
  padding: 0.375rem 0.75rem;
  line-height: 1;
  background-color: ${props =>
    props.selected ? 'var(--color-background-is-active)' : 'transparent'};
  text-decoration: none;
  &:hover {
    background-color: var(--color-background-is-active);
  }
`;
const IconContainer = styled.div`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  color: var(--color-text-dark);
`;

const NavListItem = props => {
  const { link, icon, text, selected } = props;
  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <Item selected={selected}>
        <IconContainer>
          <Icon name={icon} size='1rem' />
        </IconContainer>
        {text}
      </Item>
    </Link>
  );
};

NavListItem.propTypes = {
  link: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired
};

export default NavListItem;
