import styled from 'styled-components';
import { IconContainer } from '../icon/icon';

export const TooltipContainer = styled.div`
  display: inline-flex;
  position: relative;
  align-items: center;
  margin-left: 0.3rem;
  cursor: pointer;
  text-transform: none;

  &:hover > span {
    color: var(--color-text-lighter);
  }

  &:hover ${IconContainer} {
    color: var(--color-text-lighter);
  }
`;

export const HoverableLabel = styled.span`
  color: var(--color-text);
  font-size: var(--font-size-base);
`;

export const Header = styled.div`
  font-size: var(--font-size-md);
  font-weight: 600;
  white-space: nowrap;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--color-text);
  border-bottom: solid 1px var(--color-text-lightest);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  font-size: var(--font-size-sm);
  margin-top: 0.5rem;
`;

export const HoverWindow = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 110%;
  left: 0;
  border-radius: var(--border-radius-lg);
  background: var(--color-modal);
  border: 1px solid var(--color-text-lightest);
  box-shadow: var(--shadow-md);
  padding: 1rem;
  z-index: 999999 !important;
  font-size: var(--font-size-sm);
  min-width: 20rem;
  color: var(--color-text-darkest);
`;
