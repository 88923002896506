import React from 'react';
import { observer } from 'mobx-react';

import { Button, Icon } from 'components/atoms';

import {
  BottomBarContainer,
  BottomBarInfo,
  BottomBarError,
  BottomBarActions
} from './bottom-bar-style';

const BottomBar = observer(
  ({
    info,
    error,
    onConfirm,
    onCancel,
    confirmIcon,
    confirmLabel,
    cancelLabel
  }) => {
    return (
      <BottomBarContainer>
        {error ? (
          <BottomBarError>
            <Icon
              name='exclamationTriangleFilled'
              color='#fff'
              fill='var(--color-error-dark)'
            />
            {error}
          </BottomBarError>
        ) : (
          <BottomBarInfo>{info}</BottomBarInfo>
        )}
        <BottomBarActions>
          <Button transparent onClick={onCancel}>
            {cancelLabel || 'Cancel'}
          </Button>
          <Button icon={confirmIcon} onClick={onConfirm}>
            {confirmLabel}
          </Button>
        </BottomBarActions>
      </BottomBarContainer>
    );
  }
);

export default BottomBar;
