import { flow, types, getSnapshot, getParent, getRoot } from 'mobx-state-tree';
import { withValidations } from 'mst-validatejs';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import axios from '../common/utils/axios';
import { formatDateRange } from '../common/utils/time';
import Decimal, {
  PercentType,
  MomentType,
  NullStringType,
  LazyEmployee,
  LazyProject
} from './types';

import { BillingSummary } from './billing-summary-model';
import { Project } from './project-model';

export const Continuation = types
  .model('Continuation', {
    id: types.integer,
    key: types.optional(types.string, uuidv4()),
    start: MomentType,
    end: MomentType
  })
  .postProcessSnapshot(snapshot => {
    delete snapshot.id; // eslint-disable-line no-param-reassign
    delete snapshot.key; // eslint-disable-line no-param-reassign
    return snapshot;
  })
  .views(self => ({
    duration() {
      return self.end.diff(self.start, 'days');
    }
  }))
  .actions(self => ({
    updateDuration(d) {
      self.end = self.start.clone().add(d, 'days'); // eslint-disable-line no-param-reassign
    },
    save: flow(function* save() {
      if (self.id === -1) {
        const a = getParent(self);
        const payload = getSnapshot(self);
        const { data } = yield axios.post(
          `/api/v1/assignments/${a.id}/continuations`,
          JSON.stringify(payload)
        );
        self.id = data.id; // eslint-disable-line no-param-reassign
      } else if (self.start.isSame(self.end)) {
        self.delete();
      } else {
        const data = getSnapshot(self);
        yield axios.put(
          `/api/v1/continuations/${self.id}`,
          JSON.stringify(data)
        );
      }
    }),
    delete: flow(function* _delete() {
      yield axios.delete(`/api/v1/continuations/${self.id}`);
      self.end = self.start; // eslint-disable-line no-param-reassign
      self.id = -1; // eslint-disable-line no-param-reassign
    })
  }));

// Convert sector enums into a map for UI
const values = ['Internal', 'Advisors', 'Projects', 'Experts'];
const sectors = values.reduce((o, v) => ({ ...o, [v.toLowerCase()]: v }), {});
const keys = Object.keys(sectors);

export const Assignment = types
  .model('Assignment', {
    id: types.identifierNumber,
    task: types.optional(NullStringType),
    price: types.optional(Decimal, 0),
    external_price: types.optional(Decimal, 0),
    total_billable_amount: types.optional(Decimal, 0),
    reported_hours: types.optional(Decimal, 0),
    billing_summary: types.map(BillingSummary),
    additional_info: types.optional(NullStringType),
    order_number: types.maybeNull(types.string),
    billing_info: types.maybeNull(types.string),
    contact: types.maybeNull(types.string),
    team: types.maybeNull(types.string),
    sector: types.optional(types.enumeration(keys), keys[0]),
    // If an undefined value is given to the model (e.g. the user types
    // something that is not a valid date) it should be converted to invalid date:
    start: types.optional(MomentType, moment('')),
    end: types.optional(MomentType, moment('')),
    deletable: types.optional(types.boolean, true),
    alloc: types.optional(PercentType, '1'),
    employee: types.maybeNull(LazyEmployee),
    project: types.union(LazyProject, Project),
    continuation: types.maybeNull(Continuation),
    color: types.maybeNull(types.string)
  })
  .postProcessSnapshot(snapshot => {
    snapshot.employee_id = snapshot.employee; // eslint-disable-line no-param-reassign
    delete snapshot.id; // eslint-disable-line no-param-reassign
    delete snapshot.total_billable_amount; // eslint-disable-line no-param-reassign
    delete snapshot.reported_hours; // eslint-disable-line no-param-reassign
    delete snapshot.deletable; // eslint-disable-line no-param-reassign
    delete snapshot.employee; // eslint-disable-line no-param-reassign
    return snapshot;
  })
  .extend(
    withValidations({
      alloc: {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 100,
          message: 'invalid value'
        }
      },
      external_price: {
        numericality: {
          greaterThanOrEqualTo: 0
        }
      },
      price: {
        numericality: {
          greaterThanOrEqualTo: 0
        }
      },
      start: {
        validDateRange: true,
        dateInProjectRange: true
      },
      end: {
        validDateRange: true,
        dateInProjectRange: true
      }
    })
  )
  .views(self => ({
    summary(range) {
      return self.billing_summary.get(formatDateRange(range).range);
    },
    hasTask() {
      return self.task.length > 0;
    },
    projectNameAndTask() {
      const d = this.hasTask() ? ` (${this.task})` : '';
      return `${this.project.name}${d}`;
    },
    getSectors() {
      return sectors;
    }
  }))
  .actions(self => ({
    createContinuation() {
      if (!self.continuation) {
        // eslint-disable-next-line no-param-reassign
        self.continuation = Continuation.create({
          id: -1,
          start: self.end,
          end: self.end
        });
      }
      return self.continuation;
    },
    addBillingSummary(summary) {
      self.billing_summary.set(summary.id, summary);
    },
    set(attr, value) {
      self[attr] = value; // eslint-disable-line no-param-reassign
    },
    setTask(value) {
      self.set('task', value);
    },
    setPrice(value) {
      self.price = value; // eslint-disable-line no-param-reassign
    },
    setExternalPrice(value) {
      self.external_price = value; // eslint-disable-line no-param-reassign
    },
    setAlloc(value) {
      self.alloc = value; // eslint-disable-line no-param-reassign
    },
    setEndDate(date) {
      self.end = date; // eslint-disable-line no-param-reassign
    },
    setStartDate(date) {
      self.start = date; // eslint-disable-line no-param-reassign
    },
    setEmployee(e) {
      self.employee = e; // eslint-disable-line no-param-reassign
    },
    updateColor: flow(function* updateColor(color) {
      self.set('color', color);
      yield axios.put(
        `/api/v1/assignments/${self.id}`,
        JSON.stringify({ color })
      );
    }),
    updateSector: flow(function* updateSector(sector) {
      self.set('sector', sector);
      yield axios.put(
        `/api/v1/assignments/${self.id}`,
        JSON.stringify({ sector })
      );
    }),
    save: flow(function* save() {
      const data = getSnapshot(self);
      // TODO: add try/catch error handling
      yield axios.put(`/api/v1/assignments/${self.id}`, JSON.stringify(data));
    }),
    createCopy() {
      const { assignments } = getRoot(self);
      const { alloc } = self;
      self.setAlloc(0); // Start new assignment with zero alloc for convenience
      assignments.saveNewAssignment(self, false);
      self.setAlloc(alloc); // Reset
    }
  }));

export default Assignment;
