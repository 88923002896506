import styled from 'styled-components/macro';
import { IconContainer } from '../icon/icon';

export const Input = styled.input`
  font-size: inherit;
  color: ${props => props.$colorText || 'var(--color-input-text)'};
  filter: ${props => (props.$blurred ? 'blur(3px)' : 'blur(0)')};
  padding: 0.25rem 0.5rem;
  background: ${({ $hasError, $colorBackground }) =>
    $hasError
      ? 'var(--color-error-lightest)'
      : $colorBackground || 'transparent'};
  display: block;
  outline: none;
  width: 100%;
  min-width: 3rem;
  overflow-y: ${props => (props.$autosize ? 'hidden' : 'auto')};
  border: ${props =>
    props.$hasError === true
      ? '1px solid var(--color-error-light)'
      : '1px solid transparent'};
  border-radius: var(--border-radius-xs);
  cursor: text;
  resize: none; // Textarea component override
  &:hover,
  &:focus {
    text-decoration: none;
  }
  &:hover {
    background-color: ${props =>
      props.$colorHover || 'var(--color-input-hover)'};
  }
  &:focus {
    border: 1px solid
      ${props => props.$colorFocus || 'var(--color-input-focus)'};
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: ${props =>
      props.$colorPlaceholder || 'var(--color-input-placeholder)'};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }

  transition: filter 100ms var(--ease-in-out-expo);
`;

export const Label = styled.label`
  position: relative;
  width: 100%;
  margin-bottom: 0.125rem;
  margin-left: -0.5rem;

  ${IconContainer} {
    position: absolute;
    top: 0;
    right: ${({ iconSize }) => iconSize || '0.5rem'};
    height: 100%;
    color: inherit;
  }

  & select {
    cursor: pointer;
  }
`;
